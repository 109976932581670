import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { FaQuestionCircle, FaWrench } from 'react-icons/fa';

import GiveawayCard from '../../components/GiveawayCard';
import Empty from '../../components/Empty';

import { Container, GiveawayList, GiveawayGrid } from './styles';

import api from '../../services/api';

import Helmet from '../../components/Helmet';

export default function Giveaways() {
  const [loading, setLoading] = useState(true);
  const [giveaways, setGiveaways] = useState([]);

  const { signed } = useSelector(state => state.auth);
  const { profile } = useSelector(state => state.user);

  const hugeGiveaway = useMemo(() => {
    return giveaways.find(giveaway => giveaway.level === 2);
  }, [giveaways]);

  useEffect(() => {
    async function getGiveaways() {
      const response = await api.get('/giveaways');

      setGiveaways(response.data);
      setLoading(false);
    }

    getGiveaways();
  }, []);

  return (
    <>
      <Helmet title="Sorteios" description="Sorteios ativos" />
      {!loading && (
        <Container>
          <header>
            <div className="left">
              <h2>
                {!!giveaways.length
                  ? `${giveaways.length} sorteios ativos`
                  : 'Nenhum sorteio ativo'}{' '}
              </h2>
              {signed && profile.is_admin && (
                <Link to="/admin/sorteios">
                  <strong>
                    <span>Gerenciar</span>
                    <FaWrench size={10} />
                  </strong>
                </Link>
              )}
            </div>
            <a href="/como-funciona/sorteios" target="_blank">
              <strong className="color-gold">
                <span>Como participar</span>
                <FaQuestionCircle />
              </strong>
            </a>
          </header>
          {!!giveaways.length ? (
            <GiveawayList>
              {hugeGiveaway && (
                <GiveawayCard key={hugeGiveaway.id} {...hugeGiveaway} />
              )}
              <GiveawayGrid big>
                {giveaways.map(
                  giveaway =>
                    giveaway.level === 1 && (
                      <GiveawayCard key={giveaway.id} {...giveaway} />
                    )
                )}
              </GiveawayGrid>
              <GiveawayGrid>
                {giveaways.map(
                  giveaway =>
                    giveaway.level < 1 && (
                      <GiveawayCard key={giveaway.id} {...giveaway} />
                    )
                )}
              </GiveawayGrid>
            </GiveawayList>
          ) : (
            <div className="empty">
              <Empty />
            </div>
          )}
        </Container>
      )}
    </>
  );
}
