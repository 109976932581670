export default function(points, tier) {
  switch (tier) {
    case '1000':
      return Math.floor(points / 10);
    case '2000':
      return Math.floor(points / 5);
    case '3000':
      return Math.floor(points / 3);
    default:
      return Math.floor(points / 20);
  }
}
