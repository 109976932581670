import { keyframes } from '@xstyled/styled-components';
import { transparentize } from 'polished';

export const pulseEffect = color => keyframes`
    0% {
      box-shadow: 0 0 0 0 ${transparentize(0.6, `${color}`)};
    }

    100% {
      box-shadow: 0 0 0 10px ${transparentize(1, `${color}`)};
    }
`;

export const loadingEffect = keyframes`

  from {

    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }

`;
