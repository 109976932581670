import styled, { css, keyframes } from '@xstyled/styled-components';
import { transparentize } from 'polished';

const pulseEffect = color => keyframes`
    0% {
      box-shadow: 0 0 0 0 ${transparentize(0.6, `${color}`)};
    }


    100% {
      box-shadow: 0 0 0 10px ${transparentize(1, `${color}`)};
    }
`;

export const Container = styled.div`
  padding: 6px 1rem;
  height: 2.8rem;
  border-radius: default;

  animation: ${({ color, pulse }) => pulse && pulseEffect(color)} 3s infinite;

  display: flex;
  align-items: center;

  strong {
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;
