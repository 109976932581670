import styled, { css } from '@xstyled/styled-components';

import theme from '../../../../styles/theme';

export const Container = styled.div`
  font-size: 1.4rem;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${({ background }) =>
    background &&
    css`
      padding: huge;
      background-image: linear-gradient(
          to top,
          ${theme.colors.shapes},
          transparent
        ),
        linear-gradient(to left, ${theme.colors.shapes}, transparent),
        url(${background});
      background-size: cover;
    `}

  > * + * {
    margin-top: 2rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    display: flex;
    align-items: center;

    > * {
      align-self: stretch;
      + * {
        margin-left: 1rem;
      }
    }
  }
`;
