import styled from '@xstyled/styled-components';
import theme from '../../../../styles/theme';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  border-radius: default;
  background: linear-gradient(
      to left,
      transparent,
      ${theme.colors.shapesDark} 90%
    ),
    url(${({ background }) => background});
  background-size: cover;
  background-position: center;
`;

export const DetailsContainer = styled(Link)`
  flex: 1;
  :hover {
    color: inherit;
  }
`;

export const Details = styled.div`
  display: flex;
  img {
    height: 6.4rem;
    margin-right: 1.2rem;
    border-radius: default;
  }

  h2 {
    font-size: 2rem;
  }
`;

export const Info = styled.div`
  margin-top: 1rem;
  display: flex;
  font-size: 1.2rem;

  > div {
    display: flex;
    align-items: center;

    strong {
      margin-left: 4px;
    }

    + div {
      margin-left: 1rem;
    }
  }

  > * + * {
    margin-left: 1rem;
  }
`;

export const Status = styled.strong`
  color: ${({ enabled }) => theme.colors[enabled ? 'ticket' : 'gift']};
  text-transform: uppercase;
`;

export const Actions = styled.div``;
