import React from 'react';
import { useParams } from 'react-router-dom';

import { Wrapper, Container, Content } from './styles';

import Sidebar from './Sidebar';

import Scroll from 'react-perfect-scrollbar';

import Helmet from '../../components/Helmet';

export default function AdminLayout({ children }) {
  const { product_id, giveaway_id } = useParams();

  return (
    <>
      <Helmet title="Painel de controle" description="Área restrita" />
      <Wrapper>
        <Scroll>
          <Container>
            <Content hasBackground={product_id || giveaway_id}>
              <Sidebar />
              <section>{children}</section>
            </Content>
          </Container>
        </Scroll>
      </Wrapper>
    </>
  );
}
