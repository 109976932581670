import styled from '@xstyled/styled-components';

export const Container = styled.div`
  font-size: 1.4rem;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > * + * {
    margin-top: 2rem;
  }
`;

export const Actions = styled.div`
  display: flex;

  > * + * {
    margin-left: 1rem;
  }
`;
