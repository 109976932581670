import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ReactMarkdown from 'react-markdown';

import {
  getGiveawayRequest,
  addGiveawayTickets,
  clearGiveaway,
} from '../../../store/modules/giveaway/actions';

import { useParams } from 'react-router-dom';
import { MdLink, MdLock } from 'react-icons/md';
import { format } from 'date-fns';

import { Container, Background, Content, Details } from './styles';

import Info from './Info';
import Winner from './Winner';

import Alert from '../../../components/Alert';
import Progress from '../../../components/Progress';

import socket from '../../../services/socket';
import theme from '../../../styles/theme';

import Helmet from '../../../components/Helmet';

export default function Giveaway() {
  const { giveaway_id } = useParams();

  const { details, loading } = useSelector(state => state.giveaway);

  const dispatch = useDispatch();

  useEffect(() => {
    socket.emit('@subscribe', giveaway_id);

    socket.on('ADD_TICKETS', tickets => {
      dispatch(addGiveawayTickets(tickets));
    });

    return () => {
      socket.emit('@unsubscribe', giveaway_id);
      socket.removeEventListener();
    };
  }, [giveaway_id]); // eslint-disable-line

  useEffect(() => {
    dispatch(getGiveawayRequest(giveaway_id));

    return () => {
      dispatch(clearGiveaway());
    };
  }, []); // eslint-disable-line

  return (
    <>
      <Helmet
        title={details ? details.giveaway.title : 'Sorteio'}
        description="Sorteios ativos"
      />
      {!loading && (
        <>
          {details.giveaway.winner && (
            <Alert color={theme.colors.primary}>
              Esse sorteio foi encerrado em {format(new Date(), 'dd/MM/yy')}
            </Alert>
          )}
          <Background background={details.giveaway.background} />
          <Container>
            <Content>
              <header>
                <div>
                  <h1 className="title">{details.giveaway.title}</h1>
                  <ReactMarkdown
                    className="description"
                    source={details.giveaway.description}
                  />
                </div>
                <div className="status">
                  <div className="count">
                    <span>
                      <strong className="color-ticket">
                        {details.giveaway.tickets.length} tickets
                      </strong>{' '}
                      participantes
                    </span>
                    <span className="limit">
                      <MdLock />
                      <strong>{details.giveaway.limit}</strong>
                    </span>
                  </div>
                  <Progress
                    percent={Number(
                      (100 * details.giveaway.tickets.length) /
                        details.giveaway.limit
                    )}
                  />
                </div>
              </header>
              <Details>
                <div className="giveaway">
                  {details.giveaway.products.map(product => (
                    <div className="container" key={product.id}>
                      <img src={product.image} alt="" />
                      <div className="row">
                        <h3>{product.title}</h3>
                        <a
                          href={product.product_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <MdLink size={20} />
                        </a>
                      </div>
                      <ReactMarkdown source={product.description} />
                    </div>
                  ))}
                </div>
                <div className="info">
                  {details.giveaway.winner ? (
                    <Winner {...details.giveaway.winner} />
                  ) : (
                    <Info />
                  )}
                </div>
              </Details>
            </Content>
          </Container>
        </>
      )}
    </>
  );
}
