import styled from '@xstyled/styled-components';
import { transparentize } from 'polished';

import PerfectScrollbar from 'react-perfect-scrollbar';

import theme from '../../styles/theme';

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  background-color: shapesDark;
`;

export const Container = styled(PerfectScrollbar)`
  flex: 1;
  color: text;
  padding: 2rem 0;
`;

export const Content = styled.div`
  min-height: 100%;
  background-color: shapes;
  max-width: 980px;
  margin: 0 auto;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: default;

  font-size: 1.8rem;
  line-height: 3.6rem;

  > div {
    padding: 2rem;

    .highlight {
      background-color: ${transparentize(0.9, theme.colors.twitch)};
      border: 2px solid;
      border-color: twitch;
      padding: 2rem;
      border-radius: default;
    }
  }

  .post {
    > h1,
    > h2,
    > h3 {
      margin: 2rem 0;
      margin-top: 4rem;
      + h1,
      + h2,
      + h3 {
      }
    }

    h3 {
      svg {
        margin-right: 1rem;
      }
    }

    > p {
      > code {
        background: rgba(0, 0, 0, 0.2);
        border-radius: default;
        padding: 4px 1rem;
      }

      > a {
        text-decoration: underline;
        :hover {
          color: inherit;
        }
      }
      + p {
        margin-top: 2rem;
      }
    }

    > ul {
      li {
        margin-top: 2rem;
      }
    }
  }

  .embed {
    margin-top: 3rem;
    border-radius: default;
    overflow: hidden;
  }

  .social {
    display: flex;
    > * + * {
      margin-left: 1rem;
    }
  }
`;
