import React from 'react';

import SkeletonTheme from '../../SkeletonTheme';
import Skeleton from 'react-loading-skeleton';

export default function Details() {
  return (
    <SkeletonTheme>
      <div style={{ direction: 'rtl', lineHeight: '2rem' }}>
        <p>
          <Skeleton width={80} />
        </p>
        <p>
          <Skeleton width={120} />
        </p>
      </div>
    </SkeletonTheme>
  );
}
