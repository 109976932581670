import React from 'react';

import {
  FaGift,
  FaTwitch,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaDiscord,
} from 'react-icons/fa';
import { MdMessage } from 'react-icons/md';

import Helmet from '../../../components/Helmet';

import { Container, Item, Social } from './styles';

import Button from '../../../components/Button';
import theme from '../../../styles/theme';

import PeronioLove from '../../../assets/emotes/peronioLove.png';

export default function Account() {
  return (
    <>
      <Helmet
        title="Contato"
        description="Tem alguma dúvida ou quer relatar um problema? Entre em contato!"
      />
      <div className="post">
        <h2>
          Tem alguma dúvida ou quer relatar um problema? Entre em contato!
        </h2>
        <p>Para entrar em contato, o formato preferencial sempre é o email.</p>
        <Container>
          <Item>
            <FaGift size={32} color={theme.colors.gift} />
            <div>
              <p>
                Assuntos sobre sorteios, sistema da live, bugs, sugestões ou
                elogios
              </p>
              <strong>sorteios@peronio.com.br</strong>
            </div>
          </Item>
          <Item>
            <MdMessage size={32} color={theme.colors.verified} />
            <div>
              <p>Assuntos comerciais</p>
              <strong>contato@peronio.com.br</strong>
            </div>
          </Item>
          <Social>
            <h3>Me encontre tambem nas redes sociais</h3>

            <div className="row">
              <Button
                url="https://twitch.tv/peroniogh"
                color={theme.colors.twitch}
                text={theme.colors.text}
              >
                <FaTwitch />
                <span>Twitch</span>
              </Button>

              <Button
                url="https://www.youtube.com/peronio"
                color={theme.colors.error}
                text={theme.colors.text}
              >
                <FaYoutube />
                <span>YouTube</span>
              </Button>
            </div>
            <div className="row">
              <Button
                url="https://twitter.com/peroniogh"
                color={theme.colors.twitter}
                text={theme.colors.text}
              >
                <FaTwitter />
                <span>Twitter</span>
              </Button>
              <Button
                url="https://instagram.com/peroniogh"
                colors={[theme.colors.instagram, theme.colors.instagram2]}
                text={theme.colors.text}
              >
                <FaInstagram />
                <span>Instagram</span>
              </Button>
              <Button
                url="http://peronio.com.br/discord"
                color={theme.colors.discord}
                text={theme.colors.text}
              >
                <FaDiscord />
                <span>Discord</span>
              </Button>
            </div>
            <div className="row">
              <img src={PeronioLove} width={32} />
            </div>
          </Social>
        </Container>
      </div>
    </>
  );
}
