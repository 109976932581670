import styled, { css } from '@xstyled/styled-components';
import { lighten } from 'polished';
import theme from '../../../styles/theme';

import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  background-color: shapesDark;
  color: text;
  font-size: 1.6rem;
  padding: 4rem 2rem;
  min-width: 180px;

  display: flex;
  flex-direction: column;

  border-top: 1px solid ${lighten(0.01, theme.colors.shapes)};

  user-select: none;
  position: relative;

  a {
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: -99999px;
    right: 0;
    bottom: 0;
    width: 99999px;
    background-color: shapesDark;
  }
`;

export const Link = styled(NavLink)`
  position: relative;

  &.active {
    color: primary;
    font-weight: bold;
  }

  + a {
    margin-top: 2rem;
  }

  ${({ isNew }) =>
    isNew &&
    css`
      &::after {
        content: 'NOVO';
        position: absolute;
        left: -4rem;
        top: 0;
        background-color: primary;
        font-size: 8px;
        font-weight: bold;
        padding: 0.4rem;
        border-radius: default;
        color: text;
      }
    `}
`;
