import React from 'react';

import { SkeletonTheme } from 'react-loading-skeleton';

import theme from '../../styles/theme';

function SkeletonWrapper({ children }) {
  return (
    <SkeletonTheme
      color={theme.colors.shapesDark}
      highlightColor={theme.colors.shapes}
    >
      {children}
    </SkeletonTheme>
  );
}

export default SkeletonWrapper;
