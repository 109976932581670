import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Empty from '../../../components/Empty';
import Button from '../../../components/Button';

import api from '../../../services/api';

import { Container, Log } from './styles';
import { formatRelative } from 'date-fns';
import theme from '../../../styles/theme';

import { FaTicketAlt, FaCoins, FaGift } from 'react-icons/fa';
import { MdConfirmationNumber } from 'react-icons/md';
import Helmet from '../../../components/Helmet';
import LogItem from '../../../components/account/LogItem';

export default function Logs() {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState({ docs: [] });

  const [page, setPage] = useState(1);

  async function getLogs() {
    try {
      const response = await api.get('/logs', {
        params: {
          page,
        },
      });

      const docs = response.data.docs.map(log => ({
        ...log,
        created_at: format(new Date(log.createdAt), 'dd/MM/yy'),
        formattedRelative: formatRelative(new Date(log.createdAt), new Date(), {
          locale: ptBR,
        }),
        link: linkToInteraction(log.type, log.interaction_id),
      }));

      setLogs(prev => ({
        ...response.data,
        docs: [...prev.docs, ...docs],
      }));

      setLoading(false);
    } catch (err) {}
  }

  function linkToInteraction(type, interaction) {
    switch (type) {
      case 'giveaway':
        return `/sorteios/${interaction}`;
      case 'ticket':
        return `/sorteios/${interaction}`;
      default:
        return `/loja`;
    }
  }

  async function handleLoadMore() {
    setPage(prev => prev + 1);
  }

  useEffect(() => {
    getLogs();
  }, [page]); // eslint-disable-line

  return (
    <>
      <Helmet title="Histórico" description="Histórico da conta" />
      {!loading && (
        <Container>
          {!!logs.docs.length ? (
            logs.docs.map(log => <LogItem key={log._id} {...log} />)
          ) : (
            <Empty />
          )}
          {page < logs.totalPages && (
            <Button
              color="transparent"
              text={theme.colors.text}
              onClick={handleLoadMore}
            >
              Carregar mais
            </Button>
          )}
        </Container>
      )}
    </>
  );
}
