import React from 'react';

import { Container } from './styles';

function Alert({ children, color, transparent, className }) {
  return (
    <Container className={className} color={color} transparent={transparent}>
      <h3>{children}</h3>
    </Container>
  );
}

export default Alert;
