import React from 'react';

import { Link } from 'react-router-dom';

import {
  FaTwitch,
  FaCoins,
  FaTicketAlt,
  FaShoppingCart,
  FaGift,
  FaTruckMoving,
  FaUser,
  FaTrophy,
} from 'react-icons/fa';

import { Container, Grid } from './styles';

import Helmet from '../../components/Helmet';

export default function Delivery() {
  return (
    <Container>
      <Helmet
        title="Como funciona"
        description="Entenda como funciona o sistema"
      />
      <Grid>
        <div>
          <Link to="/como-funciona/subscribers">
            <div className="title">
              <h3>Subscribers</h3>
              <FaTwitch />
            </div>
            <p>
              Conheça os benefícios para{' '}
              <strong className="color-twitch">subscribers</strong> da live
            </p>
          </Link>
        </div>
        <div>
          <Link to="/como-funciona/gold">
            <div className="title">
              <h3>Pontos e gold</h3>
              <FaCoins />
            </div>
            <p>
              Acesse para entender a diferença entre <strong>pontos</strong> e{' '}
              <strong className="color-gold">gold</strong>
            </p>
          </Link>
        </div>

        <div>
          <Link to="/como-funciona/sorteios">
            <div className="title">
              <h3>Sorteios</h3>
              <FaTrophy />
            </div>
            <p>
              Saiba como funciona os sorteios e como participar de cada um deles
            </p>
          </Link>
        </div>
        <div>
          <Link to="/como-funciona/tickets">
            <div className="title">
              <h3>Tickets</h3>
              <FaTicketAlt />
            </div>
            <p>
              Saiba mais sobre os{' '}
              <strong className="color-ticket">tickets</strong> e{' '}
              <strong className="color-twitch">purple tickets</strong>{' '}
            </p>
          </Link>
        </div>
        <div>
          <Link to="/como-funciona/loja">
            <div className="title">
              <h3>Loja</h3>
              <FaShoppingCart />
            </div>
            <p>Acesse para saber como funciona as trocas e prazos da loja</p>
          </Link>
        </div>
        <div>
          <Link to="/como-funciona/prazos">
            <div className="title">
              <h3>Prazos</h3>
              <FaTruckMoving />
            </div>
            <p>
              Entenda como funciona os prazos de entrega das{' '}
              <strong>premiações</strong> e <strong>pedidos</strong>
            </p>
          </Link>
        </div>
        <div>
          <Link to="/como-funciona/gifts">
            <div className="title">
              <h3>Gifts</h3>
              <FaGift />
            </div>
            <p>
              Saiba mais pra que serve os{' '}
              <strong className="color-gift">gifts</strong> do sistema
            </p>
          </Link>
        </div>
        <div>
          <Link to="/como-funciona/conta">
            <div className="title">
              <h3>Conta</h3>
              <FaUser />
            </div>
            <p>Conheça todos os recursos da sua conta</p>
          </Link>
        </div>
      </Grid>
    </Container>
  );
}
