import React from 'react';

import ProductForm from '../ProductForm';

import { Container } from './styles';

import api from '../../../../services/api';

import { queue } from '../../../../services/snackbar';

import { FaTimesCircle } from 'react-icons/fa';

import theme from '../../../../styles/theme';

export default function New({ history }) {
  async function handleSubmit(data) {
    try {
      await api.post('/admin/products', data);

      history.push('/admin/loja');
    } catch (err) {
      queue.notify({
        title: <b>Whoops...</b>,
        body: err.response.data,
        icon: <FaTimesCircle size={18} color={theme.colors.error} />,
        actions: [],
      });
    }
  }

  return (
    <Container>
      <ProductForm onSubmit={handleSubmit} />
    </Container>
  );
}
