import styled, { css } from '@xstyled/styled-components';

export const Container = styled.div`
  .creating {
    display: flex;

    .col {
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 1rem;
      }

      &.select {
        width: 100%;
        flex: 1;

        input {
          width: 100%;
        }
      }

      input {
        height: 100%;
        font-size: 1.4rem;

        &.sm {
          width: 7rem;
        }

        &.md {
          width: 11rem;
        }
      }
    }

    .actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }

    > * + * {
      margin-left: 2rem;
    }
  }

  > * + * {
    margin-top: 2rem;
  }
`;

export const Code = styled.div`
  background-color: shapesDark;
  padding: 2rem;
  border-radius: default;
  margin-left: 1.5rem;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    position: absolute;
    background-color: shapes;
    top: 2.25rem;
    left: -1.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid;
    border-color: shapesDark;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  h3 {
    margin-left: 0.2rem;
    font-family: 'Roboto Mono';
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 1.6rem;

    .expires,
    .uses,
    .amount {
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 0.6rem;
      }
    }

    .expires {
      strong {
        font-size: 1rem;
      }
    }

    > * + * {
      margin-left: 1.6rem;
    }
  }

  ${({ visible }) =>
    !visible &&
    css`
      h3 {
        filter: blur(2px);
      }
    `}
`;
