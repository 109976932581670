import styled from '@xstyled/styled-components';

import { CircularProgress as MaterialProgress } from '@rmwc/circular-progress';

import theme from '../../styles/theme';

export const Container = styled(MaterialProgress)`
  --mdc-theme-primary: ${theme.colors.gold};

  &.rmwc-circular-progress {
    width: inherit;
    flex: 1;
  }
`;
