import styled from '@xstyled/styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const Label = styled.div`
  flex: 2;
`;

export const TypeContainer = styled.div`
  flex: 6;
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
`;
