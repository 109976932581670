import styled, { css } from '@xstyled/styled-components';
import { easeOutCirc } from 'eazy-ease';
import { darken } from 'polished';

import { loadingEffect } from '../../../../styles/keyframes';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition: all 0.2s ${easeOutCirc};

  :hover {
    transform: scale(1.025);
  }
`;

export const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -1rem;
  top: -1rem;
  overflow: visible;

  background-color: shapesDark;
  border: 4px solid;
  border-color: shapes;
  height: 3rem;
  width: 3rem;
  padding: 2rem;
  border-radius: 50%;
  > strong {
    font-size: 2rem;
  }
`;

export const BodyWrapper = styled.div`
  background-color: shapesDark;
  height: 100%;
  border-radius: default;
  width: 100%;

  margin-top: -8rem;
  padding-top: 9rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  :hover {
    box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.2);
  }
`;

export const Body = styled.div`
  padding: 2rem;
  padding-top: 0;
  height: 100%;
  min-height: 240px;
  display: flex;
  flex-direction: column;

  h3 {
    text-align: center;
  }

  img {
    width: 100%;
    border-radius: default;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      > * + * {
        padding-top: 2rem;
      }
    `}
`;
export const Info = styled.div`
  padding-top: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  .entries {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    max-height: 120px;
    overflow-y: auto;
    .entry {
      text-align: center;
      font-size: 2rem;
      margin-top: 1rem;
      padding: 0.4rem 1rem;
      background-color: slabs;
      border-radius: default;
      color: ${darken(0.35, theme.colors.slabs)};
    }
  }
`;

export const Label = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  strong {
    font-size: 1.2rem;
    text-transform: uppercase;
    padding: 0.6rem 1rem;
    border-radius: default;
    background-color: translucentDark;
    color: ${({ color }) => color};
  }
`;

export const CardFooter = styled.div`
  background-color: ${({ color }) => color};
  padding: 1.4rem;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: bottom;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s ${easeOutCirc};

  ${({ loading }) =>
    loading &&
    css`
      > * {
        opacity: 0.8;
      }

      svg {
        animation: ${loadingEffect} 2s linear infinite;
      }
    `}
`;

export const Locked = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 3rem;

  > * + * {
    margin-top: 2rem;
  }

  svg {
    opacity: 0.8;
  }
`;
