import { all, takeLatest, put } from 'redux-saga/effects';

import api from '../../../services/api';
import history from '../../../services/history';
import { verificationSuccess } from '../verification/actions';

function* login({ payload }) {
  api.defaults.headers.Authorization = `Bearer ${payload.data.token}`;

  yield put(verificationSuccess(payload.data));
}

function signOut() {
  history.push('/');
}

export default all([
  takeLatest('@auth/SIGN_IN_SUCCESS', login),
  takeLatest('@auth/SIGN_OUT_REQUEST', signOut),
]);
