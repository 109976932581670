import styled, { css } from '@xstyled/styled-components';

export const Wrapper = styled.div`
  background-color: shapes;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: 1080px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  min-height: 100%;
  color: text;
  display: flex;

  > section {
    width: 100%;
    padding: huge;
    max-height: 100%;

    ${({ hasBackground }) =>
      hasBackground &&
      css`
        padding: 0;
      `}
  }
`;
