import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { deleteGiveawayRequest } from '../../../../store/modules/giveaway/actions';

import { Container, Actions } from './styles';

import { FaExternalLinkAlt } from 'react-icons/fa';

import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Select from '../../../../components/Select';
import Uploader from '../../../../components/Uploader';
import Switch from '../../../../components/Switch';

import api from '../../../../services/api';
import history from '../../../../services/history';
import theme from '../../../../styles/theme';

registerLocale('pt-BR', ptBR);

const levels = [
  { label: 'Normal', value: 0 },
  { label: 'Grande', value: 1 },
  { label: 'Enorme', value: 2 },
];

export default function GiveawayForm({ onSubmit, initialData, settings }) {
  const [imageIsLink, setImageIsLink] = useState(false);
  const [backgroundIsLink, setBackgroundIsLink] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [image, setImage] = useState('');
  const [background, setBackground] = useState('');
  const [limit, setLimit] = useState(500);
  const [level, setLevel] = useState(null);
  const [autoLock, setAutoLock] = useState(true);
  const [endsAt, setEndsAt] = useState(null);
  const [active, setActive] = useState(false);

  const dispatch = useDispatch();

  function handleProductChange(data) {
    if (!data) return;
    if (!!data.length) {
      setImage(data[0].image);
      setBackground(data[0].background);
      setImageIsLink(true);
      setBackgroundIsLink(true);
      setSelectedProducts(data);
    }
  }

  function handleLevelChange(data) {
    setLevel(data);
  }

  async function handleCreate() {
    await api.post('/admin/giveaways', {
      title,
      description,
      image,
      background,
      limit,
      level,
      auto_lock: autoLock,
      ends_at: endsAt,
      active,
      products: selectedProducts.map(p => p.value),
    });

    history.push('/admin/sorteios');
  }

  async function handleUpdate() {
    onSubmit({
      title,
      description,
      products: selectedProducts,
      image,
      background,
      limit,
      level: level.value,
      auto_lock: autoLock,
      active,
      ends_at: endsAt,
    });
  }

  async function handleDelete() {
    dispatch(deleteGiveawayRequest(initialData.id));
  }

  useEffect(() => {
    if (initialData) {
      setImageIsLink(true);
      setBackgroundIsLink(true);

      setTitle(initialData.title);
      setDescription(initialData.description);
      setProducts(settings.products);
      setSelectedProducts(initialData.products);
      setImage(initialData.image);
      setBackground(initialData.background);
      setLimit(initialData.limit);
      setLevel(levels.find(l => l.value === initialData.level));
      setAutoLock(initialData.auto_lock);
      setActive(initialData.active);
      setEndsAt(initialData.ends_at ? new Date(initialData.ends_at) : null);
    } else {
      async function getConfig() {
        const response = await api.get('/settings/giveaways');

        const formatResponse = response.data.map(product => ({
          value: product.id,
          label: product.title,
          image: product.image,
          background: product.background,
        }));

        setProducts(formatResponse);
      }

      getConfig();
    }
  }, []); // eslint-disable-line

  return (
    <Container background={initialData.id && background}>
      <Form>
        <div className="row">
          <span>Titulo</span>
          <input
            type="text"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <Button
            url={`/sorteios/${initialData.id}`}
            color={theme.colors.primary}
            text={theme.colors.text}
          >
            <FaExternalLinkAlt />
          </Button>
        </div>
        <div className="row">
          <span>Descrição</span>
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div className="row">
          <span>Premiações</span>
          <Select
            className="reselect"
            options={products}
            value={selectedProducts}
            placeholder="Selecione as premiações..."
            onChange={handleProductChange}
            isMulti
          />
        </div>
        <div className="row">
          <span>Imagem</span>
          {imageIsLink ? (
            <input
              type="text"
              value={image}
              onChange={e => setImage(e.target.value)}
            />
          ) : (
            <Uploader />
          )}
        </div>
        <div className="row">
          <span>Background</span>
          {backgroundIsLink ? (
            <input
              type="text"
              value={background}
              onChange={e => setImage(e.target.value)}
            />
          ) : (
            <Uploader />
          )}
        </div>
        <div className="row">
          <div className="col">
            <span>Limite</span>
            <input
              type="number"
              className="smaller"
              min="1"
              value={limit}
              onChange={e => setLimit(e.target.value)}
            />
          </div>
          <div className="col full">
            <label htmlFor="">
              <span>Level</span>
              <Select
                className="reselect"
                options={levels}
                value={level}
                placeholder="Selecione o tamanho"
                onChange={handleLevelChange}
                isSearchable={false}
              />
            </label>
          </div>
          <div className="col">
            <label>
              <span>Auto Lock</span>
              <Switch
                checked={autoLock}
                onChange={() => setAutoLock(!autoLock)}
              />
            </label>
          </div>
          <div className="col">
            <label>
              <span>Ativo</span>
              <Switch checked={active} onChange={() => setActive(!active)} />
            </label>
          </div>
          <div className="col full">
            <label>
              <span>Data</span>
              <DatePicker
                className="big"
                selected={endsAt}
                onChange={date => setEndsAt(date)}
                locale="pt-BR"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
                timeCaption="Horário"
              />
            </label>
          </div>
        </div>
      </Form>
      <Actions>
        <div className="left">
          <Button
            color={theme.colors.error}
            text={theme.colors.text}
            onClick={handleDelete}
          >
            Apagar
          </Button>
        </div>
        <div className="right">
          <Button
            color={theme.colors.shapesDark}
            text={theme.colors.text}
            to="/admin/sorteios"
          >
            Voltar
          </Button>
          <Button
            color={theme.colors.ticket}
            text={theme.colors.text}
            onClick={initialData ? handleUpdate : handleCreate}
          >
            {initialData ? 'Atualizar sorteio' : 'Criar sorteio'}
          </Button>
        </div>
      </Actions>
    </Container>
  );
}
