import produce from 'immer';

const INITIAL_STATE = {
  details: null,
  loading: true,
};

export default function giveaway(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@giveaway/GET_GIVEAWAY_SUCCESS':
        draft.details = action.payload.data;
        draft.loading = false;
        break;
      case '@giveaway/JOIN_GIVEAWAY_SUCCESS':
        draft.details.tickets.push(...action.payload.tickets);
        break;
      case '@giveaway/CLEAR_GIVEAWAY':
        draft.details = null;
        draft.loading = true;
        break;
      case '@socket/ADD_GIVEAWAY_TICKETS':
        draft.details.giveaway.tickets.push(...action.payload.tickets);
        break;
      default:
        break;
    }
  });
}
