import React from 'react';
import { queue } from '../services/snackbar';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import theme from '../styles/theme';

export default function({ type, title, body, actions }) {
  const Icon = function() {
    switch (type) {
      case 'error':
        return <FaTimesCircle color={theme.colors.error} />;
      default:
        return <FaCheckCircle color={theme.colors.success} />;
    }
  };

  queue.notify({
    title: <h3>{title}</h3>,
    body,
    icon: <Icon size={18} />,
    actions,
  });
}
