import styled from '@xstyled/styled-components';

export const Container = styled.div`
  .header {
    text-align: center;

    > * + * {
      margin-top: 2rem;
    }

    .date {
      margin-top: 0.8rem;
      font-size: 1.4rem;
      font-weight: bold;
      opacity: 0.65;
      letter-spacing: 1.11px;
    }
    img.banner {
      max-width: 100%;
      max-height: 300px;
      object-fit: contain;
      border-radius: default;
      overflow: auto;
    }
  }

  .content {
    margin-top: 4rem;

    > * + * {
      margin-top: 2rem;
    }

    img {
      max-width: 100%;
      object-fit: contain;
      border-radius: default;
      overflow: auto;
    }
  }
`;
