export function consumeGold(amount) {
  return {
    type: '@user/CONSUME_GOLD',
    payload: { amount },
  };
}

export function consumeGift() {
  return {
    type: '@user/CONSUME_GIFT',
  };
}

export function exchangeRequest(amount) {
  return {
    type: '@user/EXCHANGE_REQUEST',
    payload: { amount },
  };
}

export function exchangeSuccess(amount, cost) {
  return {
    type: '@user/EXCHANGE_SUCCESS',
    payload: { amount, cost },
  };
}

export function redeemCodeRequest(code) {
  return {
    type: '@user/REDEEM_CODE_REQUEST',
    payload: { code },
  };
}

export function redeemGoldCodeSuccess(amount) {
  return {
    type: '@user/REDEEM_GOLD_CODE_SUCCESS',
    payload: { amount },
  };
}

export function redeemTicketCodeSuccess(items, isUnique) {
  return {
    type: '@user/REDEEM_TICKET_CODE_SUCCESS',
    payload: { items, isUnique },
  };
}

export function redeemGiftCodeSuccess(items) {
  return {
    type: '@user/REDEEM_GIFT_CODE_SUCCESS',
    payload: { items },
  };
}
