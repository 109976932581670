import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { FaSteamSymbol, FaLink, FaUpload, FaGift, FaKey } from 'react-icons/fa';

import { Container, Form, Actions } from './styles';

import Button from '../../../../components/Button';
import Uploader from '../../../../components/Uploader';
import Select from '../../../../components/Select';
import Switch from '../../../../components/Switch';

import theme from '../../../../styles/theme';

import api from '../../../../services/api';

export default function ProductForm({ onSubmit, initialData }) {
  const [loading, setLoading] = useState(true);
  const [imageIsLink, setImageIsLink] = useState(true);
  const [backgroundIsLink, setBackgroundIsLink] = useState(true);
  const [config, setConfig] = useState({});

  const [category, setCategory] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [productUrl, setProductUrl] = useState('');
  const [image, setImage] = useState('');
  const [background, setBackground] = useState('');
  const [screenshots] = useState([]);
  const [rating, setRating] = useState(null);
  const [brand, setBrand] = useState();
  const [price, setPrice] = useState(0);
  const [enabled, setEnabled] = useState(false);
  const [giftable, setGiftable] = useState(false);

  const ImageUploadButton = imageIsLink ? FaUpload : FaLink;
  const BackgroundUploadButton = backgroundIsLink ? FaUpload : FaLink;

  const { product_id } = useParams();

  const categoriesRef = useRef(null);
  const platformsRef = useRef(null);

  async function handleGetSteamInfo() {
    const response = await api.get('/steam', {
      params: {
        appids: title,
      },
    });

    const { data } = response.data[title];

    setProductUrl(`https://store.steampowered.com/app/${title}`);
    setTitle(data.name);
    setDescription(data.short_description);
    setImage(data.header_image);
    setBackground(data.background);
    setRating(data.metacritic ? data.metacritic.score : 0);
    setBrand(data.developers[0]);
  }

  async function handleSubmit() {
    onSubmit({
      title,
      description,
      image,
      background,
      price,
      category_id: category.value,
      platform_id: platform.value,
      product_url: productUrl,
      product_id,
      enabled,
      is_giftable: giftable,
      screenshots,
      rating,
      brand,
    });
  }

  function loadForm(data) {
    setConfig(data);
    // setCategory(data.categories[0].value);
    // setPlatform(data.platforms[0].value);
    setLoading(false);
  }

  async function handleCategoryKeyDown(e) {
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault();

      try {
        const response = await api.post(`/admin/categories`, {
          name: e.target.value,
        });

        const newCategory = {
          value: response.data.id,
          label: response.data.name,
        };

        setConfig(prev => ({
          ...prev,
          categories: [newCategory, ...prev.categories],
        }));

        categoriesRef.current.blur();

        setCategory(newCategory);
      } catch (err) {}
    }
  }
  async function handlePlatformKeyDown(e) {
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault();

      try {
        const response = await api.post(
          `/admin/categories/${category.value}/platforms`,
          {
            name: e.target.value,
          }
        );

        const newPlatform = {
          value: response.data.id,
          label: response.data.name,
        };

        setConfig(prev => ({
          ...prev,
          platforms: [newPlatform, ...prev.platforms],
        }));

        platformsRef.current.blur();

        setPlatform(newPlatform);
      } catch (err) {}
    }
  }

  useEffect(() => {
    if (product_id) {
      async function getProduct() {
        const response = await api.get(`/admin/shop/${product_id}`);

        const { product, settings } = response.data;

        setCategory({
          label: product.category.name,
          value: product.category.id,
        });
        setPlatform({
          label: product.platform.name,
          value: product.platform.id,
        });

        setTitle(product.title);
        setDescription(product.description);
        setProductUrl(product.product_url);
        setImage(product.image);
        setBackground(product.background);
        setPrice(product.price);
        setEnabled(product.enabled);
        setGiftable(product.is_giftable);
        setBrand(product.brand);
        loadForm(settings);
      }

      getProduct();
    } else {
      async function getConfig() {
        const response = await api.get('/settings/shop');

        loadForm(response.data);
      }

      getConfig();
    }
  }, []); // eslint-disable-line

  return (
    !loading && (
      <Container background={product_id && background}>
        <Form>
          <div className="selects">
            <div className="select">
              <span>Categoria</span>
              <Select
                ref={categoriesRef}
                className="reselect"
                options={config.categories}
                placeholder="Selecione uma categoria"
                onChange={option => setCategory(option)}
                defaultValue={config.categories[0]}
                value={category}
                onKeyDown={handleCategoryKeyDown}
              />
            </div>

            <div className="select">
              <span>Plataforma</span>
              <Select
                ref={platformsRef}
                className="reselect"
                options={config.platforms}
                placeholder="Selecione uma plataforma"
                onChange={option => setPlatform(option)}
                defaultValue={config.platforms[0]}
                value={platform}
                onKeyDown={handlePlatformKeyDown}
              />
            </div>
          </div>

          <div className="row">
            <div className="full">
              <span>Título ou ID de plataforma</span>
              <input
                name="title"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <Button
                type="button"
                onClick={handleGetSteamInfo}
                color={theme.colors.steam}
                text={theme.colors.text}
              >
                <FaSteamSymbol size={16} />
              </Button>
            </div>

            <div className="item">
              <span>Marca</span>
              <input
                name="brand"
                value={brand}
                onChange={e => setBrand(e.target.value)}
              />
            </div>
          </div>

          <div>
            <span>Descrição</span>
            <textarea
              name="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          <div>
            <span>Link do produto</span>
            <input
              name="product_url"
              value={productUrl}
              onChange={e => setProductUrl(e.target.value)}
            />
          </div>
          <div>
            <span>Imagem</span>
            {imageIsLink ? (
              <input
                name="image"
                value={image}
                onChange={e => setImage(e.target.value)}
              />
            ) : (
              <Uploader />
            )}
            <Button
              onClick={() => setImageIsLink(!imageIsLink)}
              color={theme.colors.shapesDark}
              text={theme.colors.text}
            >
              <ImageUploadButton size={16} />
            </Button>
          </div>
          <div>
            <span>Fundo</span>
            {backgroundIsLink ? (
              <input
                name="background"
                value={background}
                onChange={e => setBackground(e.target.value)}
              />
            ) : (
              <Uploader />
            )}
            <Button
              onClick={() => setBackgroundIsLink(!backgroundIsLink)}
              color={theme.colors.shapesDark}
              text={theme.colors.text}
            >
              <BackgroundUploadButton size={16} />
            </Button>
          </div>
          <div>
            <div>
              <span>Preço em gold</span>
              <input
                name="price"
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
              />
            </div>
            <Switch
              checked={enabled}
              onChange={() => setEnabled(prev => !prev)}
            >
              Disponível
            </Switch>
            <Switch
              checked={giftable}
              onChange={() => setGiftable(prev => !prev)}
            >
              <FaGift />
            </Switch>
          </div>
          <Actions>
            <Button
              color={theme.colors.verified}
              text={theme.colors.text}
              to={`/admin/loja/produtos/${product_id}/keys`}
            >
              <FaKey />
            </Button>
            <Button
              type="button"
              color={theme.colors.ticket}
              text={theme.colors.text}
              onClick={handleSubmit}
            >
              {product_id ? 'Atualizar produto' : 'Cadastrar produto'}
            </Button>
          </Actions>
        </Form>
      </Container>
    )
  );
}
