import React, { useState } from 'react';

import { FaTrophy } from 'react-icons/fa';
import { MdPerson } from 'react-icons/md';
import Chart from 'react-apexcharts';
import ptBR from 'apexcharts/dist/locales/pt-br.json';

import Button from '../../../../components/Button';

import { Container } from './styles';
import theme from '../../../../styles/theme';

export default function Stats() {
  const [options, setOptions] = useState({
    chart: {
      zoom: {
        enabled: false,
      },
      background: 'transparent',
      locales: [ptBR],
      defaultLocale: 'pt-br',
      toolbar: {
        show: true,
      },
      fontFamily: 'Roboto',
      parentHeightOffset: 0,
      height: '100%',
    },
    colors: [theme.colors.ticket],
    xaxis: {
      categories: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
    },
    theme: {
      mode: 'dark',
      palette: 'palette1',
    },
    fill: {
      type: 'gradient',
      colors: [theme.colors.ticket],
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.2,
        opacityTo: 0.7,
        stops: [0, 100],
      },
    },
    tooltip: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Tickets da semana',
    },
    subtitle: {
      text: 'Tickets e tickets únicos usados durante os sorteios da semana',
    },
    stroke: {
      colors: [theme.colors.ticket],
    },
  });

  const [series, setSeries] = useState([
    {
      name: 'Tickets',
      data: [341, 415, 450, 500, 350, 760, 410],
    },
  ]);

  return (
    <Container>
      <div className="stats stats1">
        <MdPerson className="icon" size={48} />
        <div>
          <span>Usuários cadastrados</span>
          <strong className="count">1226</strong>
        </div>
      </div>
      <div className="stats stats2">
        <FaTrophy className="icon" size={48} />
        <div>
          <span>Sorteios realizados</span>
          <strong className="count">517</strong>
        </div>
      </div>
      <div className="stats stats3">
        <MdPerson className="icon" size={48} />
        <div>
          <span>Usuários cadastrados</span>
          <strong className="count">1226</strong>
        </div>
      </div>
      <div className="main">
        <div className="chart">
          <Chart options={options} series={series} type="area" />
        </div>
        <div className="stats">
          <Button color={theme.colors.ticket} text={theme.colors.text}>
            Tickets
          </Button>
        </div>
      </div>
    </Container>
  );
}
