import React from 'react';

import { Container, DetailsContainer } from './styles';

export default function ListItem({ children, background, to }) {
  return (
    <Container background={background} className="list-item">
      <DetailsContainer to={to}>{children[0]}</DetailsContainer>
      {children[1]}
    </Container>
  );
}
