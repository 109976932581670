import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toggleDetails } from '../../../store/modules/settings/actions';

import { redeemCodeRequest } from '../../../store/modules/user/actions';

import { Container } from './styles';

import Button from '../../../components/Button';
import Switch from '../../../components/Switch';

import theme from '../../../styles/theme';
import Helmet from '../../../components/Helmet';

import qs from 'query-string';

export default function Settings() {
  const [code, setCode] = useState('');

  const { profile } = useSelector(state => state.user);
  const { details } = useSelector(state => state.settings);

  const dispatch = useDispatch();
  const location = useLocation();

  function handleToggleDetails() {
    dispatch(toggleDetails());
  }

  async function handleCodeSubmit(e) {
    e.preventDefault();

    dispatch(redeemCodeRequest(code));
  }

  useEffect(() => {
    const params = qs.parse(location.search);
    if (params.codigo) {
      return setCode(params.codigo);
    }
    setCode('');
  }, [profile]);

  return (
    <>
      <Helmet title="Configurações" description="Configurações da conta" />
      <Container>
        <div className="item">
          <h2>Geral</h2>
          <div className="row">
            <label>
              <p>Esconder/mostrar detalhes do usuário</p>
              <Switch checked={details} onChange={handleToggleDetails} />
            </label>
          </div>
        </div>
        <div className="item">
          <form onSubmit={handleCodeSubmit}>
            <h2>Resgatar código</h2>
            <div className="row">
              <input
                placeholder="Insira um código válido para resgate"
                type="text"
                value={code}
                onChange={e => setCode(e.target.value)}
              />
              <Button
                type="submit"
                name="code"
                color={theme.colors.verified}
                text={theme.colors.text}
                disabled={code.length !== 36}
              >
                Resgatar
              </Button>
            </div>
          </form>
        </div>
      </Container>
    </>
  );
}
