import React from 'react';

import Highlight from '../Highlight';

import { Container, Content } from './styles';

import Love from '../../../../assets/emotes/peronioLove.png';

export default function Reminder() {
  return (
    <Highlight full>
      <Container>
        <img src={Love} />
        <Content>
          <p>
            Nunca faça uma contribuição para a live (doação, bits, sub, ou
            qualquer outro tipo de contribuição) com a intenção principal e
            única apenas de participar de um sorteio na live ou ganhar algo. Seu
            motivo principal para fazer sua contribuição deve ser{' '}
            <strong>apenas</strong> de ajudar a manter meu trabalho.
          </p>
          <p className="center">
            <strong>
              Todos os sorteios são um <strong>bônus</strong> e devem ser
              encarados como tal.
            </strong>
          </p>
        </Content>
      </Container>
    </Highlight>
  );
}
