import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { SimpleListItem, CollapsibleList } from '@rmwc/list';
import { FaAngleRight, FaSteam } from 'react-icons/fa';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Button from '../../Button';

import { Container, Status, List, RewardInfo } from './styles';

import theme from '../../../styles/theme';

import { generatePlatformLink } from '../../../utils';

export default function GiveawayItem({
  id,
  keys,
  products,
  title,
  status,
  image,
  background,
  formattedCreated,
  onClaim,
  expiresAt,
  example,
  platform,
  ...rest
}) {
  const [loading, setLoading] = useState(false);

  function renderStatus(status) {
    switch (status) {
      case 'pending':
        return <Status color={theme.colors.verified}>Pendente</Status>;
      case 'cancelled':
        return <Status color={theme.colors.error}>Cancelado</Status>;
      case 'done':
        return <Status color={theme.colors.success}>Enviado</Status>;
      default:
        return;
    }
  }

  function handleClaim() {
    if (example) return;
    setLoading(true);
    onClaim();
  }

  function RenderInfo({ background, platform, code, needs_key }) {
    const [keyVisible, setKeyVisible] = useState(false);

    const [copied, setCopied] = useState(false);

    function copy() {
      navigator.clipboard.writeText(code.value);

      setCopied(true);
    }

    return (
      <RewardInfo background={background} keyVisible={keyVisible}>
        {needs_key ? (
          <p className="key">
            {keyVisible ? code.value || code : 'XXXXX-XXXXX-XXXXX-XXXXX-XXXXX'}
          </p>
        ) : (
          <h3>
            Você recebeu uma mensagem no seu e-mail cadastrado na Twitch com
            instruções. Por favor, leia e responda.
          </h3>
        )}
        {needs_key && (
          <div className="actions">
            <Button
              color={theme.colors.translucentDark}
              text={theme.colors[copied ? 'success' : 'text']}
              onClick={copy}
            >
              {copied ? 'Copiado!' : 'Copiar'}
            </Button>
            <Button
              color={theme.colors.primary}
              text={theme.colors.text}
              onClick={() => setKeyVisible(prev => !prev)}
            >
              {keyVisible ? 'Esconder' : 'Mostrar'}
            </Button>
            {platform.name === 'Steam' && (
              <Button
                url={generatePlatformLink('steam', code.value)}
                color={theme.colors.steam}
                text={theme.colors.text}
              >
                <FaSteam />
                <span>Ativar</span>
              </Button>
            )}
          </div>
        )}
      </RewardInfo>
    );
  }

  useEffect(() => {
    setLoading(false);
  }, [status]);

  return (
    <Container background={background} {...rest}>
      <div className="details">
        <div>
          <h2>{title}</h2>
          <strong className="date">{formattedCreated}</strong>
        </div>
        {status === 'unclaimed' ? (
          <div className="right">
            <Button
              color={theme.colors.giveaway}
              text={theme.colors.text}
              onClick={handleClaim}
              loading={loading}
              pulse
            >
              Resgatar premiação
            </Button>
            <strong className="date">
              Expira em{' '}
              {format(new Date(expiresAt), 'dd/MM/yy', {
                locale: ptBR,
              })}
            </strong>
          </div>
        ) : (
          <div className="right">
            <strong className="status">Status: {renderStatus(status)}</strong>
            {status !== 'done' && (
              <Link to="/como-funciona/prazos">
                <strong>Saiba mais sobre os prazos</strong>
              </Link>
            )}
          </div>
        )}
      </div>
      {status === 'done' && (
        <div className="rewards">
          <List>
            {products.map(product => {
              return (
                <CollapsibleList
                  key={product.id}
                  handle={
                    <SimpleListItem
                      text={product.title}
                      metaIcon={<FaAngleRight />}
                    />
                  }
                >
                  <RenderInfo
                    code={keys.find(k => k.product.id === product.id)}
                    {...product}
                  />
                </CollapsibleList>
              );
            })}
          </List>
        </div>
      )}
    </Container>
  );
}
