import styled from '@xstyled/styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2rem;

  > * + * {
    margin-top: 2rem;
  }
`;

export const Item = styled.div`
  background-color: shapesDark;
  border-radius: default;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;

  > div {
    margin-left: 3rem;
  }
`;

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 1rem;
  }

  .row {
    display: flex;
    > * + * {
      margin-left: 1rem;
    }

    img {
      margin-top: 2rem;
    }
  }
`;
