import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';

import { Container, Input } from './styles';

function SearchBox({
  children,
  icon: Icon,
  placeholder,
  value,
  onChange,
  loading,
}) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!loading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [loading]);

  return (
    <Container>
      <Input loading={loading ? 1 : 0}>
        <Icon size={18} />
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={loading}
        />
      </Input>
      <div className="actions">{children}</div>
    </Container>
  );
}

SearchBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  placeholder: PropTypes.string.isRequired,
};

SearchBox.defaultProps = {
  children: null,
  icon: null,
};

export default memo(SearchBox);
