import React from 'react';
import PropTypes from 'prop-types';

import ReactSwitch from 'react-switch';

import theme from '../../styles/theme';

import { Container } from './styles';

function Switch({ children, onChange, checked, height, light }) {
  return (
    <Container>
      <span>{children}</span>
      <ReactSwitch
        height={height}
        checkedIcon={null}
        uncheckedIcon={null}
        width={28}
        activeBoxShadow={null}
        checked={checked}
        onChange={onChange}
        offColor={light ? theme.colors.shapes : theme.colors.shapesDark}
        onColor={theme.colors.primary}
        handleDiameter={height - 6}
      />
    </Container>
  );
}

Switch.propTypes = {
  height: PropTypes.number,
  light: PropTypes.bool,
};

Switch.defaultProps = {
  height: 16,
  light: false,
};

export default Switch;
