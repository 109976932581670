import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Container } from './styles';

import api from '../../../../services/api';
import history from '../../../../services/history';

import GiveawayForm from '../GiveawayForm';

export default function Giveaway() {
  const [loading, setLoading] = useState(true);
  const [giveaway, setGiveaway] = useState(null);
  const [settings, setSettings] = useState(null);

  const { giveaway_id } = useParams();

  async function handleSubmit(data) {
    await api.put(`/admin/giveaways/${giveaway_id}`, data);

    history.push('/admin/sorteios');
  }

  useEffect(() => {
    async function getGiveaway() {
      const response = await api.get(`/admin/giveaways/${giveaway_id}`);

      const formattedGiveaway = {
        ...response.data.giveaway,
        products: response.data.giveaway.products.map(p => ({
          value: p.id,
          label: p.title,
          image: p.image,
          background: p.background,
        })),
      };

      setGiveaway(formattedGiveaway);
      setSettings(response.data.settings);
      setLoading(false);
    }

    getGiveaway();
  }, []); // eslint-disable-line

  return (
    !loading && (
      <GiveawayForm
        onSubmit={handleSubmit}
        initialData={{ ...giveaway }}
        settings={settings}
      />
    )
  );
}
