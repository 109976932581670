import React from 'react';

import { Link } from 'react-router-dom';

import Quote from '../components/Quote';
import Highlight from '../components/Highlight';
import Reminder from '../components/Reminder';

import Button from '../../../components/Button';
import GiveawayCard from '../../../components/GiveawayCard';
import GiveawayItem from '../../../components/account/GiveawayItem';

import { addDays } from 'date-fns';

import theme from '../../../styles/theme';

import Helmet from '../../../components/Helmet';

export default function Giveaways() {
  return (
    <>
      <Helmet
        title="Sorteios"
        description="Saiba como funciona os sorteios e como participar de cada um deles"
      />

      <div className="post">
        <h1>Sorteios! Sorteios e mais sorteios!</h1>
        <Quote>
          Para ver todos os sorteios ativos neste momento, acesse nossa{' '}
          <Link to="/sorteios">página de sorteios</Link>
        </Quote>
        <h2>Todos podem participar dos sorteios!</h2>
        <p>
          Existem duas formas básicas de participar de um sorteio: Com{' '}
          <strong className="color-ticket">tickets</strong> ou entrando em
          sorteios abertos.
        </p>
        <Highlight>
          <Button
            to="/como-funciona/tickets"
            color={theme.colors.translucentDark}
            text={theme.colors.ticket}
          >
            Clique aqui para saber mais sobre os tickets
          </Button>
        </Highlight>
        <p>
          Se você tem um <strong className="color-ticket">ticket</strong>, você
          pode entrar em{' '}
          <strong className="color-giveaway">qualquer sorteio</strong> ativo na
          live. A diferença entre eles é somente quando este sorteio vai
          ocorrer:
        </p>
        <h3>
          Sorteio com limite de{' '}
          <strong className="color-ticket">tickets</strong>
        </h3>
        <Highlight>
          <GiveawayCard
            title="Sorteio especial"
            image="https://steamcdn-a.akamaihd.net/steam/apps/883710/header.jpg?t=1576641977"
            ends_at={null}
            limit={50}
            tickets={new Array(16)}
            auto_lock
            level={0}
            example
          />
        </Highlight>
        <p>
          Neste tipo de sorteio há um número exato de{' '}
          <strong className="color-ticket">tickets</strong> que ele aceita
          (sendo <strong className="color-ticket">tickets</strong> ou{' '}
          <strong className="color-twitch">purple tickets</strong>) e quando
          chegar a este número o sorteio é <strong>trancado</strong>, não
          permitindo a entrada de{' '}
          <strong className="color-ticket">tickets</strong> adicionais.
        </p>
        <p>
          O sorteio é realizado imediatamente após o número de{' '}
          <strong className="color-ticket">tickets</strong> ser atingido
          (normalmente já na próxima live ou até na mesma live caso ele se feche
          durante)
        </p>
        <h3>
          Sorteio com mínimo de{' '}
          <strong className="color-ticket">tickets</strong> SEM data
          pré-definida
        </h3>
        <Highlight>
          <GiveawayCard
            title="Sorteio especial"
            image="https://steamcdn-a.akamaihd.net/steam/apps/883710/header.jpg?t=1576641977"
            ends_at={null}
            limit={500}
            tickets={new Array(266)}
            level={0}
            example
          />
        </Highlight>
        <p>
          Neste tipo de sorteio, há um número <strong>mínimo</strong> de{' '}
          <strong className="color-ticket">tickets</strong> para o mesmo
          ocorrer, mas não há um limite, podendo entrar{' '}
          <strong className="color-ticket">tickets</strong> adicionais depois de
          o mínimo ser atingido.
        </p>
        <p>
          O sorteio é realizado imediatamente após o mínimo ser atingido
          (normalmente já na próxima live ou até na mesma live caso ele se feche
          durante){' '}
        </p>
        <h3>
          Sorteio com mínimo de{' '}
          <strong className="color-ticket">tickets</strong> COM data
          pré-definida
        </h3>
        <Highlight>
          <GiveawayCard
            title="Sorteio especial"
            image="https://steamcdn-a.akamaihd.net/steam/apps/883710/header.jpg?t=1576641977"
            ends_at={new Date(addDays(new Date(), 3))}
            limit={50}
            tickets={new Array(16)}
            level={0}
            example
          />
        </Highlight>
        <p>
          Neste tipo de sorteio, há um número <strong>mínimo</strong> de{' '}
          <strong className="color-ticket">tickets</strong> para o mesmo
          ocorrer, mas não há um limite, podendo entrar{' '}
          <strong className="color-ticket">tickets</strong> adicionais depois de
          o mínimo ser atingido.
        </p>
        <p>
          O sorteio é realizado na data estipulada, e caso ao chegar na data o
          número mínimo de <strong className="color-ticket">tickets</strong> não
          tiver sido atingido, o sorteio é cancelado e os{' '}
          <strong className="color-ticket">tickets</strong> utilizados são
          ressarcidos voltando a ficarem disponíveis na conta do forte para usos
          em outros sorteios.
        </p>
        <h3>Sorteios abertos com pontos</h3>
        <p>
          Os sorteios abertos a <strong>todos os fortes</strong> continuam
          ativos, e são feitos sem datas ou programação pré-definida.
        </p>
        <p>
          Para participar desses sorteios, basta estar assistindo a live no
          momento do sorteio e interagir da maneira devida (digitando algo no
          chat, gastando <strong>pontos</strong>, etc).
        </p>
        <h3>Resgate e Recebimento de Premiações</h3>
        <p>
          Ao ser contemplado em um sorteio, você deve resgatar o mesmo em sua
          conta. Para resgatar sua premiação, basta acessar a aba{' '}
          <Link className="color-primary" to="/premios">
            <strong className="color-primary">Premios</strong>
          </Link>{' '}
          em sua conta e você irá encontrar um item com esse modelo:
        </p>
        <Highlight full>
          <GiveawayItem
            title="Sorteio especial para subscribers"
            background="https://steamcdn-a.akamaihd.net/steam/apps/731490/page_bg_generated_v6b.jpg?t=1568841571"
            status="unclaimed"
            expiresAt={new Date(addDays(new Date(), 30))}
            formattedCreated="Há 5 minutos"
            example
          />
        </Highlight>
        <p>
          Depois de resgatar, caso você seja um{' '}
          <Link className="color-verified" to="/como-funciona/verificado">
            <strong className="color-verified">usuário verificado</strong>
          </Link>{' '}
          pelo sistema, você recebe <strong>imediatamente</strong> produtos
          digitais em sua conta, e produtos físicos são enviados em até 5 dias
          úteis após o recebimentos dos dados de envio.
        </p>
        <p>
          Caso você não seja um{' '}
          <strong className="color-verified">usuário verificado</strong>, após
          resgatar uma premiação, ela ficará como <strong>pendente</strong> e
          será enviada conforme os prazos descritos na página de{' '}
          <Link to="/como-funciona/prazos">
            <strong>prazos</strong>
          </Link>
          .
        </p>

        <p>
          Lembrando que você{' '}
          <strong>não precisa estar online no momento dos sorteios</strong>,
          porém precisa resgatá-los antes do tempo limite de{' '}
          <strong>72 horas</strong> após a realização do sorteio.
        </p>

        <Reminder />
      </div>
    </>
  );
}
