import React from 'react';
import { NavLink } from 'react-router-dom';

import { Container } from './styles';

export default function AccountMenu() {
  return (
    <Container>
      <NavLink to="/premios">Premios</NavLink>
      <NavLink to="/pedidos">Pedidos</NavLink>
      <NavLink to="/historico">Histórico</NavLink>
      <NavLink to="/conta">Configurações</NavLink>
    </Container>
  );
}
