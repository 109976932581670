import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { Router } from 'react-router-dom';

import Routes from './routes';

import { ThemeProvider } from '@xstyled/styled-components';
import GlobalStyle from './styles/global';
import theme from './styles/theme';
import history from './services/history';
import { SnackbarQueue } from '@rmwc/snackbar';
import { queue } from './services/snackbar';

import Header from './components/Header';
import Dialog from './components/Dialog';

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton,
} from '@rmwc/dialog';

function App() {
  const [open, setOpen] = React.useState(false);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <div className="wrapper">
              <Header />
              <Routes />
              <Dialog
                open={open}
                onClose={evt => {
                  console.log(evt.detail.action);
                  setOpen(false);
                }}
              >
                <DialogContent>
                  <h2 className="center">⚠️ Atenção</h2>

                  <p>Devido à uma mudança na API, algumas funcionalidades relacionadas com a Twitch estão <strong>indisponíveis</strong>, como a troca de pontos e sorteios especiais de aniversário.</p>
                </DialogContent>
                <DialogActions>
                  <DialogButton action="accept" isDefaultAction>
                    Entendi
                  </DialogButton>
                </DialogActions>
              </Dialog>
            </div>
            <GlobalStyle />

            <SnackbarQueue messages={queue.messages} />
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
