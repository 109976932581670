import React from 'react';

import { Link } from 'react-router-dom';

import Highlight from '../components/Highlight';

import ShopItem from '../../Shop/ShopItem';

import { FaGift } from 'react-icons/fa';

import Helmet from '../../../components/Helmet';

export default function Gifts() {
  return (
    <>
      <Helmet
        title="Gifts"
        description="Saiba mais pra que serve os gifts do sistema"
      />

      <div className="post">
        <h1>Gifts da Loja</h1>

        <p>
          Caso você tenha recebido um{' '}
          <strong className="color-gift">gift</strong> você pode trocá-lo
          diretamente na loja por um produto (seja ele digital ou físico) desde
          que o mesmo seja um produto presenteável.
        </p>
        <p>
          Para saber se um produto da loja é presenteável, basta ativar o{' '}
          <strong>
            filtro <FaGift className="color-gift" size={12} />
          </strong>{' '}
          ou apenas verificar se o mesmo possui um contorno vermelho na loja:
        </p>
        <Highlight>
          <ShopItem
            title="Dead by Daylight"
            brand="Behaviour Digital Inc."
            is_giftable
            image="https://steamcdn-a.akamaihd.net/steam/apps/381210/header.jpg?t=1578696045"
            price={5000}
            keys={12}
            example
          />
        </Highlight>

        <p>
          Os prazos para recebimento dos produtos adquiridos com{' '}
          <strong className="color-gift">gifts</strong> são os mesmos para
          compras ou sorteios. Passar saber mais, acesse página de{' '}
          <Link to="/como-funciona/prazos">prazos</Link>.
        </p>
      </div>
    </>
  );
}
