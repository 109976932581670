import React from 'react';

import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';

import { Container, Product } from './styles';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

import theme from '../../../../styles/theme';

export default function Pendency({
  id,
  type,
  user,
  giveaway,
  product,
  onSend,
  created_at,
}) {
  function Message() {
    switch (type) {
      case 'giveaway':
        return (
          <span>
            <strong>{user.username}</strong> resgatou uma premiação
          </span>
        );
      case 'order':
        return (
          <span>
            <strong>{user.username}</strong> comprou um item na loja
          </span>
        );
      case 'gift':
        return (
          <span>
            <strong>{user.username}</strong> resgatou um gift
          </span>
        );
    }
  }

  const details = giveaway || product;
  const products = giveaway ? giveaway.products : [product];

  function renderProduct({ title, needs_key }) {
    const Icon = needs_key ? FaCheckCircle : FaExclamationTriangle;

    return (
      <>
        <Icon />
        <span>
          <strong>{title}</strong>{' '}
          {!needs_key && (
            <span>
              será notificado ao ganhador via <strong>e-mail</strong>
            </span>
          )}
        </span>
      </>
    );
  }

  const productsWithKeys = products.map(p => p.needs_key).filter(p => p).length;
  const productsReady = products.map(p => !!p.keys.length).filter(p => p)
    .length;

  return (
    <Container>
      <div className="details">
        <div className="left">
          <Avatar
            url={user.avatar}
            admin={user.is_admin}
            verified={user.is_verified}
          />
          <div className="info">
            <p className="message">
              <Message />
            </p>
            <p>
              <strong className="title">{details.title}</strong> &middot;{' '}
              {format(new Date(created_at), "dd/MM/yy', às' HH:mm", {
                locale: ptBR,
              })}
            </p>
          </div>
        </div>
        <div className="actions">
          <Button
            color={theme.colors.code}
            text={theme.colors.text}
            onClick={() => onSend(id)}
            pulse
          >
            Enviar premiação
          </Button>
        </div>
      </div>
      <div className="products">
        {products.map(p => (
          <Product key={p.id} needsKey={p.needs_key}>
            {renderProduct(p)}
          </Product>
        ))}
      </div>
    </Container>
  );
}
