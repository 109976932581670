import React, { useEffect, useState } from 'react';

import { Container } from './styles';

import Search from '../../../components/admin/Search';
import Button from '../../../components/Button';

import UserItem from './UserItem';

import api from '../../../services/api';
import theme from '../../../styles/theme';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  async function handleVerify(id) {
    try {
      await api.post(`/admin/users/${id}/verify`);

      setUsers(prev =>
        prev.map(user =>
          user.id === id ? { ...user, is_verified: !user.is_verified } : user
        )
      );
    } catch (err) {}
  }

  async function loadMore() {
    setPage(prev => prev + 1);
  }

  function handleSearch(value) {
    setPage(0);
    setUsers([]);
    setSearch(value);
  }

  useEffect(() => {
    async function getUsers() {
      const response = await api.get('/admin/users', {
        params: {
          search,
          page,
        },
      });

      setUsers(prev => [...prev, ...response.data.users]);

      setCount(response.data.count);
    }

    getUsers();
  }, [search, page]);

  return (
    <Container>
      <Search placeholder="Busque um usuário..." onChange={handleSearch} />

      <div className="users">
        {users.map(user => (
          <UserItem onVerify={handleVerify} key={user.id} {...user} />
        ))}
      </div>

      {users.length < count && (
        <Button
          className="load-more"
          onClick={loadMore}
          color={theme.colors.translucentDark}
          text={theme.colors.text}
        >
          Carregar mais
        </Button>
      )}
    </Container>
  );
}
