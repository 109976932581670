import React from 'react';

import { Container, Link } from './styles';

export default function Sidebar() {
  return (
    <Container>
      <Link exact to="/admin">
        Dashboard
      </Link>
      <Link exact to="/admin/chatbot">
        Botonio
      </Link>
      <Link exact to="/admin/treasures" isNew>
        Tesouros
      </Link>
      <Link exact to="/cofres" isNew>
        Cofres
      </Link>
      <Link exact to="/admin/pendencias">
        Pendencias
      </Link>
      <Link exact to="/admin/sorteios">
        Sorteios
      </Link>
      <Link exact to="/admin/loja">
        Produtos
      </Link>
      <Link exact to="/admin/usuarios">
        Usuários
      </Link>
      <Link exact to="/admin/codigos">
        Códigos
      </Link>
      <Link exact to="/admin/configuracoes">
        Configurações
      </Link>
    </Container>
  );
}
