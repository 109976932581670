import styled, { css } from '@xstyled/styled-components';
import { easeOutCirc } from 'eazy-ease';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;

  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ${easeOutCirc};

  .fade {
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  .content {
    position: absolute;

    .participants {
      text-align: center;
    }

    .actions {
    }
  }

  ${({ visible }) =>
    visible &&
    css`
      z-index: 10;
      visibility: visible;
      opacity: 1;
    `}
`;

export const Actions = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  visibility: hidden;
  opacity: 0;
  transition: all 1s ${easeOutCirc};

  > * + * {
    margin-top: 1rem;
  }

  .row {
    display: flex;
    > * + * {
      margin-left: 1rem;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * + * {
      margin-top: 1rem;
    }

    span {
      font-size: 1.8rem;
    }
  }

  .space-bottom {
    margin-bottom: 2rem;
  }

  .space-top {
    margin-top: 2rem;
  }

  .big {
    font-size: 2.8rem !important;
  }

  .prize {
    height: 12rem;
  }

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
