import React from 'react';
import { useSelector } from 'react-redux';

import { Wrapper, Container, Content } from './styles';

import AccountMenu from '../../components/AccountMenu';
import UserDetails from '../../components/account/UserDetails';

export default function AccountLayout({ children }) {
  const { profile } = useSelector(state => state.user);

  return (
    <Wrapper>
      <Container>
        <Content>
          <UserDetails profile={profile} />
          <AccountMenu />
          <section>{children}</section>
        </Content>
      </Container>
    </Wrapper>
  );
}
