import styled from '@xstyled/styled-components';
import { easeOutCirc } from 'eazy-ease';

import Masonry from 'react-masonry-css';

export const Container = styled.div`
  max-width: 980px;
  margin: 0 auto;
  padding: page;
`;

export const Grid = styled(Masonry).attrs({
  breakpointCols: 2,
  columnClassName: 'grid-column',
})`
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -1.2rem; /* gutter size offset */
  width: auto;
  font-size: 1.6rem;
  line-height: 3rem;

  .grid-column {
    padding-left: 1.2rem; /* gutter size */
    background-clip: padding-box;

    > div {
      background-color: shapesDark;
      margin-bottom: 1.2rem;
      padding: 2rem;
      border-radius: default;

      transition: all 0.2s ${easeOutCirc};

      :hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.2);
      }

      a {
        color: inherit;

        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h3 {
            text-transform: uppercase;
          }
        }

        p {
          margin-top: 2rem;
        }
      }
    }
  }
`;
