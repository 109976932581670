import styled from '@xstyled/styled-components';

import { Link } from 'react-router-dom';

import { easeOutCirc } from 'eazy-ease';

export const Container = styled(Link)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.6rem;
  font-weight: bold;

  transition: all 0.1s ${easeOutCirc};

  :hover {
    transform: translateX(2px);
  }

  > * {
    display: flex;
    align-items: center;
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;

  .info {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;

    .email {
      margin-top: 0.6rem;
      font-size: 1.2rem;
      font-weight: normal;
    }
  }
`;

export const Stats = styled.div`
  > div {
    margin-left: 1rem;
    strong {
      margin-left: 1rem;
    }
  }
`;
