import React from 'react';

import { FaTicketAlt, FaGift, FaCoins, FaCheckCircle } from 'react-icons/fa';

import { Container, Details, Stats } from './styles';

import Avatar from '../../../../components/Avatar';
import Switch from '../../../../components/Switch';

import theme from '../../../../styles/theme';

export default function UserItem({
  id,
  email,
  avatar,
  username,
  is_admin,
  is_verified,
  gold,
  tickets,
  gifts,
  onVerify,
}) {
  return (
    <Container
      to={`/admin/usuarios/${id}`}
      admin={is_admin}
      verified={is_verified}
    >
      <Details>
        <Avatar
          url={avatar}
          verified={is_verified}
          admin={is_admin}
          size={38}
        />
        <div className="info">
          <span className="username">{username}</span>
          <span className="email">{email}</span>
        </div>
      </Details>
      <Stats>
        <div>
          <Switch checked={is_verified} onChange={() => onVerify(id)} light>
            <FaCheckCircle size={10} />
          </Switch>
        </div>
        <div>
          <FaCoins size={12} color={theme.colors.gold} />
          <strong>{gold}</strong>
        </div>
        <div>
          <FaTicketAlt size={12} color={theme.colors.ticket} />
          <strong>{tickets.length}</strong>
        </div>
        <div>
          <FaGift size={12} color={theme.colors.gift} />
          <strong>{gifts.length}</strong>
        </div>
      </Stats>
    </Container>
  );
}
