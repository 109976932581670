import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { exchangeRequest } from '../../store/modules/user/actions';

import Button from '../../components/Button';

import { Container } from './styles';

import theme from '../../styles/theme';

import Badge from './Badge';

import calculateExchange from '../../utils/calculateExchange';

export default function Points() {
  const [exchange, setExchange] = useState('');
  // const [isValid, setIsValid] = useState(false);

  const {
    profile: { gold },
    elements: { points },
    twitch: { subscription },
  } = useSelector(state => state.user);
  const { defaults } = useSelector(state => state.info);

  const dispatch = useDispatch();

  const exchangeLimit = useMemo(() => {
    return calculateExchange(points, subscription && subscription.tier);
  }, []);

  const pointsResult = useMemo(() => {
    if (!subscription) {
      return points - defaults.gold.price * Number(exchange);
    }
    switch (subscription.tier) {
      case '1000':
        return points - defaults.gold.subPrice[0] * Number(exchange);
      case '2000':
        return points - defaults.gold.subPrice[1] * Number(exchange);
      case '3000':
        return points - defaults.gold.subPrice[2] * Number(exchange);
    }
  }, [exchange]);

  const isValid = useMemo(() => {
    return !(+exchange > exchangeLimit || +exchange < 1);
  }, [exchange]);

  function handleExchange() {
    dispatch(exchangeRequest(exchange));
  }

  useEffect(() => {
    setExchange('');
  }, [gold]);

  return (
    <Container>
      <Link to="/como-funciona" className="learn-more">
        Quer saber mais sobre como funciona os pontos e subscribers? Clique aqui
        para entender
      </Link>

      <div className="badges">
        <Badge active={!subscription} />
        <Badge tier={1} active={subscription && subscription.tier === '1000'} />
        <Badge tier={2} active={subscription && subscription.tier === '2000'} />
        <Badge tier={3} active={subscription && subscription.tier === '3000'} />
      </div>

      <div className="points">
        {pointsResult ? (
          <>
            <p>Você tem</p>
            <h2>{points} pontos</h2>
            <p>
              e pode trocar por{' '}
              {subscription ? (
                <>
                  <strong className="color-gold stroke">
                    {calculateExchange(points)} gold
                  </strong>{' '}
                  <strong className="color-gold">{exchangeLimit} gold</strong>
                </>
              ) : (
                <strong className="color-gold">{exchangeLimit} gold</strong>
              )}
            </p>
            <div className="exchange">
              <div className="input">
                <input
                  type="number"
                  max={1000}
                  min="1"
                  placeholder="Insira a quantidade de gold"
                  value={exchange}
                  onChange={e => setExchange(e.target.value)}
                />
                <Button
                  color={theme.colors.gold}
                  disabled={!isValid}
                  pulse={isValid}
                  onClick={handleExchange}
                >
                  TROCAR
                </Button>
              </div>

              {exchange !== '' && (
                <>
                  {isValid ? (
                    <p className="result">
                      Efetuando a troca você terá{' '}
                      <strong>{pointsResult} pontos</strong> e{' '}
                      <strong className="color-gold">
                        {gold + Number(exchange)} gold
                      </strong>
                    </p>
                  ) : (
                    <p className="result error">
                      Insira uma quantidade válida para troca.
                    </p>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <h3>Você precisa de pontos para realizar trocas.</h3>
        )}
      </div>
    </Container>
  );
}
