import styled, { css, keyframes } from '@xstyled/styled-components';
import { darken, transparentize } from 'polished';

import { easeOutExpo } from 'eazy-ease';

import { pulseEffect, loadingEffect } from '../../styles/keyframes';

export const Container = styled.button`
  user-select: none;
  color: ${({ text }) => text};
  padding: default;
  font-size: 1.4rem;
  border: 0;
  font-weight: bold;
  border-radius: default;
  background-color: ${({ color }) => color};

  transition: all 0.1s ${easeOutExpo};

  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.2s ease-out;
  animation: ${({ color, colors, pulse }) =>
    pulse && pulseEffect(colors ? colors[1] : color)} 3s infinite;

  position: relative;

  > span {
    /* :disabled {
      opacity: ${({ disabled }) => (disabled ? 0 : 1)};
    } */
    transition: opacity 0.3s ease;

    display: flex;
    align-items: center;
    > * + * {
      margin-left: 1rem;
    }

    ${({ loading }) =>
      loading &&
      css`
        color: transparent;
      `}
  }

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: ${({ disabled }) => (disabled ? 1 : 0)};

    transition: opacity 0.3s ease;

    svg {
      animation: ${loadingEffect} 1s infinite linear;
    }
  }

  :active {
    transform: translateY(2px);
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ color, disabled }) =>
    !disabled &&
    css`
      :hover {
        background-color: ${darken(0.08, color)};
      }
    `}

  ${({ icon }) =>
    icon &&
    css`
      padding: 1rem !important;

      :hover {
        color: primary;
      }
    `}

  ${({ colors }) =>
    colors &&
    css`
      background-image: linear-gradient(to right, ${colors[0]}, ${colors[1]});
    `}
`;
