import styled from '@xstyled/styled-components';

import theme from '../../../../styles/theme';

import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem;
  border: 2px solid;
  border-color: primary;
  font-size: 1.4rem;
  line-height: 2rem;
  border-radius: default;
  background-color: shapesDark;

  img {
    width: 42px;
    margin: 0 2.6rem 0 1.5rem;
  }
`;

export const Content = styled.div`
  flex: 1;
  text-align: center;

  > * + * {
    margin-top: 2rem;
  }
`;
