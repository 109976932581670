import React, { useMemo } from 'react';

import { format, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { FaCoins, FaTicketAlt, FaGift } from 'react-icons/fa';

import Avatar from '../../Avatar';

import { Container } from './styles';

import { partition } from 'lodash';

export default function UserDetails({ profile }) {
  const [unique, common] = useMemo(() => {
    if (!profile) return [[], []];
    return partition(profile.tickets, 'is_unique');
  }, [profile]);

  return (
    <Container>
      <div className="avatar">
        <Avatar url={profile.avatar} size={112} />
      </div>
      <div className="content">
        <div className="info">
          <div className="profile">
            <h2>{profile.username}</h2>
            <span>{profile.email}</span>
          </div>

          <div className="inventory">
            <div className="item">
              <FaCoins className="color-gold" /> <strong>{profile.gold}</strong>
            </div>
            <div className="item">
              <FaTicketAlt className="color-ticket" />{' '}
              <strong>{common.length}</strong> <span>&middot;</span>
              <FaTicketAlt className="color-twitch" />{' '}
              <strong>{unique.length}</strong>
            </div>
            <div className="item">
              <FaGift className="color-gift" />
              <strong>{profile.gifts.length}</strong>
            </div>
          </div>
        </div>
        <div className="stats">
          <p>
            Participa há{' '}
            <strong>
              {formatDistance(new Date(), new Date(profile.created_at), {
                locale: ptBR,
              })}
            </strong>
          </p>
          {/* <p>
            Total em doações: <strong className="color-slabs">R$76,00</strong>
          </p>
          <p>
            Subscriber <strong className="color-twitch">há 2 meses</strong>
          </p> */}
        </div>
      </div>
    </Container>
  );
}
