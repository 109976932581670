import React, { useState } from 'react';

import { TabBar } from '@rmwc/tabs';
import Tab from '../../../../../components/Tab';

import GiveawayItem from '../../../../../components/account/GiveawayItem';
import ProductItem from '../../../../../components/account/ProductItem';
import LogItem from '../../../../../components/account/LogItem';

import { Container } from './styles';

import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';

function Rewards({ data }) {
  const formattedRewards = data.map(reward => ({
    ...reward,
    formattedCreated: formatDistance(new Date(reward.created_at), new Date(), {
      locale: ptBR,
    }),
  }));

  return (
    <div className="rewards">
      {formattedRewards.map(reward => {
        if (reward.giveaway) {
          const { giveaway, keys } = reward;

          return (
            <GiveawayItem
              key={giveaway.id}
              id={reward.id}
              status={reward.status}
              formattedCreated={reward.formattedCreated}
              expiresAt={reward.expires_at}
              onClaim={null}
              keys={keys}
              {...giveaway}
            />
          );
        }
      })}
    </div>
  );
}
function Orders({ data }) {
  return (
    <div className="orders">
      {data.map(order => (
        <ProductItem key={order.id} {...order} />
      ))}
    </div>
  );
}
function Logs({ data }) {
  return (
    <div className="logs">
      {data.docs.map(log => (
        <LogItem key={log._id} {...log} />
      ))}
    </div>
  );
}

export default function UserTabs({ rewards, orders, logs }) {
  const [tabIndex, setTabIndex] = useState(0);

  function TabContent() {
    switch (tabIndex) {
      case 0:
        return <Rewards data={rewards} />;
      case 1:
        return <Orders data={orders} />;
      case 2:
        return <Logs data={logs} />;
      default:
        break;
    }
  }

  return (
    <Container>
      <TabBar
        activeTabIndex={tabIndex}
        onActivate={e => setTabIndex(e.detail.index)}
      >
        <Tab label="Premiações" />
        <Tab label="Pedidos" />
        <Tab label="Histórico" />
      </TabBar>
      <div className="content">
        <TabContent />
      </div>
    </Container>
  );
}
