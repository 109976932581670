import styled, { css } from '@xstyled/styled-components';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  padding: huge;
  font-size: 1.2rem;
  height: 100%;

  ${({ background }) =>
    background &&
    css`
      padding: huge;
      background-image: linear-gradient(
          to top,
          ${theme.colors.shapes},
          transparent
        ),
        linear-gradient(to left, ${theme.colors.shapes}, transparent),
        url(${background});
      background-size: cover;
    `}

  header {
    display: flex;

    .info {
      display: flex;
      flex-direction: column;
      > * + * {
        margin-top: 6px;
      }
    }

    > * + * {
      margin-left: 1rem;
    }

    img {
      height: 96px;
      border-radius: default;
    }

    h2 {
      font-size: 2rem;
    }
  }

  .keys__key-new {
    margin-top: 2rem;
    display: flex;

    input {
      font-size: 1.6rem;
      flex: 1;
      border-radius: default;
      background-color: shapesDark;
      color: text;
      padding: 1rem 1.5rem;
      letter-spacing: 2px;
    }

    button {
      margin-left: 1rem;
    }
  }
`;

export const KeyList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  font-size: 1.6rem;

  > * + * {
    margin-top: 1rem;
  }
`;

export const Key = styled.div`
  background-color: shapesDark;
  padding: 1rem;
  border-radius: default;
  letter-spacing: 2px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    padding: 0 0.5rem;

    ${({ reserved }) =>
      reserved &&
      css`
        color: gold;
      `}
    ${({ redeemed }) =>
      redeemed &&
      css`
        color: gift !important;
      `}
  }

  .actions {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 1rem;
    }
  }

  ${({ visible }) =>
    !visible &&
    css`
      strong {
        filter: blur(2px);
      }
    `}
`;
