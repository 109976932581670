import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';

import { FaCircleNotch } from 'react-icons/fa';
import Tippy from '@tippy.js/react';
import { roundArrow } from 'tippy.js/dist/tippy.esm';

import { Container } from './styles';

import history from '../../services/history';

function Button({
  centered,
  children,
  color,
  colors,
  text,
  type,
  onClick,
  to,
  url,
  pulse,
  icon,
  loading,
  tip,
  ...rest
}) {
  function handleClick() {
    if (url) return window.open(url, '_blank');
    if (onClick) return onClick();
    if (to) return history.push(to);
  }

  const CustomButton = forwardRef((props, ref) => {
    return (
      <Container
        ref={ref}
        type={type}
        colors={colors}
        color={color}
        pulse={pulse}
        text={text}
        onClick={handleClick}
        icon={icon}
        loading={loading}
        {...rest}
      >
        <span>{children}</span>
        <div className="loading">{loading && <FaCircleNotch size={16} />}</div>
      </Container>
    );
  });

  return tip ? (
    <Tippy
      animation="shift-toward"
      arrow={roundArrow}
      content={tip}
      className="items__item-tippy"
      delay={300}
      inertia
      theme="default"
    >
      <CustomButton />
    </Tippy>
  ) : (
    <CustomButton />
  );
}

Button.propTypes = {
  color: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string,
  type: PropTypes.string,
  pulse: PropTypes.bool,
  loading: PropTypes.bool,
  tip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Button.defaultProps = {
  color: '#FFF',
  text: '#222',
  type: 'button',
  pulse: false,
  loading: null,
  tip: null,
};

export default memo(Button);
