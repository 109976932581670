import React, { useEffect, useState } from 'react';

import { format, formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FaCheckCircle } from 'react-icons/fa';

import { Container } from './styles';

import Empty from '../../../components/Empty';
import ProductItem from '../../../components/account/ProductItem';

import api from '../../../services/api';
import Helmet from '../../../components/Helmet';

export default function Orders() {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  const [page, setPage] = useState(1);

  async function getOrders() {
    try {
      const response = await api.get('/orders', {
        params: {
          page,
        },
      });

      const formattedOrders = response.data.map(order => ({
        ...order,
        formattedCreated: formatDistance(
          new Date(order.created_at),
          new Date(),
          {
            locale: ptBR,
          }
        ),
      }));

      setOrders(formattedOrders);

      setLoading(false);
    } catch (err) {}
  }

  async function handleLoadMore() {
    setPage(prev => prev + 1);
  }

  useEffect(() => {
    getOrders();
  }, [page]); // eslint-disable-line

  return (
    <>
      <Helmet title="Pedidos" description="Seus pedidos" />
      {!loading && (
        <Container>
          {!!orders.length ? (
            orders.map(order => (
              <ProductItem
                key={order.id}
                code={order.key}
                className="product"
                {...order}
              />
            ))
          ) : (
            <Empty />
          )}
        </Container>
      )}
    </>
  );
}
