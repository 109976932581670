export function createOrderRequest(productId, type, price) {
  return {
    type: '@shop/CREATE_ORDER_REQUEST',
    payload: { productId, type, price },
  };
}

export function createOrderSuccess(data) {
  return {
    type: '@shop/CREATE_ORDER_SUCCESS',
    payload: { data },
  };
}
