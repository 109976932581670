import React, { useRef } from 'react';

import { Wrapper, Container, Content } from './styles';

import PerfectScrollbar from 'react-perfect-scrollbar';

function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <PerfectScrollbar>
        <Container>
          <Content>{children}</Content>
        </Container>
      </PerfectScrollbar>
    </Wrapper>
  );
}

export default DefaultLayout;
