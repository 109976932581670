import React, { useState, useEffect, useRef } from 'react';

import ScrollBar from 'react-perfect-scrollbar';
import { Container, UserList, User, Winner, StartContainer } from './styles';
import { FaGoogle, FaRandom } from 'react-icons/fa';
import { shuffle } from 'lodash';

import Avatar from '../../../../components/Avatar';
import Button from '../../../../components/Button';

import theme from '../../../../styles/theme';
import api from '../../../../services/api';

import Logo from '../../../../assets/logo.png';

import io from 'socket.io-client';

export default function Gifter() {
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [winner, setWinner] = useState(null);
  const [error, setError] = useState(null);

  const winnerRef = useRef(null);

  async function handleStart() {
    try {
      await api.post(`/admin/chatbot/gifter/status/start`);

      setUsers([]);

      setActive(true);
    } catch (err) {}
  }

  async function handleStop() {
    try {
      await api.post(`/admin/chatbot/gifter/status/stop`);

      setActive(false);
    } catch (err) {}
  }

  async function handleDraw() {
    setWinner(null);
    setError(null);
    const user = users[Number(winnerRef.current.value) - 1];

    try {
      const response = await api.get(`/admin/chatbot/gifter/winner/${user.id}`);

      setWinner({
        ...response.data,
        subscriber: user.subscriber,
      });
    } catch (err) {
      setError('Esse usuário não existe no sistema.');
    }
  }

  function handleShuffle() {
    const shuffled = shuffle(users);

    setUsers(shuffled);
  }

  useEffect(() => {
    async function getGiveaway() {
      const response = await api.get('/admin/chatbot/gifter/users');

      setActive(response.data.active);
      setUsers(response.data.users);
      setLoading(false);
    }

    getGiveaway();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BOT_URL);

    socket.on('@giveaway/JOIN', user => {
      setUsers(prev => [...prev, user]);
    });
  }, []);

  return !loading ? (
    <>
      {active ? (
        <Container>
          <UserList>
            {users.length ? (
              <ScrollBar className="users">
                {users.map((user, i) => (
                  <User key={user.id} color={user.color}>
                    <div className="user">
                      {user.subscriber && <img src={Logo} width={16} />}
                      <strong>{user.username}</strong>
                    </div>
                    <strong className="number">{i + 1}</strong>
                  </User>
                ))}
              </ScrollBar>
            ) : (
              <StartContainer>
                <h3>Nenhum usuário está participando...</h3>
              </StartContainer>
            )}
          </UserList>
          <div className="details">
            {active ? (
              <>
                <header>
                  <strong>{users.length} participantes</strong>
                  <div className="actions">
                    <Button
                      color={theme.colors.translucentDark}
                      text={theme.colors.giveaway}
                      onClick={handleShuffle}
                      tip="Embaralhar participantes"
                    >
                      <FaRandom />
                    </Button>
                    <Button
                      color={theme.colors.translucentDark}
                      text={theme.colors.primary}
                      onClick={handleStop}
                      tip="Cancelar rodada"
                    >
                      Cancelar
                    </Button>
                  </div>
                </header>
                <div className="content">
                  <div className="draw">
                    <div className="top">
                      <span>Vencedor:</span>
                      <input ref={winnerRef} type="number" />
                      <Button
                        color={theme.colors.verified}
                        text={theme.colors.text}
                        url={`https://www.google.com/search?q=random+number+1+${users.length}`}
                        tip="Escolher um participante aleatório"
                      >
                        <FaGoogle />
                      </Button>
                    </div>
                    <div className="bottom">
                      <Button
                        color={
                          winner
                            ? theme.colors.translucentDark
                            : theme.colors.success
                        }
                        text={winner ? theme.colors.success : theme.colors.text}
                        onClick={handleDraw}
                        pulse={!winner}
                        tip="Mostrar vencedor"
                      >
                        Sortear
                      </Button>
                    </div>
                  </div>
                  {error ? (
                    <p className="color-error">{error}</p>
                  ) : (
                    <>
                      {winner && (
                        <>
                          <Winner className="winner" isSub={winner.subscriber}>
                            <h3>Parabéns</h3>
                            <Avatar
                              size={108}
                              url={winner.avatar}
                              admin={winner.is_admin}
                              verified={winner.is_verified}
                            />
                            <h1>{winner.username}</h1>
                          </Winner>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="details-container">
                <Button>Pausar</Button>
              </div>
            )}
          </div>
        </Container>
      ) : (
        <StartContainer>
          <Button
            onClick={handleStart}
            color={theme.colors.verified}
            text={theme.colors.text}
          >
            Começar um sorteio
          </Button>
        </StartContainer>
      )}
    </>
  ) : (
    <StartContainer>
      <h1>Carregando...</h1>
    </StartContainer>
  );
}
