import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { shuffle } from 'lodash';
import { FaArrowLeft, FaGoogle, FaTicketAlt } from 'react-icons/fa';

import { format } from 'date-fns';
import api from '../../../services/api';

import { Container, Actions, TreasureGrid, Banner } from './styles';

import Button from '../../../components/Button';

import TreasureCard from './TreasureCard';
import TreasureModal from './TreasureModal';

import theme from '../../../styles/theme';

function Treasures({ match }) {
  const [loading, setLoading] = useState(true);
  const [treasures, setTreasures] = useState([]);
  const [shuffled, setShuffled] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);

  const { pathname } = useLocation();
  const { goBack } = useHistory();

  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user);

  const { slug } = match.params;

  const hasBanner = useMemo(() => {
    return slug.startsWith('subs');
  }, []);

  const shuffleTreasures = useCallback(() => {
    const shuffledTreasures = shuffle(treasures);
    setShuffled(shuffledTreasures);
  }, [treasures]);

  const treasureList = useMemo(() => {
    return shuffled || treasures;
  }, [treasures, shuffled, shuffleTreasures]);

  const isJoinable = useMemo(() => {
    return pathname.startsWith('/tesouros');
  }, [pathname]);

  const isAdmin = useMemo(() => {
    if (!auth.signed) return;
    return user.profile && user.profile.is_admin;
  }, [user]);

  const openModal = useCallback(
    id => {
      if (!isAdmin) return;

      const treasure = treasures.find(t => t.id === id);
      setModalData(treasure);
      setModalVisible(true);
    },
    [treasures, shuffled, shuffleTreasures, isAdmin]
  );

  const handleJoin = useCallback(
    ({ id, entry }) => {
      const newTreasures = treasureList.map(treasure =>
        treasure.id === id
          ? {
              ...treasure,
              entry,
            }
          : treasure
      );

      setTreasures(newTreasures);
    },
    [treasureList]
  );

  useEffect(() => {
    if (!slug) return;

    async function getTreasures() {
      try {
        const response = await api.get(`/treasures/${slug}`, {
          params: {
            active: isJoinable,
          },
          ...(auth.token && {
            headers: {
              Authorization:
                api.defaults.headers.Authorization || `Bearer ${auth.token}`,
            },
          }),
        });
        const formattedTreasures = response.data.map(treasure => ({
          ...treasure,
          ended_at: treasure.ended_at
            ? format(new Date(treasure.ended_at), 'dd/MM/yy')
            : null,
        }));
        setTreasures(formattedTreasures);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    getTreasures();
  }, [auth.signed]);

  return (
    <>
      <TreasureModal
        treasure={modalData}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        isJoinable={isJoinable}
      />
      <Container>
        {loading ? (
          <h2>Carregando tesouros...</h2>
        ) : (
          <>
            {treasureList.length ? (
              <>
                <Actions>
                  <Button
                    color={theme.colors.shapes}
                    text={theme.colors.text}
                    icon
                    onClick={goBack}
                  >
                    <FaArrowLeft />
                    <span>Voltar</span>
                  </Button>
                  {isAdmin && (
                    <div className="admin">
                      <Button
                        color={theme.colors.verified}
                        text={theme.colors.text}
                        url={`https://www.google.com/search?q=random+number+1+${treasureList.length}`}
                      >
                        <FaGoogle />
                      </Button>
                      <Button
                        color={theme.colors.translucentDark}
                        text={theme.colors.slabs}
                        onClick={shuffleTreasures}
                      >
                        Embaralhar
                      </Button>
                    </div>
                  )}
                </Actions>
                {hasBanner && (
                  <Banner>
                    <p>
                      Subs participam <strong>automaticamente</strong> dos
                      sorteios de tesouros!
                    </p>
                    <ul>
                      <li>
                        Grupo 1 / <span className="color-prime">Prime</span>:{' '}
                        <FaTicketAlt size={12} color={theme.colors.slabs} />{' '}
                        <strong>x1</strong>
                      </li>
                      <li>
                        Grupo 2:{' '}
                        <FaTicketAlt size={12} color={theme.colors.slabs} />{' '}
                        <strong>x5</strong>
                      </li>
                      <li>
                        Grupo 3:{' '}
                        <FaTicketAlt size={12} color={theme.colors.slabs} />{' '}
                        <strong>x20</strong>
                      </li>
                    </ul>
                    <p>
                      {`(`}A cada usuário presenteado por você:{' '}
                      <FaTicketAlt size={12} color={theme.colors.slabs} />{' '}
                      <strong>x1 EXTRA</strong>
                      {`)`}
                    </p>
                  </Banner>
                )}
                <TreasureGrid>
                  {treasureList.map((treasure, index) => (
                    <TreasureCard
                      key={treasure.id}
                      open={treasure.winner}
                      number={shuffled && index + 1}
                      {...treasure}
                      onTreasureClick={openModal}
                      onJoin={handleJoin}
                      canOpen={isAdmin}
                    />
                  ))}
                </TreasureGrid>
              </>
            ) : (
              <p>Nenhum tesouro disponível no momento.</p>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default Treasures;
