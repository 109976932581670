import { createGlobalStyle } from '@xstyled/styled-components';
import { easeOutCirc } from 'eazy-ease';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/themes/translucent.css';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import 'react-sweet-progress/lib/style.css';

import 'react-datepicker/dist/react-datepicker.css';

import 'react-perfect-scrollbar/dist/css/styles.css';

import '@rmwc/icon/icon.css';
import 'react-tabs/style/react-tabs.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@rmwc/circular-progress/circular-progress.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';
import '@material/list/dist/mdc.list.css';
import '@rmwc/list/collapsible-list.css';

import 'react-step-progress-bar/styles.css';

import 'csshake/dist/csshake.min.css';

import theme from './theme';

import { lighten } from 'polished';

export default createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,500,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    ::selection {
      background-color: primary;
    }
    ::-moz-selection {
      background-color: primary;
    }
  }

  html {
    font-size: 62.5%;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: shapes;
    font-size: 1.4rem;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s ${easeOutCirc};

    :hover {
      color: primary;
    }
  }

  textarea, input {
    border: 0;
  }

  input {
    color: #fff;
    border-radius: default;
    background-color: shapesDark;
    padding: default;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .tippy {
  }

  .tippy-content {
    z-index: 10 !important;
    padding: 1.4rem 1.2rem !important;
  }

  .tippy-content .items__item-tippy {
    line-height: 2.4rem !important;
  }

  .tippy-content .items__item-tippy h2 {
    margin-bottom: 1rem;
  }

  .tippy-tooltip.default-theme {
    background-color: shapes;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .25);

    .tippy-svg-arrow {
      fill: shapes;
    }
  }

  .color-primary {
    color: primary;
  }

  .color-gold {
    color: gold;
  }

  .color-ticket {
    color: ticket;
  }

  .color-gift {
    color: gift;
  }

  .color-twitch {
    color: twitch;
  }

  .color-slabs {
    color: slabs;
  }

  .color-verified {
    color: verified;
  }

  .color-success {
    color: success;
  }

  .color-error {
    color: error;
  }

  .color-prime {
    color: prime;
  }

  .color-discord {
    color: discord;
  }
  .color-telegram {
    color: telegram;
  }

  .stroke {
    text-decoration: line-through;
  }

  .ps__rail-y:hover {
    background-color: shapes !important;
  }

  .ps__thumb-y {
    background-color: shapesDark !important;
  }

  .mdc-snackbar__surface {


    background-color: shapesDark !important;


    .rmwc-icon {
      margin: 0 .5rem 0 2rem !important;
    }

    .mdc-snackbar__label {
      font-size: 1.4rem;

      b {
        display: block;
      }
    }

    .mdc-snackbar__action {
      font-size: 1.4rem;
      color: text !important;
    }
  }

  .apexcharts-canvas {
    background: transparent !important;
  }

  .switch-wrapper {
    position: relative;
    height: 100%;
    overflow: auto;
  }

  .switch-wrapper > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }

`;
