import styled from '@xstyled/styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-top: 2rem;
  padding: 2rem 0;
  flex: 1;
`;
