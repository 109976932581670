import React from 'react';

import {
  FaTwitch,
  FaMoneyBill,
  FaTruckMoving,
  FaTrophy,
  FaKey,
} from 'react-icons/fa';

import { Container } from './styles';

import theme from '../../../../../styles/theme';

export default function EventItem({ type, message, formattedCreatedAt }) {
  function renderIcon() {
    switch (type) {
      case 'subscription':
        return <FaTwitch size={14} color={theme.colors.twitch} />;
      case 'donation':
        return <FaMoneyBill size={14} color={theme.colors.success} />;
      case 'bits':
        return <FaTwitch size={14} color={theme.colors.twitch} />;
      case 'order':
        return <FaTruckMoving size={14} color={theme.colors.verified} />;
      case 'redeem':
        return <FaKey size={14} color={theme.colors.primary} />;
      case 'giveaway':
        return <FaTrophy size={14} color={theme.colors.gold} />;
      default:
        return <FaKey size={14} />;
    }
  }

  return (
    <Container>
      <div className="icon-container">
        <div className="icon">{renderIcon()}</div>
      </div>
      <div className="message">
        <p>{message}</p>
        <strong>{formattedCreatedAt}</strong>
      </div>
    </Container>
  );
}
