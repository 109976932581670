import styled, { css } from '@xstyled/styled-components';
import { Link } from 'react-router-dom';
import { easeOutCirc } from 'eazy-ease';
import { lighten, darken } from 'polished';

import theme from '../../styles/theme';

function imageHeight(level) {
  switch (level) {
    case 0:
      return '155px';
    case 1:
      return '215px';
    case 2:
      return '100%';
  }
}

export const Container = styled.div`
  position: relative;
  transition: all 0.2s ${easeOutCirc};

  width: 100%;

  :hover {
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }

  :hover .actions {
    visibility: visible;
    opacity: 1;
  }

  .actions {
    top: 1rem;
    right: 1rem;
    position: absolute;

    display: flex;

    visibility: hidden;
    opacity: 0;

    transition: all 0.2s ${easeOutCirc};

    .item {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 8px;
      border-radius: default;

      + .item {
        margin-left: 1rem;
      }
    }
  }

  .details {
    color: text !important;
  }
`;

export const Wrapper = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  border-radius: default;
  overflow: hidden;

  box-shadow: 0 4px 10px -2px transparent;

  height: 100%;

  .image {
    height: ${({ level }) => imageHeight(level)};

    img {
      background-color: ${darken(0.035, theme.colors.shapes)};
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .details {
    border-top: 2px solid;
    border-color: ${lighten(0.02, theme.colors.shapes)};
    background-color: shapesDark;
    padding: 1.6rem;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-size: 1.8rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    .bottom {
      margin-top: 2rem;
      font-size: 1.4rem;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;

        svg {
          margin-left: 0.4rem;
        }
      }
    }
  }

  ${({ example }) =>
    example &&
    css`
      margin: 0 auto;
      width: 304px;
    `}

  ${({ level }) =>
    level === 2 &&
    css`
      display: flex;
      flex-direction: row;

      height: 340px;

      .image {
        flex: 4;
      }

      .details {
        padding: 3rem;
        flex: 2;
        display: flex;
        flex-direction: column;
        border: 0;

        .top {
          height: 100%;
          overflow-y: auto;
          .title {
            text-align: center;
            font-size: 2.6rem;
          }

          .description {
            flex: 1;
            margin-top: 2rem;
            height: 100%;

            line-height: 3rem;
          }
        }
      }
    `}
`;
