import styled from 'styled-components';

export const Container = styled.label`
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;

  display: flex;
  align-items: center;

  > * + * {
    margin-left: 0.6rem;
  }
`;
