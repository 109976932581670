export function verificationRequest() {
  return {
    type: '@verification/VERIFICATION_REQUEST',
  };
}

export function verificationSuccess(data) {
  return {
    type: '@verification/VERIFICATION_SUCCESS',
    payload: { data },
  };
}

export function verificationFailure() {
  return {
    type: '@verification/VERIFICATION_FAILURE',
  };
}
