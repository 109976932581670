import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { FaTicketAlt, FaPen } from 'react-icons/fa';
import { MdPerson } from 'react-icons/md';

import { Container, Wrapper } from './styles';

import { formatDistanceToNow, isAfter } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import Label from '../Label';

import theme from '../../styles/theme';

import ReactMarkdown from 'react-markdown';

import PerfectScrollbar from 'react-perfect-scrollbar';

function GiveawayCard({
  id,
  title,
  description,
  image,
  tickets,
  level,
  limit,
  ends_at,
  auto_lock,
  example,
}) {
  const { profile } = useSelector(state => state.user);

  const dateExpired = isAfter(new Date(ends_at), new Date());

  const formattedEndsAt = dateExpired
    ? formatDistanceToNow(new Date(ends_at), {
        locale: ptBR,
      })
    : 'Corre que ainda dá!';

  function FormattedLabel() {
    if (ends_at) {
      return (
        <Label
          color={theme.colors.gift}
          tip={
            dateExpired ? (
              <span>
                Este sorteio será realizado em{' '}
                <strong>{formattedEndsAt}</strong> caso alcance o mínimo de
                tickets necessário.
              </span>
            ) : (
              <span>Este sorteio está prestes a ser realizado!</span>
            )
          }
          pulse
        >
          {dateExpired ? `Finaliza em ${formattedEndsAt}` : formattedEndsAt}
        </Label>
      );
    }

    if (auto_lock) {
      return (
        <Label
          color={theme.colors.verified}
          tip={
            <span>
              Este sorteio será realizado ao atingir <strong>{limit}</strong>{' '}
              <strong className="color-ticket">tickets</strong> participantes
            </span>
          }
          pulse
        >
          {`Fecha em ${tickets.length}/${limit}`}
        </Label>
      );
    }

    return (
      <Label
        color={theme.colors.giveaway}
        tip={
          <span>
            Este sorteio precisa atingir, no mínimo, <strong>{limit}</strong>{' '}
            <strong className="color-ticket">tickets</strong> participantes
          </span>
        }
        pulse
      >
        {`Minimo de ${limit}`}
      </Label>
    );

    return;
  }

  return (
    <Container>
      <Wrapper
        to={example ? `/sorteios` : `/sorteios/${id}`}
        className="item"
        level={level}
        example={example ? 1 : 0}
      >
        <div className="image">
          <img src={image} alt={title} />
        </div>
        <div className="details">
          <div className="top">
            <PerfectScrollbar>
              <span className="title">{title}</span>
              {level === 2 && (
                <ReactMarkdown className="description" source={description} />
              )}
            </PerfectScrollbar>
          </div>
          <div className="bottom">
            <div className="left">
              <strong>{tickets.length}</strong>
              {ends_at && <span>/{limit}</span>}
              <MdPerson />
            </div>

            <FormattedLabel />
          </div>
        </div>
      </Wrapper>
      {!example && profile && profile.is_admin && (
        <div className="actions">
          <Link className="item" to={`/admin/sorteios/${id}/sortear`}>
            <FaTicketAlt size={12} />
          </Link>
          <Link className="item" to={`/admin/sorteios/${id}`}>
            <FaPen size={12} />
          </Link>
        </div>
      )}
    </Container>
  );
}

export default memo(GiveawayCard);
