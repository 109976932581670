export default {
  colors: {
    primary: '#ee5253',
    shapes: '#1B2836',
    shapesDark: '#141D26',
    text: '#FFF',

    success: '#1dd1a1',
    error: '#eb4d4b',
    warn: '#ff9f43',

    twitch: '#a970ff',
    prime: '#03A8E0',
    steam: '#54a5d4',
    steamDark: '#161920',
    slabs: '#32C3A2',
    discord: '#7289da',
    telegram: '#0088cc',
    twitter: '#1da1f2',
    instagram: '#833ab4',
    instagram2: '#f56040',

    gold: '#f8b400',
    ticket: '#52de97',
    gift: '#f67280',
    verified: '#54a0ff',
    giveaway: '#6c5ce7',
    code: '#6ab04c',

    translucentDark: `rgba(0,0,0,.2)`,
  },
  radii: {
    default: '4px',
    top: '4px 4px 0 0',
    bottom: '0 0 4px 4px',
  },
  space: {
    default: '.9rem 1.6rem',
    even: '1.6rem',
    even2x: '3.2rem',
    huge: '4.8rem',
    horizontal: '0 1rem',
    horizontalHuge: '1.6rem 4.8rem',
    vertical: '1rem 0',
    verticalHuge: '4.8rem 1.6rem',
    top: '1.6rem 0 0',
    bottom: '0 0 1.6rem',
    inputWithIcon: '0 1.6rem 0 4rem ',
    contentModal: '2.4rem 1.6rem',

    page: '3rem 2rem',
    pageHorizontal: '0 2rem',
    pageVertical: '3rem 0',
  },
};
