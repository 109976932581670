import React from 'react';

import Highlight from '../components/Highlight';

import Button from '../../../components/Button';
import theme from '../../../styles/theme';
import Helmet from '../../../components/Helmet';

export default function Subscribers() {
  return (
    <>
      <Helmet title="Tesouros" />

      <div className="post">
        <h3>
          Os tesouros são a mais nova adição aos nossos sistemas de sorteios da
          live!
        </h3>

        <h4>Sorteios</h4>

        <p>
          Basicamente são vários tipos de sorteios e cada um deles com suas
          próprias regras para participantes:
        </p>

        <ul>
          <li>
            <h4>TESOUROS PARA SUBSCRIBERS</h4>
            <p>
              Como o próprio nome já sugere, estes tesouros são exclusivos para
              subscribers da live, e para participar desses sorteios basta ser
              um sub ativo no momento do sorteio que você já está participando
              automaticamente.
            </p>
          </li>
          <li>
            <h4>TESOUROS ABERTOS</h4>
            <p>
              Estes tesouros são abertos a todos os participantes da live, e
              para participar desses sorteios basta fazer o login no site e
              clicar em PARTICIPAR.
            </p>
            <p>
              Os ganhadores de qualquer sorteio de tesouros serão contatados no
              email cadastrado na Twitch ou via whisper na própria Twitch.
            </p>
          </li>
        </ul>
        <h4>TIPOS DE TESOUROS</h4>
        <p>
          Os tesouros são divididos em 4 raridades: <strong>Comum</strong>,{' '}
          <strong className="color-gold">Raro</strong>,{' '}
          <strong className="color-twitch">Épico</strong> e{' '}
          <strong className="color-slabs">Lendário</strong>.
        </p>
      </div>
    </>
  );
}
