import styled, { css } from '@xstyled/styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  img {
    cursor: pointer;
    border-radius: 50%;

    ${({ size }) => css`
      border: ${size / 18}px solid;
      border-color: shapes;
      height: ${size}px;
      width: ${size}px;
    `}

    ${({ verified }) =>
      verified &&
      css`
        border-color: verified;
      `}

    ${({ admin }) =>
      admin &&
      css`
        border-color: primary;
      `}
  }

  .icon {
    position: absolute;
    right: 0px;
    top: 0px;

    height: 1.4rem;
    width: 1.4rem;


    ${({ size }) => css`
      height: ${size / 3}px;
      width: ${size / 3}px;
    `}

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    ${({ verified }) =>
      verified &&
      css`
        background-color: verified;
      `}

    ${({ admin }) =>
      admin &&
      css`
        background-color: primary;
      `}
  }
`;
