import React from 'react';

// import { Container } from './styles';

export default function ImageRenderer({ alt, src }) {
  return (
    <div style={{ textAlign: 'center', margin: '4rem 0' }}>
      <img src={`${process.env.REACT_APP_CMS_URL}/${src}`} alt={alt} />
    </div>
  );
}
