import styled from '@xstyled/styled-components';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  .details {
    background-color: shapesDark;

    padding: 2rem;
    border-radius: top;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;

      .info {
        margin-left: 2rem;
        .message {
          font-size: 1.6rem;
        }
        h3 {
          margin-top: 0.6rem;
        }

        > * + * {
          margin-top: 0.6rem;
        }
      }
    }

    .actions {
    }
  }

  .products {
    border: 2px solid;
    border-color: shapesDark;
    border-radius: bottom;
    padding: 2rem;

    > * + * {
      margin-top: 2rem;
    }
  }
`;

export const Product = styled.p`
  display: flex;
  align-items: center;

  a:hover {
    color: inherit;
    text-decoration: underline;
  }

  svg {
    fill: ${({ needsKey }) =>
      needsKey ? theme.colors.success : theme.colors.warn};
    margin-right: 1.4rem;
  }

  .status {
    color: success;
  }
`;
