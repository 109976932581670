import React from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import { Container } from './styles';

registerPlugin(FilePondPluginImagePreview);

function Uploader() {
  return (
    <Container>
      <FilePond server="/upload" />
    </Container>
  );
}

export default Uploader;
