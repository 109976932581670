import React from 'react';

import SkeletonTheme from '../../SkeletonTheme';
import Skeleton from 'react-loading-skeleton';

export default function Avatar() {
  return (
    <SkeletonTheme>
      <Skeleton circle={true} height={42} width={42} />
    </SkeletonTheme>
  );
}
