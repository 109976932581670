import styled from '@xstyled/styled-components';

export const Container = styled.div`
  font-size: 1.6rem;
  padding: 2rem 2rem 2rem 3rem;
  border: 2px solid;
  border-color: shapesDark;
  border-radius: default;
  position: relative;

  .message {
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .icon-container {
    position: absolute;
    left: -1.6rem;
    top: 1.4rem;
    background-color: shapesDark;
    border: 2px solid;
    border-color: shapesDark;
    border-radius: 50%;
    padding: 0.6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  p {
    font-weight: bold;
  }
`;
