import produce from 'immer';
import _ from 'lodash';

const INITIAL_STATE = {
  profile: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS':
        draft.profile = action.payload.data.profile;
        draft.elements = {
          points: action.payload.data.elements.points,
        };
        draft.twitch = action.payload.data.twitch;
        break;
      case '@auth/SIGN_OUT_REQUEST':
        draft.profile = null;
        draft.elements = null;
        break;
      case '@giveaway/JOIN_GIVEAWAY_SUCCESS':
        draft.profile.tickets = _.xorBy(
          state.profile.tickets,
          action.payload.tickets,
          'id'
        );
        break;
      case '@verification/VERIFICATION_SUCCESS':
        draft.profile = action.payload.data.profile;
        draft.elements = {
          points: action.payload.data.elements.points,
        };
        break;
      case '@user/CONSUME_GOLD':
        draft.profile.gold -= action.payload.amount;
        break;
      case '@user/CONSUME_GIFT':
        draft.profile.gifts.pop();
        break;
      case '@user/EXCHANGE_SUCCESS':
        draft.profile.gold = state.profile.gold + Number(action.payload.amount);
        draft.elements.points = state.elements.points - action.payload.cost;
        break;
      case '@user/REDEEM_GOLD_CODE_SUCCESS':
        draft.profile.gold = state.profile.gold + Number(action.payload.amount);
        break;
      case '@user/REDEEM_TICKET_CODE_SUCCESS':
        draft.profile.tickets = [
          ...action.payload.items,
          ...state.profile.tickets,
        ];
        break;
      case '@user/REDEEM_GIFT_CODE_SUCCESS':
        draft.profile.gifts = [...action.payload.items, ...state.profile.gifts];
        break;

      default:
    }
  });
}
