import React from 'react';

import { Link } from 'react-router-dom';
import { addDays } from 'date-fns';

import Quote from '../components/Quote';
import Highlight from '../components/Highlight';
import Reminder from '../components/Reminder';

import Button from '../../../components/Button';
import GiveawayItem from '../../../components/account/GiveawayItem';

import theme from '../../../styles/theme';

import Helmet from '../../../components/Helmet';

export default function Shop() {
  return (
    <>
      <Helmet
        title="Loja"
        description="Acesse para saber como funciona as trocas e prazos da loja"
      />

      <div className="post">
        <h1>Lojonia</h1>

        <Quote>
          Você junta <strong>pontos</strong> assistindo a live, troca por{' '}
          <strong className="color-gold">gold</strong> e compra produtos na
          lojonia
        </Quote>

        <h3>
          Trocando seus <strong className="color-gold">golds</strong> por
          produtos na lojonia
        </h3>

        <p>
          Ao assistir a live, você ganha <strong>pontos</strong> que podem ser
          trocados por <strong className="color-gold">gold</strong> no site.
        </p>

        <Highlight>
          <Button
            to="/como-funciona/gold"
            color={theme.colors.translucentDark}
            text={theme.colors.gold}
          >
            Clique aqui para saber mais sobre pontos e gold
          </Button>
        </Highlight>

        <p>
          Em posse dos seus <strong className="color-gold">gold</strong> você
          pode escolher qualquer produto da lojonia e comprar. São jogos de
          todas as plataformas, produtos físicos, entrada para eventos, etc.
          Sempre que tivermos novidades, as mesmas aparecerão na lojonia em
          destaque.
        </p>

        <p>
          Outra maneira de você adquirir produtos na lojonia é possuindo{' '}
          <Link className="color-gift" to="/como-funciona/gifts">
            <strong className="color-gift">gifts</strong>
          </Link>
          . Com eles você pode escolher qualquer produto que possa ser utilizado
          com um <strong className="color-gift">gifts</strong>.
        </p>

        <Highlight>
          <Button
            to="/como-funciona/gifts"
            color={theme.colors.translucentDark}
            text={theme.colors.gift}
          >
            Clique aqui para saber mais sobre os gifts
          </Button>
        </Highlight>

        <p>
          Você pode conseguir <strong className="color-gift">gifts</strong> em
          sorteios ou sendo presenteado.
        </p>

        <h3>Recebendo os produtos adquiridos na lojonia</h3>

        <p>
          Ao fazer uma compra na lojonia, utilizando{' '}
          <strong className="color-gold">gold</strong> ou um{' '}
          <strong className="color-gift">gift</strong>, caso você seja um{' '}
          <Link className="color-verified" to="/como-funciona/conta">
            <strong className="color-verified">usuário verificado</strong>
          </Link>{' '}
          pelo sistema, você recebe <strong>imediatamente</strong> produtos
          digitais em sua conta e produtos físicos são enviados em até 5 dias
          úteis após o recebimentos dos dados de envio.
        </p>

        <Highlight full>
          <GiveawayItem
            title="Sorteio dos fortes"
            background="https://steamcdn-a.akamaihd.net/steam/apps/252950/page_bg_generated_v6b.jpg?t=1578389102"
            status="done"
            expiresAt={new Date(addDays(new Date(), 30))}
            formattedCreated="Há 2 minutos"
            products={[
              {
                id: 1,
                background:
                  'https://steamcdn-a.akamaihd.net/steam/apps/252950/page_bg_generated_v6b.jpg?t=1578389102',
                title: 'Rocket League®',
                platform: {
                  name: 'Steam',
                },
              },
            ]}
            keys={[
              {
                value: 'SERA-QUE-TEM-COMO-RESGATAR-ESSE-CODE',
                product: {
                  id: 1,
                },
              },
            ]}
            example
          />
        </Highlight>

        <p>
          Caso você não seja um{' '}
          <strong className="color-verified">usuário verificado</strong>, após
          fazer a compra, ela ficará como <strong>pendente</strong> em sua conta
          e será enviada conforme os prazos descritos na{' '}
          <Link to="/como-funciona/conta">
            <strong>página de prazos e recebimentos</strong>
          </Link>
          .
        </p>

        <Reminder />
      </div>
    </>
  );
}
