import React, { useMemo } from 'react';

import { Container, LogoContainer } from './styles';

import Logo from '../../../assets/logo.png';

import { FaBacon, FaCoins } from 'react-icons/fa';

export default function Badge({ tier, active }) {
  const price = useMemo(() => {
    switch (tier) {
      case 1:
        return 10;
      case 2:
        return 5;
      case 3:
        return 3;

      default:
        return 20;
    }
  }, []);

  const formattedTier = tier ? `Sub Tier ${tier}` : 'Forte';

  return (
    <Container active={active}>
      <LogoContainer active={active}>
        <img src={Logo} />
      </LogoContainer>
      <strong>{formattedTier}</strong>
      <small>
        <span>
          <FaBacon /> {price}
        </span>
        =
        <span className="color-gold">
          <FaCoins /> 1
        </span>
      </small>
    </Container>
  );
}
