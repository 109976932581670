import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import info from './info/reducer';
import giveaway from './giveaway/reducer';
import verification from './verification/reducer';
import shop from './shop/reducer';
import settings from './settings/reducer';

export default combineReducers({
  auth,
  user,
  info,
  giveaway,
  verification,
  shop,
  settings,
});
