import styled, { css } from '@xstyled/styled-components';
import { transparentize } from 'polished'

import { Tab as MaterialTab } from '@rmwc/tabs';

import theme from '../../styles/theme'
import { loadingEffect } from '../../styles/keyframes'

export const Container = styled.div`
  padding: page;
  max-width: 980px;
  margin: 0 auto;
  height:100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  a:hover {
    color: inherit;
  }

`;

export const Grid = styled.main`
  width: 100%;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  align-items: flex-start;
  height:100%;

  .start {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
      margin-top: 1rem;
      animation: ${loadingEffect} 2s infinite linear;
    }

    > * + * {
      margin-top: 1.6rem;
    }

  }

  > .info {
    margin-top: 10rem;

    .card {
      margin-top: -6rem;
      background-color: shapesDark;
      border-radius: default;
      padding: 2rem;

      img {
        width: 100%;
        border-radius: default;
        margin-top: -6rem;
        object-fit: cover;
        background-color: dark;
      }

      .content {
        padding-top: 2rem;

        p {
          margin-top: 1rem;
          line-height: 2.8rem;
        }
      }
    }
  }

  .tab {
    background-color: shapesDark;
    border-radius: default;

    .content {
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      > .info {
        display: flex;
        flex-direction: column;
        line-height: 2.6rem;

        > * + * {
          margin-top: 1rem;
        }
      }
    }
  }

  ${({ isFull }) => isFull && css`
    .tab {
      height: 100%;
      position: relative;

      .content {
        width: 100%;
        height: calc(100% - 48px);
        position: absolute;
      }
    }
  `}
`

export const Tab = styled(MaterialTab)`
  --mdc-theme-primary: ${theme.colors.twitch};

  flex: 1;

  &.mdc-tab {
    padding: 0 1rem;
    :hover .mdc-tab__text-label {
      color: text;
    }
  }
  &.mdc-tab .mdc-tab__text-label {
    font-size: 1.4rem;
    color: text;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  &.mdc-tab--active .mdc-tab__text-label {
    color: twitch;
  }
  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-color: shapes;
    opacity: 1;
  }
  .mdc-tab-indicator--active .mdc-tab-indicator__content--underline {
    border-color: twitch;
  }
  .mdc-tab__ripple.mdc-ripple-upgraded--background-focused::before {
    opacity: 0;
  }
`;


export const Participants = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 1.2rem;

  li {
    + li {
      margin-top: 1rem;
    }

    &:nth-child(even) {
      background-color: shapes;
    }

    &:nth-child(odd) {
      border-color: shapes;
    }
  }
`

export const Participant = styled.li`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 1.2rem;
  border-radius: default;
  border: 3px solid transparent;

  .info {
    display: flex;
    justify-content: flex-start;
    flex-direction: row !important;
    align-items: center;
    color: ${({ color }) => color || '#fff'};

    strong {
      margin-left: 1rem;
    }
  }

  > strong {
    font-size: 1.3rem;
    font-family: 'Roboto Mono';
    letter-spacing: 2px;
    padding: 2px;
    background: rgba(0, 0, 0, 0.08);
    padding: .4rem 1rem;
    border-radius: default;
  }

  ${({ winner }) => winner && css`
    background-color: ${transparentize(.8, theme.colors.success)};
    border-color: ${theme.colors.success} !important;

    > strong {
      background-color: success;
    }
  `}

`

export const Badge = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: default;
  border: 2px solid transparent;

  img {
    width: 100%;
    padding: .5rem;
  }


  ${({ color }) =>
    color &&
    css`
      background: linear-gradient(
        ${transparentize(0.8, color)},
        ${transparentize(0.9, color)}
      );
      border-color: ${color};
    `}

    ${({ glow, color }) => glow && css`
      box-shadow: 0 0 20px -2px ${transparentize(.25, color)};
    `}
`

export const Actions = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: vertical;

  .left {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 1rem;
    }

    input {
      background-color: shapes;
    }

  }
`

export const Winner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 3rem;
  margin: vertical;
`

