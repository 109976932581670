import React, { useEffect, useState } from 'react';

import { Container, Actions } from './styles';

import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Select from '../../../../components/Select';
import Uploader from '../../../../components/Uploader';
import Switch from '../../../../components/Switch';

import api from '../../../../services/api';
import history from '../../../../services/history';
import theme from '../../../../styles/theme';

registerLocale('pt-BR', ptBR);

export default function New() {
  const [imageIsLink, setImageIsLink] = useState(false);
  const [backgroundIsLink, setBackgroundIsLink] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [products, setProducts] = useState(null);
  const [selectedProducts, setSelectProducts] = useState(null);
  const [image, setImage] = useState('');
  const [background, setBackground] = useState('');
  const [price, setPrice] = useState(1);
  const [limit, setLimit] = useState(500);
  const [autoLock, setAutoLock] = useState(true);
  const [endsAt, setEndsAt] = useState(new Date());
  const [active, setActive] = useState(false);

  function handleProductChange(data) {
    if (!data) return;
    if (!!data.length) {
      setImage(data[0].image);
      setBackground(data[0].background);
      setImageIsLink(true);
      setBackgroundIsLink(true);
      setSelectProducts(data);
    }
  }

  async function handleCreate() {
    await api.post('/admin/giveaways', {
      title,
      description,
      image,
      background,
      price,
      limit,
      auto_lock: autoLock,
      ends_at: endsAt,
      active,
      products: selectedProducts.map(p => p.value),
    });

    history.push('/admin/sorteios');
  }

  useEffect(() => {
    async function getConfig() {
      const response = await api.get('/settings/giveaways');

      const formatResponse = response.data.map(product => ({
        value: product.id,
        label: product.title,
        image: product.image,
        background: product.background,
        price: product.price,
      }));

      setProducts(formatResponse);
    }

    getConfig();
  }, []);

  return (
    <Container>
      <Form>
        <div className="row">
          <span>Titulo</span>
          <input
            type="text"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
        <div className="row">
          <span>Descrição</span>
          <textarea
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div className="row">
          <span>Premiações</span>
          <Select
            className="reselect"
            options={products}
            placeholder="Selecione as premiações..."
            onChange={handleProductChange}
            isMulti
          />
        </div>
        <div className="row">
          <span>Imagem</span>
          {imageIsLink ? (
            <input
              type="text"
              value={image}
              onChange={e => setImage(e.target.value)}
            />
          ) : (
            <Uploader />
          )}
        </div>
        <div className="row">
          <span>Background</span>
          {backgroundIsLink ? (
            <input
              type="text"
              value={background}
              onChange={e => setImage(e.target.value)}
            />
          ) : (
            <Uploader />
          )}
        </div>
        <div className="row">
          <div className="col">
            <span>Limite</span>
            <input
              type="number"
              className="smaller"
              min="1"
              value={limit}
              onChange={e => setLimit(e.target.value)}
            />
          </div>
          <div className="col">
            <label>
              <span>Auto Lock</span>
              <Switch
                checked={autoLock}
                onChange={() => setAutoLock(!autoLock)}
              />
            </label>
          </div>
          <div className="col">
            <label>
              <span>Ativo</span>
              <Switch checked={active} onChange={() => setActive(!active)} />
            </label>
          </div>
          <div className="col full">
            <label>
              <span>Encerramento</span>
              <DatePicker
                className="big"
                selected={endsAt}
                onChange={date => setEndsAt(date)}
                locale="pt-BR"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
                timeCaption="Horário"
              />
            </label>
          </div>
        </div>
      </Form>
      <Actions>
        <Button
          color={theme.colors.shapesDark}
          text={theme.colors.text}
          to="/admin/sorteios"
        >
          Voltar
        </Button>
        <Button
          color={theme.colors.ticket}
          text={theme.colors.text}
          onClick={handleCreate}
        >
          Criar sorteio
        </Button>
      </Actions>
    </Container>
  );
}
