import { all, takeLatest, put, call } from 'redux-saga/effects';

import api from '../../../services/api';
import history from '../../../services/history';

import {
  getGiveawaySuccess,
  deleteGiveawaySuccess,
  joinGiveawaySuccess,
} from './actions';

function* getGiveaway({ payload }) {
  try {
    const { giveawayId } = payload;

    const response = yield call(api.get, `/giveaways/${giveawayId}`);

    yield put(getGiveawaySuccess(response.data));
  } catch (err) {
    console.log(err.response);
  }
}

function* deleteGiveaway({ payload }) {
  try {
    const { giveawayId } = payload;

    yield call(api.delete, `/admin/giveaways/${giveawayId}`);

    history.push('/admin/sorteios');
  } catch (err) {
    console.log(err.response);
  }
}

function* joinGiveaway({ payload }) {
  try {
    const { giveawayId, type, ticketCount } = payload;

    const ticketData = ticketCount ? { ticketCount } : null;

    const response = yield call(
      api.post,
      `/giveaways/${giveawayId}/join/${type}`,
      ticketData
    );

    yield put(joinGiveawaySuccess(type, response.data));
  } catch (err) {
    console.log(err.response);
  }
}

export default all([
  takeLatest('persist/REHYDRATE', () => {}),
  takeLatest('@giveaway/GET_GIVEAWAY_REQUEST', getGiveaway),
  takeLatest('@giveaway/DELETE_GIVEAWAY_REQUEST', deleteGiveaway),
  takeLatest('@giveaway/JOIN_GIVEAWAY_REQUEST', joinGiveaway),
]);
