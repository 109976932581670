import React from 'react';

import { Wrapper, Container, Content } from './styles';

export default function PostLayout({ children }) {
  return (
    <Wrapper>
      <Container>
        <Content>{children}</Content>
      </Container>
    </Wrapper>
  );
}
