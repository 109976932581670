import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

import theme from '../../styles/theme';

function Progress({ percent, color, trailColor, height }) {
  return (
    <Container
      percent={percent}
      color={color}
      trailColor={trailColor}
      height={height}
    />
  );
}

Progress.propTypes = {
  percent: PropTypes.number.isRequired,
  color: PropTypes.string,
  trailColor: PropTypes.string,
  height: PropTypes.number,
};

Progress.defaultProps = {
  color: theme.colors.primary,
  trailColor: theme.colors.shapesDark,
  height: 6,
};

export default Progress;
