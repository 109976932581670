import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import { createOrderRequest } from '../../store/modules/shop/actions';
import { signInSuccess } from '../../store/modules/auth/actions';

import { Container, ShopGrid } from './styles';

import {
  FaGift,
  FaCircleNotch,
  FaCoins,
  FaQuestionCircle,
  FaWrench,
} from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';
import { DialogContent, DialogActions } from '@rmwc/dialog';

import ShopItem from './ShopItem';

import Alert from '../../components/Alert';
import Button from '../../components/Button';
import SearchBox from '../../components/SearchBox';
import Select from '../../components/Select';
import Switch from '../../components/Switch';
import Dialog from '../../components/Dialog';

import api from '../../services/api';
import theme from '../../styles/theme';

import useDebounce from '../../hooks/useDebounce';

import { createPopup } from '../../utils';

import socket from '../../services/socket';

import Helmet from '../../components/Helmet';

function Shop() {
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState({
    count: 0,
    products: [],
  });
  const [page, setPage] = useState(0);
  const [firstQuery, setFirstQuery] = useState(true);
  const [title, setTitle] = useState('');
  const [loadingResults, setLoadingResults] = useState(false);
  const [isProductDialogVisible, setIsProductDialogVisible] = useState(false);
  const [order, setOrder] = useState(null);

  const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState(null);
  const [platforms, setPlatforms] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [listOrder, setListOrder] = useState(null);

  const { signed } = useSelector(state => state.auth);
  const { profile } = useSelector(state => state.user);

  const [giftables, setGiftables] = useState(false);

  const debouncedTitle = useDebounce(title, 500);

  const dispatch = useDispatch();

  const Icon = loadingResults ? FaCircleNotch : MdSearch;

  const platformsRef = useRef(null);

  let loginPopup;

  socket.on('login:success', data => {
    if (!loginPopup) return;
    loginPopup.close();

    dispatch(signInSuccess(data));
  });

  function handleLogin() {
    if (!socket.id) return;
    loginPopup = createPopup(
      `${process.env.REACT_APP_API_URL}/connect/twitch?socketId=${socket.id}`,
      'Login',
      480,
      640
    );
  }

  const getProducts = async () => {
    setLoadingResults(true);

    try {
      const response = await api.get('/shop', {
        params: {
          page,
          giftables,
          firstQuery,
          title,
          category,
          platform,
          listOrder: listOrder && listOrder.value,
        },
      });

      if (firstQuery) setFirstQuery(false);

      setProductList(prev => ({
        count: response.data.count,
        products:
          page > 0
            ? [...prev.products, ...response.data.products]
            : response.data.products,
      }));

      setCategories(
        response.data.info.categories.map(category => ({
          label: category.name,
          value: category.id,
        }))
      );

      setPlatforms(
        response.data.info.platforms.map(platform => ({
          label: platform.name,
          value: platform.id,
          category: platform.category.id,
        }))
      );

      setLoadingResults(false);
      setLoading(false);
    } catch (err) {
      console.log(err.response);
    }
  };

  function handleLoadMore(e) {
    setPage(prev => prev + 1);
  }

  async function handleProductDetails(id) {
    const product = productList.products.find(p => p.id === id);

    setOrder(product);

    console.log(product);

    setIsProductDialogVisible(true);
  }

  async function handleOrder(type) {
    dispatch(createOrderRequest(order.id, type, order.price));
  }

  function handleCancelOrder() {
    setIsProductDialogVisible(false);
  }

  useEffect(() => {
    if (title !== '') setPage(0);
  }, [title]);

  useEffect(() => {
    setProductList(prev => ({ ...prev, products: [] }));
    setPage(0);
    if (firstQuery) return;
    getProducts();
  }, [giftables, category, platform, listOrder]); // eslint-disable-line

  useEffect(() => {
    getProducts();
  }, [page, debouncedTitle]); // eslint-disable-line

  const categoryPlaforms = useMemo(() => {
    console.log('category', category);
    console.log(platformsRef.current);
    if (!platformsRef.current) return;
    platformsRef.current.select.setValue(null);
    if (!category) return [];
    console.log(platforms);
    return platforms.filter(platform => platform.category === category.value);
  }, [category]);

  return (
    <>
      <Helmet title="Lojonia" description="Produtos disponíveis na lojonia" />

      <Container>
        {order && (
          <Dialog
            open={isProductDialogVisible}
            onClose={handleCancelOrder}
            className="shop__dialog"
            background={order.background}
          >
            <header>
              <h2>{order.title}</h2>
              <strong>{order.platform.name}</strong>
            </header>
            <DialogContent className="shop__dialog-content">
              <div className="shop__dialog-content-image">
                <img src={order.image} alt={order.title} />
              </div>
              <div className="shop__dialog-content-info">
                <ReactMarkdown source={order.description} />
              </div>
            </DialogContent>
            <DialogActions className="shop__dialog-actions">
              <Alert className="alert" color={theme.colors.gold} transparent>
                Confira a plataforma antes de comprar um produto
              </Alert>
              {order.platform.name === 'Nintendo Switch' && (
                <Alert className="alert" color={theme.colors.error} transparent>
                  Certifique-se que sua conta da Nintendo esteja na região
                  norte-americana
                </Alert>
              )}
              {signed ? (
                <>
                  {order.keys ? (
                    <>
                      <Button
                        color={theme.colors.translucentDark}
                        text={theme.colors.gift}
                        onClick={() => handleOrder('gift')}
                        disabled={!order.is_giftable || !profile.gifts.length}
                      >
                        {order.is_giftable
                          ? 'Usar gift'
                          : 'Indisponível como gift'}
                      </Button>
                      <Button
                        color={theme.colors.translucentDark}
                        text={theme.colors.gold}
                        onClick={() => handleOrder('gold')}
                        disabled={profile.gold < order.price}
                      >
                        <FaCoins />
                        <strong>{order.price}</strong>
                      </Button>
                    </>
                  ) : (
                    <Button
                      color={theme.colors.translucentDark}
                      text={theme.colors.error}
                      disabled
                    >
                      Fora de estoque
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  color={theme.colors.twitch}
                  text={theme.colors.text}
                  onClick={handleLogin}
                  pulse
                >
                  Entrar com Twitch
                </Button>
              )}
            </DialogActions>
          </Dialog>
        )}

        <header>
          {signed ? (
            profile.is_admin && (
              <Link to="/admin/loja">
                <strong>
                  <span>Gerenciar</span>
                  <FaWrench size={10} />
                </strong>
              </Link>
            )
          ) : (
            <div />
          )}
          <a href="/como-funciona/loja" target="_blank">
            <strong className="color-gold">
              <span>Como trocar</span>
              <FaQuestionCircle />
            </strong>
          </a>
        </header>

        <SearchBox
          placeholder={
            loadingResults
              ? 'Carregando resultados...'
              : 'Busque por produtos...'
          }
          icon={Icon}
          value={title}
          onChange={value => setTitle(value)}
          loading={loadingResults}
        >
          <Select
            placeholder="Categoria"
            options={categories}
            onChange={setCategory}
            isSearchable={false}
          />
          <Select
            isDisabled={!category}
            ref={platformsRef}
            placeholder="Plataforma"
            options={categoryPlaforms}
            onChange={setPlatform}
            isSearchable={false}
          />
          <Select
            placeholder="Ordenar por..."
            options={[
              { label: 'Mais novos', value: 'newer' },
              { label: 'Preço: baixo a alto', value: 'price_lower' },
              { label: 'Preço: alto a baixo', value: 'price_higher' },
              { label: 'Ordem alfabética', value: 'alphabetic' },
              { label: 'Ordem alfabética-invertida', value: 'inverted' },
            ]}
            onChange={setListOrder}
            isSearchable={false}
          />
          <Switch
            checked={giftables}
            onChange={() => setGiftables(prev => !prev)}
          >
            <FaGift />
          </Switch>
        </SearchBox>
        {!loading && (
          <>
            <ShopGrid>
              {productList.products.map(item => (
                <ShopItem
                  key={item.id}
                  {...item}
                  onClick={handleProductDetails}
                  isAdmin={profile && profile.is_admin}
                />
              ))}
            </ShopGrid>
            {!!productList.products.length &&
              productList.products.length < productList.count && (
                <Button
                  className="load"
                  color={theme.colors.shapesDark}
                  text={theme.colors.text}
                  onClick={handleLoadMore}
                >
                  Carregar mais
                </Button>
              )}
          </>
        )}
      </Container>
    </>
  );
}

export default Shop;
