import React, { useEffect, useState } from 'react';

import { TabBar } from '@rmwc/tabs';
import { Container, Tab, Content } from './styles';

import Pendency from './Pendency';

import api from '../../../services/api';

export default function Pendencies() {
  const [activeTab, setActiveTab] = useState(0);
  const [label, setLabel] = useState(null);

  const [pendencies, setPendencies] = useState([]);

  function getLabel() {
    switch (activeTab) {
      case 0:
        return 'giveaway';
      case 1:
        return 'order';
    }
  }

  async function handleSend(id) {
    try {
      const response = await api.post(`/admin/pendencies/${label}/${id}/send`);
    } catch (err) {
      console.log(err.response);
    }
  }

  useEffect(() => {
    setLabel(getLabel());
  }, [activeTab]);

  useEffect(() => {
    if (!label) return;
    async function getPendencies() {
      try {
        const response = await api.get(`/admin/pendencies/${label}`);

        setPendencies(response.data);
      } catch (err) {}
    }

    getPendencies();
  }, [label]);

  return (
    <Container>
      <TabBar
        activeTabIndex={activeTab}
        onActivate={e => setActiveTab(e.detail.index)}
      >
        <Tab label="Sorteios" />
        <Tab label="Pedidos" />
      </TabBar>
      <Content>
        {pendencies.map(pendency => (
          <Pendency
            key={pendency.id}
            type={label}
            onSend={handleSend}
            {...pendency}
          />
        ))}
      </Content>
    </Container>
  );
}
