import React from 'react';

import { Container, Label, TypeContainer } from './styles';

import SkeletonTheme from '../../../../../components/SkeletonTheme';
import Skeleton from 'react-loading-skeleton';
import { LoadingItem } from '../../Skeletons';

export default function Item({ label, description, children, loading }) {
  return (
    <Container>
      <Label>
        {loading ? (
          <SkeletonTheme>
            <Skeleton width="80%" height={30} />
          </SkeletonTheme>
        ) : (
          label
        )}
      </Label>
      <TypeContainer>
        {loading ? (
          <LoadingItem />
        ) : (
          <>
            {children}
            <p>{description}</p>
          </>
        )}
      </TypeContainer>
    </Container>
  );
}
