import styled from '@xstyled/styled-components';
import { Link } from 'react-router-dom';

import theme from '../../../styles/theme';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  border-radius: default;
  background: linear-gradient(
      to left,
      transparent,
      ${theme.colors.shapesDark} 90%
    ),
    url(${({ background }) => background});
  background-size: cover;
  background-position: center;

  font-size: 1.2rem;

  .details {
    display: flex;
    img {
      height: 6.4rem;
      margin-right: 1.2rem;
      border-radius: default;
    }

    h2 {
      font-size: 2rem;
    }

    > * {
      display: flex;
      flex-direction: column;
    }

    .row {
      margin-top: 1rem;

      display: flex;

      > * + * {
        margin-left: 1rem;
      }

      > div {
        display: flex;
        align-items: center;

        > * + * {
          margin-left: 0.6rem;
        }
      }
    }
  }

  .actions {
    display: flex;

    > * + * {
      margin-left: 1rem;
    }
  }
`;

export const DetailsContainer = styled(Link)`
  flex: 1;
  :hover {
    color: inherit;
  }
`;
