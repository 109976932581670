import styled from '@xstyled/styled-components';

export const Container = styled.div`
  .users {
    margin-top: 2rem;
    > * {
      padding: default;
      display: flex;
      align-items: center;

      border-radius: default;
      background-color: shapesDark;

      + * {
        margin-top: 1.2rem;
      }
    }
  }

  .load-more {
    width: 100%;
  }

  > * + * {
    margin-top: 2rem;
  }
`;
