import theme from '../../styles/theme';
// import { lighten } from 'polished';

export default {
  container: (provided, state) => ({
    ...provided,
    border: 0,
    flex: 1,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: theme.colors.shapesDark,
    border: 0,
    boxShadow: state.isHovered && `0 0 0 1px ${theme.colors.primary}`,
    ':hover, :active': {
      border: 0,
      boxShadow: `0 0 0 1px ${theme.colors.primary}`,
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: theme.colors.shapes,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    color: theme.colors.text,
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: theme.colors.shapesDark,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: theme.colors.shapesDark,
    color: theme.colors.text,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.colors.shapes,
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: theme.colors.text,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
  }),
};
