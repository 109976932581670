import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Treasure from '../../../../components/Treasure';
import { FaLock } from 'react-icons/fa';
import { FaCircleNotch } from 'react-icons/fa';

import api from '../../../../services/api';

import {
  Container,
  BodyWrapper,
  Body,
  Info,
  Label,
  CardFooter,
  Number,
  Locked,
} from './styles';

import theme from '../../../../styles/theme';

function TreasureCard({
  id,
  type,
  open,
  title,
  image,
  category,
  number,
  is_secret,
  onTreasureClick,
  hasJoined,
  entries,
  onJoin,
  canOpen,
}) {
  const [loadingJoin, setLoadingJoin] = useState(false);
  const [joined, setJoined] = useState(hasJoined);

  const { pathname } = useLocation();

  const auth = useSelector(state => state.auth);

  const label = useMemo(() => {
    switch (type) {
      case 'common':
        return {
          color: theme.colors.text,
          text: 'comum',
        };
      case 'rare':
        return {
          color: theme.colors.gold,
          text: 'raro',
        };
      case 'epic':
        return {
          color: theme.colors.twitch,
          text: 'épico',
        };
      case 'legendary':
        return {
          color: theme.colors.slabs,
          text: 'lendário',
        };
      default:
        return null;
    }
  }, [type]);

  const isJoinable = useMemo(() => {
    return pathname.startsWith('/tesouros');
  }, [pathname]);

  const joinGiveaway = useCallback(async () => {

    try {
      const response = await api.post(`/treasures/${id}/join`);
      onJoin({ id, entry: response.data.entry });
      setJoined(true);
    } catch (err) {
    } finally {
      setLoadingJoin(false);
    }
  }, []);

  const Footer = useCallback(() => {
    switch (category) {
      case 'subs':
        return (
          <CardFooter color={theme.colors.twitch}>
            Exclusivo para subscribers!
          </CardFooter>
        );
      case 'donation':
        return (
          <CardFooter color={theme.colors.slabs}>
            Exclusivo para doadores!
          </CardFooter>
        );

      default:
        if (!auth.signed) {
          return (
            <CardFooter color={theme.colors.translucentDark}>
              Faça login para participar
            </CardFooter>
          );
        }

        if (joined) {
          return (
            <CardFooter color={theme.colors.translucentDark}>
              Você está participando
            </CardFooter>
          );
        }

        return (
          <CardFooter
            color={theme.colors.translucentDark}
            onClick={joinGiveaway}
            loading={loadingJoin ? 1 : 0}
          >
            {loadingJoin ? (
              <FaCircleNotch size={16} />
            ) : (
              <span>Participar</span>
            )}
          </CardFooter>
        );
    }
  }, [category, loadingJoin, auth.signed, joined, hasJoined]);

  const Entry = useCallback(() => {
    if (!entries || !entries.length) return null;
    return (
      <Info>
        <h3>{entries.length > 1 ? 'Seus números' : 'Seu número'}:</h3>
        <div className="entries">
          {entries.map(entry => (
            <div key={entry} className="entry">
              <strong>{entry}</strong>
            </div>
          ))}
        </div>
      </Info>
    );
  }, [entries]);

  useEffect(() => {
    if (hasJoined) setJoined(true);
  }, [hasJoined]);

  return (
    <Container canOpen={canOpen}>
      <Treasure
        type={type}
        open={open}
        innerGlow={open}
        prize={image}
        onClick={() => onTreasureClick(id)}
        canOpen={canOpen}
      />
      <BodyWrapper>
        {number && (
          <Number>
            <strong>{number}</strong>
          </Number>
        )}
        <Body isVisible={!is_secret}>
          {is_secret ? (
            <>
              <Label color={label.color}>
                <strong>{label.text}</strong>
              </Label>
              <Locked>
                <FaLock size={20} />
                <p>Este tesouro é secreto.</p>
              </Locked>
              <Entry />
            </>
          ) : (
            <>
              <Label color={label.color}>
                <strong>{label.text}</strong>
              </Label>
              <img src={image} alt="" />
              <h3>{title}</h3>
              <Entry />
            </>
          )}
        </Body>
        {isJoinable && <Footer />}
      </BodyWrapper>
    </Container>
  );
}

export default TreasureCard;
