import styled from '@xstyled/styled-components';

import { Tab as MaterialTab } from '@rmwc/tabs';

import theme from '../../styles/theme';

export const Container = styled(MaterialTab)`
  --mdc-theme-primary: ${({ color }) => theme.colors[color]};

  flex: 1;

  &.mdc-tab {
    padding: 0 1rem;
    :hover .mdc-tab__text-label {
      color: text;
    }
  }
  &.mdc-tab .mdc-tab__text-label {
    font-size: 1.4rem;
    color: text;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  &.mdc-tab--active .mdc-tab__text-label {
    color: ${({ color }) => theme.colors[color]};
  }
  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-color: shapesDark;
    opacity: 1;
  }
  .mdc-tab-indicator--active .mdc-tab-indicator__content--underline {
    border-color: ${({ color }) => theme.colors[color]};
  }
  .mdc-tab__ripple.mdc-ripple-upgraded--background-focused::before {
    opacity: 0;
  }
`;
