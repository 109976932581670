import styled from '@xstyled/styled-components';

import { transparentize } from 'polished';

import theme from '../../styles/theme';

import Setup from '../../assets/home/setup.png';
import Sorteios from '../../assets/home/sorteios.png';
import Loja from '../../assets/home/loja.png';
import Tesouros from '../../assets/home/tesouros.png';

export const Container = styled.div`
  padding: page;
  max-width: 980px;
  margin: 0 auto;
  height: 100%;
`;

export const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-areas:
    'big big'
    'giveaways shop'
    'social social';
  grid-template-rows: 1fr 1fr;
  align-content: flex-start;

  .card {
    padding: 2rem;
    border: 2px solid;
    border-color: shapesDark;
    border-radius: default;
    text-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);

    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(
      to top,
      ${transparentize(0.1, theme.colors.shapesDark)} 20%,
      ${transparentize(0.7, theme.colors.shapesDark)}
    );

    :hover {
      color: inherit;
      border-color: gold;
      transform: translateY(-2px);

      &.giveaways {
        border-color: giveaway;
      }

      &.shop {
        border-color: gift;
      }

      &.treasures {
        border-color: slabs;
      }
    }
  }

  .big {
    grid-area: big;

    background: linear-gradient(
        to bottom,
        ${transparentize(0.9, theme.colors.gold)} 20%,
        ${theme.colors.shapesDark}
      ),
      url(${Tesouros}) no-repeat bottom;
    background-size: cover;

    font-size: 2rem;

    display: flex;
    flex-direction: column;

    text-shadow: 0 2px 40px rgba(0, 0, 0, 0.65);
  }

  .giveaways,
  .shop,
  .treasures {
    align-self: flex-start;
    min-height: 12rem;
  }

  .giveaways {
    grid-area: giveaways;

    background: linear-gradient(
        to bottom,
        ${transparentize(0.9, theme.colors.giveaway)} 20%,
        ${theme.colors.shapesDark}
      ),
      url(${Sorteios}) no-repeat;
    background-size: cover;
  }

  .shop {
    grid-area: shop;

    background: linear-gradient(
        to bottom,
        ${transparentize(0.9, theme.colors.gift)} 20%,
        ${theme.colors.shapesDark}
      ),
      url(${Loja}) no-repeat;
    background-size: cover;
  }

  .treasures {
    grid-area: treasures;

    background: linear-gradient(
        to bottom,
        ${transparentize(0.5, theme.colors.shapesDark)} 20%,
        ${theme.colors.shapesDark}
      ),
      url(${Tesouros}) no-repeat;
    background-size: cover;
  }

  .social {
    grid-area: social;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .row {
      display: flex;
      > * + * {
        margin-left: 1rem;
      }
    }

    > * + * {
      margin-top: 1rem;
    }
  }
`;

export const StyledTip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 210px;

  > * + * {
    margin-top: 1rem;
  }

  img {
    display: inline-block;
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 0.6rem;
      }
    }
  }
`;
