export function getGiveawayRequest(giveawayId) {
  return {
    type: '@giveaway/GET_GIVEAWAY_REQUEST',
    payload: { giveawayId },
  };
}

export function getGiveawaySuccess(data) {
  return {
    type: '@giveaway/GET_GIVEAWAY_SUCCESS',
    payload: { data },
  };
}

export function deleteGiveawayRequest(giveawayId) {
  return {
    type: '@giveaway/DELETE_GIVEAWAY_REQUEST',
    payload: { giveawayId },
  };
}

export function deleteGiveawaySuccess(giveawayId) {
  return {
    type: '@giveaway/DELETE_GIVEAWAY_SUCCESS',
    payload: { giveawayId },
  };
}

export function joinGiveawayRequest(giveawayId, type, ticketCount) {
  return {
    type: '@giveaway/JOIN_GIVEAWAY_REQUEST',
    payload: { giveawayId, type, ticketCount },
  };
}

export function joinGiveawaySuccess(type, tickets) {
  return {
    type: '@giveaway/JOIN_GIVEAWAY_SUCCESS',
    payload: { type, tickets },
  };
}

export function clearGiveaway() {
  return {
    type: '@giveaway/CLEAR_GIVEAWAY',
  };
}

export function addGiveawayTickets(tickets) {
  return {
    type: '@socket/ADD_GIVEAWAY_TICKETS',
    payload: { tickets },
  };
}
