import styled from '@xstyled/styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    align-items: center;

    + div {
      margin-top: 1.2rem;
    }

    > * + * {
      margin-left: 1rem;
    }
  }

  .col {
    display: flex;
    align-items: center;

    &.full {
      flex: 1;

      > * {
        flex: 1;
        justify-content: flex-end;
      }
    }

    > * + * {
      margin-left: 1rem !important;
    }

    > label {
      margin-left: 1rem;
      > * + * {
        margin-left: 1rem !important;
      }
    }

    input {
      width: 100%;
    }
  }

  textarea,
  input,
  .reselect {
    flex: 1;
  }

  textarea,
  input {
    color: #fff;
    font-size: 1.6rem;
    padding: 1rem;
    background-color: shapesDark;
    border-radius: default;
  }

  textarea {
    min-height: 9rem;
  }

  input.tiny {
    width: 4.5rem;
  }

  input.small {
    width: 6rem;
  }

  input.smaller {
    width: 8rem;
  }

  input.big {
    width: 100%;
    flex: 1;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;
