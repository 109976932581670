import styled from '@xstyled/styled-components';
import { transparentize } from 'polished';

import theme from '../../../styles/theme';

export const Container = styled.div`
  padding: page;
  max-width: 980px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 2rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .admin {
    display: flex;
    > * + * {
      margin-left: 1rem;
    }
  }
`;

export const TreasureGrid = styled.div`
  display: grid;
  padding-top: 4rem;

  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 2rem;
`;

export const Banner = styled.div`
  align-self: center;
  background-color: ${transparentize(0.9, theme.colors.twitch)};
  border: 2px solid;
  border-color: twitch;
  padding: 2rem;
  border-radius: default;

  font-size: 1.6rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  > * + * {
    margin-top: 1rem;
  }

  ul {
    list-style: none;
    display: flex;

    li {
      padding: 0.5rem 1rem;
      margin: 0;
      background-color: translucentDark;
      border-radius: default;

      + li {
        margin-left: 1rem;
      }
    }
  }
`;
