import React, { useEffect, useState } from 'react';
import { Container } from './styles';

import TreasureCard from './TreasureCard';

import api from '../../../services/api';

const Treasures = () => {
  const [treasures, setTreasures] = useState([]);

  useEffect(() => {
    const getTreasures = async () => {
      try {
        const response = await api.get('/admin/treasures');
        const formattedData = response.data.map(treasure => ({
          ...treasure,
          formatted_ended_at: new Intl.DateTimeFormat('pt-BR', {
            timeStyle: 'medium',
            dateStyle: 'short',
          }).format(new Date(treasure.ended_at)),
        }));
        setTreasures(formattedData);
      } catch (err) {}
    };

    getTreasures();
  }, []);

  return (
    <Container>
      {treasures.map(treasure => (
        <TreasureCard id={treasure.id} {...treasure} />
      ))}
    </Container>
  );
};

export default Treasures;
