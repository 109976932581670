import styled from '@xstyled/styled-components';

export const Container = styled.div`
  padding: 2rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  > * + * {
    margin-top: 2rem;
  }
`;
