import React from 'react';

import PropTypes from 'prop-types';

import { MaterialDialog } from './styles';

function Dialog({ children, ...rest }) {
  return <MaterialDialog {...rest}>{children}</MaterialDialog>;
}

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default Dialog;
