import styled from '@xstyled/styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .item {
    .row {
      margin-top: 2rem;
      display: flex;
      align-items: center;

      input {
        width: 50%;
        font-size: 1.4rem;
      }

      label {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      > * + * {
        margin-left: 1rem;
      }
    }

    + * {
      margin-top: 3rem;
    }
  }
`;
