import styled from '@xstyled/styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: default;
  border: 2px solid;
  border-color: shapesDark;

  .left {
    display: flex;

    img {
      height: 6rem;
      border-radius: default;
    }

    div {
      margin-left: 1.2rem;
      display: flex;
      flex-direction: column;

      > * + * {
        margin-top: 0.6rem;
      }

      strong {
        font-size: 1.6rem;
      }

      span {
        color: ${({ typeColor }) => typeColor};
        text-transform: uppercase;
        font-size: 1.2rem;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    * + * {
      margin-top: 1rem;
    }

    time {
      font-size: 1.2rem;
      color: #999;
      font-weight: bold;
    }

    div {
      * + * {
        margin-left: 0.8rem;
      }

      > * + * {
        margin-top: 1rem;
      }
    }

    strong {
      font-size: 1.8rem;
    }
  }
`;
