import styled, { css } from '@xstyled/styled-components';
import { darken } from 'polished';

import { easeOutCirc } from 'eazy-ease';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;

  > div {
    flex: 1;
    border-radius: default;
  }

  .details {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    border: 3px solid;
    border-color: shapesDark;
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6rem;

      .actions {
        display: flex;
        > * + * {
          margin-left: 0.6rem;
        }
      }

      strong {
        font-size: 2rem;
      }
    }

    .content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      > * + * {
        margin-top: 2rem;
      }

      input {
        background-color: shapesDark;
        font-size: 1.6rem;
      }
      .draw {
        display: flex;
        flex-direction: column;
        align-items: center;
        > * + * {
          margin-top: 2rem;
        }
        .top {
          display: flex;
          align-items: center;
          justify-content: center;

          > * + * {
            margin-left: 1rem;
          }
        }
      }
    }
  }
`;

export const UserList = styled.div`
  max-height: 100%;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: shapesDark;

  .users {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
`;

export const User = styled.p`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: default;
  color: ${({ color }) => color};
  transition: all 0.1s ${easeOutCirc};
  background-color: shapes;
  padding: 1rem;

  + p {
    margin-top: 1rem;
  }

  .user {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 0.6rem;
    }
  }

  .number {
    background-color: translucentDark;
    padding: 0.5rem 1.6rem;
    font-size: 1.4rem;
    border-radius: default;
    color: success;
  }

  :hover {
    transform: scale(1.01);
    box-shadow: 0 4px 10px -3px rgba(0, 0, 0, 0.2);
  }

  :nth-child(even) {
    /* background-color: shapes; */
    background-color: ${darken(0.02, theme.colors.shapes)};
  }
`;

export const Winner = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > * + * {
    margin-top: 2rem;
  }

  ${({ isSub }) =>
    isSub &&
    css`
      h1 {
        color: twitch;
      }
    `}
`;

export const StartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
