import React from 'react';
import Tippy from '@tippy.js/react';
import { roundArrow } from 'tippy.js/dist/tippy.esm';

import { Container } from './styles';

export default function Label({ children, tip, ...rest }) {
  return (
    <Container {...rest}>
      {tip ? (
        <Tippy
          animation="shift-toward"
          arrow={roundArrow}
          content={tip}
          className="items__item-tippy"
          delay={300}
          inertia
          theme="default"
        >
          <strong>{children}</strong>
        </Tippy>
      ) : (
        <strong>{children}</strong>
      )}
    </Container>
  );
}
