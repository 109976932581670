import React from 'react';

import {
  Container,
  DetailsContainer,
  Details,
  Info,
  Status,
  Actions,
} from './styles';

import { FaKey, FaShoppingCart, FaCoins } from 'react-icons/fa';

import Button from '../../../../components/Button';
import theme from '../../../../styles/theme';

function ShopItem({ to, id, title, image, background, price, enabled, keys }) {
  return (
    <Container background={background} className="item">
      <DetailsContainer to={to}>
        <Details>
          <img src={image} alt={title} />
          <div>
            <h2>{title}</h2>
            <Info>
              <div>
                <FaCoins className="color-gold" size={12} />
                <strong>{price}</strong>
              </div>
              <div>
                <FaKey size={12} />
                <strong>{keys.length}</strong>
              </div>
              <div>
                <FaShoppingCart size={12} />
                <strong>31</strong>
              </div>
              <strong>&middot;</strong>
              <Status enabled={enabled}>{enabled ? 'Ativo' : 'Inativo'}</Status>
            </Info>
          </div>
        </Details>
      </DetailsContainer>
      <Actions>
        <Button
          to={`/admin/loja/produtos/${id}/keys`}
          color={theme.colors.steam}
          text={theme.colors.text}
        >
          <FaKey />
          <span>Chaves</span>
        </Button>
      </Actions>
    </Container>
  );
}

export default ShopItem;
