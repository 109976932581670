import styled from '@xstyled/styled-components';

import { Link } from 'react-router-dom';

import theme from '../../../styles/theme';

export const Container = styled.div`
  font-size: 1.4rem;

  display: flex;
  flex-direction: column;
`;
