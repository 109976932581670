import produce from 'immer';

const INITIAL_STATE = {
  loading: true,
  defaults: null,
  hotlinks: [],
};

export default function info(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@info/GET_INFO_SUCCESS':
        draft.defaults = action.payload.data.defaults;
        draft.hotlinks = action.payload.data.hotlinks;
        draft.stream = action.payload.data.stream;
        draft.loading = false;
        break;

      default:
        break;
    }
  });
}
