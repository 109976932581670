import styled from '@xstyled/styled-components';

export const Container = styled.div`
  width: 100%;

  .filepond--root {
    margin-bottom: 0;
    font-size: 1.2rem;
  }

  .filepond--wrapper {
    width: 100%;
  }

  .filepond--panel-root {
    background-color: shapesDark;
  }

  .filepond--image-preview {
    background-color: shapesDark;
  }

  .filepond--drop-label {
    color: text;
  }
`;
