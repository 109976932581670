import React from 'react';

import Avatar from '../../../../components/Avatar';

import { Container } from './styles';

export default function Winner({ username, avatar, is_admin, is_verified }) {
  return (
    <Container>
      <h2>Vencedor</h2>

      <Avatar size={64} url={avatar} admin={is_admin} verified={is_verified} />
      <h3>{username}</h3>
    </Container>
  );
}
