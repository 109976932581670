import styled, { css } from '@xstyled/styled-components';

import theme from '../../../../styles/theme';

import { lighten, transparentize } from 'polished';

export const Container = styled.div`
  margin: 1rem 0;
  display: inline-block;
  width: 100%;
  background-color: shapesDark;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  font-style: italic;
  border-left: 4px solid;
  border-color: ${lighten(0.1, theme.colors.shapes)};
  border-radius: default;

  > a {
    text-decoration: underline;
  }

  ${({ color }) =>
    color &&
    css`
      border-color: ${color};
      background-color: ${transparentize(0.8, theme.colors[color])};
    `}
`;
