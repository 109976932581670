import React, { useEffect, useState } from 'react';

import api from '../../services/api';

import Helmet from '../../components/Helmet';
import Empty from '../../components/Empty';

import TreasureGroup from './TreasureGroup';

import { Container, TreasureGrid } from './styles';

function TreasuresGroups({ location }) {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  const { pathname } = location;

  useEffect(() => {
    async function getTreasureGroups() {
      try {
        const response = await api.get('/treasures', {
          params: {
            active: pathname === '/tesouros',
          },
        });

        setGroups(response.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    getTreasureGroups();
  }, []);

  return (
    <Container>
      <Helmet title="Tesouros" description="Tesouros disponíveis" />
      {loading ? (
        <h2>Carregando...</h2>
      ) : (
        <>
          {groups.length ? (
            <TreasureGrid>
              {groups.map(group => (
                <TreasureGroup key={group.id} id={group.id} {...group} />
              ))}
            </TreasureGrid>
          ) : (
            <Empty />
          )}
        </>
      )}
    </Container>
  );
}

export default TreasuresGroups;
