import React from 'react';

import { FaTwitter, FaInstagram, FaDiscord } from 'react-icons/fa';

import Highlight from '../components/Highlight';

import Button from '../../../components/Button';
import theme from '../../../styles/theme';
import Helmet from '../../../components/Helmet';

import ResponsiveEmbed from 'react-responsive-embed';

export default function Subscribers() {
  return (
    <>
      <Helmet title="2021" description="Novidades de 2021!" />

      <div className="post">
        <h1>2021 chegou! 🥂</h1>
        <p>
          2021 chegou e, como sempre, temos que ter <strong>MUITA</strong> coisa
          nova para esse ano, né?
        </p>
        <p>
          Para resumir tudo, fiz este pequeno vídeo onde listo e falo da maioria
          das novidades e do nossos planos para 2021.
        </p>
        <Highlight>
          <h4>Essa página será atualizada quando a live acabar.</h4>
        </Highlight>

        {/* <div className="embed">
          <ResponsiveEmbed
            src="https://www.youtube.com/embed/8aDRbKSyO-M"
            ratio="16:9"
            allowFullScreen
          />
        </div> */}

        <Highlight>
          <div className="social">
            <Button
              href="https://twitter.com/peroniogh"
              color={theme.colors.twitter}
              text={theme.colors.text}
              pulse
            >
              <FaTwitter />
              <span>Twitter</span>
            </Button>
            <Button
              to="https://www.instagram.com/peroniogh"
              colors={[theme.colors.instagram, theme.colors.instagram2]}
              text={theme.colors.text}
              pulse
            >
              <FaInstagram />
              <span>Instagram</span>
            </Button>
            <Button
              to="/como-funciona"
              color={theme.colors.discord}
              text={theme.colors.text}
              pulse
            >
              <FaDiscord />
              <span>Discord</span>
            </Button>
          </div>
        </Highlight>
      </div>
    </>
  );
}
