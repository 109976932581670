export function createPopup(pageURL, title, w, h) {
  const top = window.outerHeight / 2 + window.screenY - h / 2;
  const left = window.outerWidth / 2 + window.screenX - w / 2;
  const win = window.open(
    pageURL,
    title,
    `width=${w}, height=${h}, top=${top}, left=${left}`
  );

  return win;
}

export function generatePlatformLink(platform, code) {
  switch (platform) {
    case 'steam':
      return `https://store.steampowered.com/account/registerkey?key=${code}`;

    default:
      return code;
  }
}
