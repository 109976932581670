import React from 'react';

import { Container } from './styles';

import Ghost from '../../assets/sad.svg';

export default function Empty() {
  return (
    <Container>
      <img src={Ghost} alt="" />
      <div className="shadow"></div>
      <div className="text">
        <h1>Oops...</h1>
        <p>Parece que não há nada por aqui</p>
      </div>
    </Container>
  );
}
