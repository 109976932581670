import styled, { css } from '@xstyled/styled-components';
import { transparentize } from 'polished';
import { easeOutCirc } from 'eazy-ease';

import theme from '../../../styles/theme';

export const Container = styled.div`
  cursor: pointer;
  border-radius: default;
  overflow: hidden;
  font-size: 1.4rem;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  background-color: shapesDark;

  transition: all 0.2s ${easeOutCirc};

  min-width: 300px;

  :hover {
    transform: scale(1.05);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  }

  .admin {
    position: absolute;
  }

  .image {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.9);
    background: linear-gradient(
        to right,
        ${transparentize(0.1, theme.colors.shapesDark)} 20%,
        ${transparentize(0.7, theme.colors.shapesDark)}
      ),
      url(${({ image }) => image}) center;

    background-size: cover;

    min-height: 130px;
    height: auto;

    padding: 1.4rem 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .left {
        display: flex;
        flex-direction: column;
        .brand {
          margin-top: 1rem;
          font-size: 1rem;
          opacity: 0.8;
          text-transform: uppercase;

          ${({ example }) =>
            example &&
            css`
              margin-top: 0;
              line-height: 1rem !important;
            `}
        }
      }

      .edit {
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > * {
        display: flex;
        align-items: center;
        font-weight: bold;
      }

      .left {
        svg {
          margin-right: 0.6rem;
        }

        .price,
        .keys {
          display: flex;
          align-items: center;
        }
        .price {
          svg {
            fill: gold;
          }
        }
        strong {
          margin: 0 0.6rem;
        }
        .keys {
        }
      }

      .platforms {
        > * {
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
          + * {
            margin-left: 0.6rem;
          }
        }

        img {
          width: 14px;
        }

        svg {
          fill: #fff;
        }
      }
    }

    ${({ giftable }) =>
      giftable &&
      css`
        border: 2px solid;
        border-color: gift;
      `}
  }

  ${({ keys }) =>
    !keys &&
    css`
      opacity: 0.35;
      filter: saturate(0.15);

      :hover {
        opacity: 1;
        filter: saturate(0.5);
      }
    `}
`;
