import styled, { css } from '@xstyled/styled-components';
import { transparentize } from 'polished';

import theme from '../../styles/theme';

export const Container = styled.div`
  background-color: ${({ color }) => color};
  padding: default;
  font-size: 1.4rem;
  text-align: center;
  width: 100%;

  ${({ color, transparent }) =>
    transparent &&
    css`
      border-radius: default;
      border: 2px solid;
      border-color: ${color};
      background: linear-gradient(
          0deg,
          ${transparentize(0.25, theme.colors.shapes)},
          ${transparentize(0.25, theme.colors.shapes)}
        ),
        ${transparentize(0.6, color)};
    `}
`;
