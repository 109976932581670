import React, { forwardRef } from 'react';

import ReactSelect from 'react-select';

import theme from './styles';

const Select = forwardRef((props, ref) => {
  return <ReactSelect ref={ref} {...props} styles={theme} />;
});

export default Select;
