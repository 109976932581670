import styled from '@xstyled/styled-components';

export const Container = styled.div`
  padding: page;
  max-width: 980px;
  margin: 0 auto;
`;

export const TreasureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  grid-gap: 2rem;
`;
