import React, { useState } from 'react';

import { Container, Content } from './styles';

import { TabBar } from '@rmwc/tabs';

import Stats from './Stats';
import Events from './Events';

import Tab from '../../../components/Tab';

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState(1);

  function RenderTab() {
    switch (activeTab) {
      case 0:
        return <Stats />;
      case 1:
        return <Events />;
      default:
        return null;
    }
  }

  return (
    <Container>
      <TabBar
        activeTabIndex={activeTab}
        onActivate={e => setActiveTab(e.detail.index)}
      >
        <Tab label="Detalhes" />
        <Tab label="Notificações" />
      </TabBar>
      <Content>
        <RenderTab />
      </Content>
    </Container>
  );
}
