import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Tab({ color, ...rest }) {
  return <Container color={color} {...rest} />;
}

Tab.propTypes = {
  color: PropTypes.string,
};

Tab.defaultProps = {
  color: 'primary',
};

export default Tab;
