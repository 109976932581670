import React from 'react';

import { FaCoins, FaGift, FaTicketAlt } from 'react-icons/fa';
import { MdConfirmationNumber } from 'react-icons/md';

import { Container } from './styles';

import theme from '../../../styles/theme';

export default function LogItem({ link, type, message, formattedRelative }) {
  function logIcon(type) {
    const size = 12;

    switch (type) {
      case 'gold':
        return <FaCoins size={size} color={theme.colors[type]} />;
      case 'gift':
        return <FaGift size={size} color={theme.colors[type]} />;
      case 'giveaway':
        return <FaTicketAlt size={size} color={theme.colors[type]} />;
      case 'code':
        return <MdConfirmationNumber size={size} color={theme.colors[type]} />;
      default:
        return null;
    }
  }

  return (
    <Container type={type} to={link}>
      <div className="icon">{logIcon(type)}</div>
      <h3>{message}</h3>
      <span className="log__date-relative">{formattedRelative}</span>
    </Container>
  );
}
