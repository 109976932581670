import styled from '@xstyled/styled-components';

import PerfectScrollbar from 'react-perfect-scrollbar';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const Container = styled(PerfectScrollbar)`
  width: 100%;
`;

export const Content = styled.div`
  max-width: 980px;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;

  background-color: shapes;
  color: text;
  padding: page;

  > * {
    margin-bottom: 3rem;
  }
`;
