import React, { useCallback, useState, useRef } from 'react';

import Treasure from '../../../../components/Treasure';
import Button from '../../../../components/Button';
import Avatar from '../../../../components/Avatar';

import { Container, Actions } from './styles';

import theme from '../../../../styles/theme';

import api from '../../../../services/api';
import { FaGoogle, FaArrowRight, FaCircleNotch } from 'react-icons/fa';

function TreasureModal({ treasure, onCancel, visible, isJoinable }) {
  const [participants, setParticipants] = useState(null);
  const [step, setStep] = useState(1);
  const [shaking, setShaking] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [winner, setWinner] = useState(null);
  const [prize, setPrize] = useState(null);
  const [loading, setLoading] = useState(false);

  const winnerRef = useRef(null);
  const userRef = useRef(null);

  const start = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.post(`/admin/treasures/${treasure.id}/start`);
      setParticipants(response.data);
      setStep(2);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [treasure]);

  const getUser = useCallback(async () => {
    try {
      const response = await api.get('/admin/users', {
        params: {
          search: userRef.current.value,
          basic: true,
        },
      });
      setUser(response.data);
    } catch (err) {
      setUser({
        username: userRef.current.value,
      });
      console.log(err);
    } finally {
      setStep(2);
    }
  }, [treasure]);

  const handleCancel = useCallback(() => {
    onCancel();
    setTimeout(() => {
      setStep(1);
      setOpen(false);
    }, 300);
  }, []);

  const handleDraw = useCallback(async () => {
    setActionsVisible(false);
    setTimeout(() => {
      setShaking(true);
      setStep(3);
    }, 1000);
    setTimeout(() => {
      setOpen(true);
      setActionsVisible(true);
      setShaking(false);
    }, 3000);

    if (!isJoinable) {
      try {
        const response = await api.post(
          `/admin/treasures/${treasure.id}/open`,
          {
            user_id: user.id,
          }
        );
        setWinner(user);
        setPrize(response.data.treasure);
      } catch (err) {
        console.log(err);
      }
      return;
    }

    if (winnerRef.current.value === '') return;

    try {
      const response = await api.post(`/admin/treasures/${treasure.id}/open`, {
        user: participants[Number(winnerRef.current.value) - 1],
      });
      console.log(response.data);
      setWinner(response.data.winner);
      setPrize(response.data.treasure);
    } catch (err) {
      console.log(err);
    }
  }, [treasure, participants, user, isJoinable]);

  const StepOne = useCallback(() => {
    return (
      <>
        {isJoinable ? (
          <Button
            onClick={start}
            color={theme.colors.gold}
            disabled={loading}
            loading={loading}
            pulse
          >
            Iniciar sorteio
          </Button>
        ) : (
          <div className="row">
            <input ref={userRef} placeholder="Nome do ganhador" />
            <Button onClick={getUser} color={theme.colors.gold} pulse>
              <FaArrowRight />
            </Button>
          </div>
        )}

        <Button
          onClick={handleCancel}
          color={theme.colors.shapesDark}
          text={theme.colors.error}
        >
          Cancelar
        </Button>
      </>
    );
  }, [treasure, isJoinable, loading]);

  const StepTwo = useCallback(
    ({ onDraw }) => {
      if (!isJoinable) {
        if (user.id) {
          return (
            <>
              <div className="col">
                <Avatar url={user.avatar} size={96} />
                <span>
                  Parabéns, <strong>{user.username}</strong>!
                </span>
              </div>
              <Button
                color={theme.colors.gold}
                text={theme.colors.shapesDark}
                pulse
              >
                Enviar premiação
              </Button>
              <Button
                onClick={handleCancel}
                color={theme.colors.shapesDark}
                text={theme.colors.error}
              >
                Cancelar
              </Button>
            </>
          );
        }
        return (
          <>
            <div className="col space-bottom">
              <h2>Oops...!</h2>
              <p>
                Parece que <strong>{user.username}</strong> não está no sistema.
              </p>
            </div>
            <Button
              onClick={onDraw}
              color={theme.colors.verified}
              text={theme.colors.shapesDark}
              pulse
            >
              Continuar
            </Button>
            <Button
              onClick={handleCancel}
              color={theme.colors.shapesDark}
              text={theme.colors.error}
            >
              Cancelar
            </Button>
          </>
        );
      }

      return (
        <>
          <div className="col space-bottom">
            <h1>{participants.length}</h1>
            <span>estão participando</span>
          </div>
          <div className="row space-bottom">
            <input ref={winnerRef} type="number" />
            <Button
              color={theme.colors.verified}
              text={theme.colors.text}
              url={`https://www.google.com/search?q=random+number+1+${participants.length}`}
            >
              <FaGoogle />
            </Button>
          </div>
          <Button
            onClick={onDraw}
            color={theme.colors.gold}
            text={theme.colors.shapesDark}
            pulse
          >
            Abrir tesouro
          </Button>
          <Button
            onClick={handleCancel}
            color={theme.colors.shapesDark}
            text={theme.colors.error}
          >
            Cancelar
          </Button>
        </>
      );
    },
    [participants, prize, isJoinable, user]
  );

  const StepThree = useCallback(() => {
    return (
      <>
        <div className="col space-top">
          <Avatar url={winner && winner.avatar} size={96} />
          <span className="big">
            Parabéns, <strong>{winner.username}</strong>!
          </span>
        </div>
        <div className="col space-top">
          <img className="prize" src={prize.image} alt={prize.title} />
          <h3>{prize.title}</h3>
        </div>
        <Button
          className="space-top"
          onClick={handleCancel}
          color={theme.colors.shapesDark}
          text={theme.colors.error}
        >
          Fechar
        </Button>
      </>
    );
  }, [winner, prize, user]);

  const CurrentStep = useCallback(() => {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo onDraw={handleDraw} />;
      case 3:
        return <StepThree />;
    }
  }, [treasure, participants, step, loading]);

  return (
    <Container visible={visible}>
      <div className="fade"></div>
      <div className="content">
        {treasure && (
          <Treasure
            className={`${shaking && 'shake shake-constant'}`}
            type={treasure.type}
            open={open}
            innerGlow={open}
            size={open ? 124 : 220}
          />
        )}
        <Actions visible={actionsVisible}>
          <CurrentStep />
        </Actions>
      </div>
    </Container>
  );
}

export default TreasureModal;
