import styled from '@xstyled/styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 1.6rem;

  > * + * {
    margin-top: 3rem;
  }
`;

export const Actions = styled.div`

  display: flex;
  justify-content: space-between;

  .left, .right {

    > * + * {
      margin-left: 1rem;
    }
  }

  .right {

    display: flex;
    justify-content: flex-end;
  }

`;
