import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import info from './info/sagas';
import giveaway from './giveaway/sagas';
import verification from './verification/sagas';
import shop from './shop/sagas';

export default function* rootSaga() {
  return yield all([auth, user, info, giveaway, verification, shop]);
}
