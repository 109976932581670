import React, { memo } from 'react';

import {
  FaCoins,
  FaBox,
  FaPen,
  FaSteamSquare,
  FaPlaystation,
  FaXbox,
  FaNintendoSwitch,
} from 'react-icons/fa';
import { MdLink } from 'react-icons/md';

import { Container } from './styles';

import Button from '../../../components/Button';

import theme from '../../../styles/theme';

import EpicGamesLogo from '../../../assets/icons/epic.png';
import UplayLogo from '../../../assets/icons/uplay.svg';
import CorsairLogo from '../../../assets/icons/corsair.svg';
import OriginLogo from '../../../assets/icons/origin.svg';

function ShopItem({
  id,
  title,
  image,
  product_url,
  brand,
  rating,
  price,
  is_giftable,
  onClick,
  example,
  keys,
  isAdmin,
  platform,
}) {
  function PlatformIcon() {
    if (!platform) return <MdLink size={18} />;
    switch (platform.name) {
      case 'Steam':
        return <FaSteamSquare size={18} />;
      case 'Epic Games Store':
        return <img src={EpicGamesLogo} />;
      case 'Playstation 4':
        return <FaPlaystation size={18} />;
      case 'Xbox One':
        return <FaXbox size={18} />;
      case 'Nintendo Switch':
        return <FaNintendoSwitch size={18} />;
      case 'Corsair':
        return <img src={CorsairLogo} />;
      case 'Origin':
        return <img src={OriginLogo} />;
      case 'Uplay':
        return <img src={UplayLogo} />;
      default:
        return <MdLink size={18} />;
    }
  }

  return (
    <Container
      image={image}
      giftable={is_giftable}
      onClick={() => !example && onClick(id)}
      example={example}
      keys={keys}
    >
      <div className="image">
        <div className="top">
          <div className="left">
            <h3>{title}</h3>
            <strong className="brand">{brand}</strong>
          </div>
          {isAdmin && (
            <Button
              className="edit"
              to={`/admin/loja/produtos/${id}`}
              color={theme.colors.translucentDark}
              text={theme.colors.text}
              icon
            >
              <FaPen />
            </Button>
          )}
        </div>
        <div className="bottom">
          <div className="left">
            <div className="price">
              <FaCoins /> {price}
            </div>
            <strong>&middot;</strong>
            <div className="keys">
              <FaBox size={12} /> {keys}
            </div>
          </div>

          <div className="platforms">
            <Button
              url={product_url}
              color={theme.colors.translucentDark}
              text={theme.colors.text}
              icon
            >
              <PlatformIcon />
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default memo(ShopItem);
