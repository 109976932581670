import styled from '@xstyled/styled-components';

import { Tab as MaterialTab } from '@rmwc/tabs';

import theme from '../../../styles/theme';

export const Container = styled.div`
  width: 100%;
`;

export const Tab = styled(MaterialTab)`
  --mdc-theme-primary: ${theme.colors.primary};

  flex: 1;

  &.mdc-tab {
    padding: 0 1rem;
    :hover .mdc-tab__text-label {
      color: text;
    }
  }
  &.mdc-tab .mdc-tab__text-label {
    font-size: 1.4rem;
    color: text;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  &.mdc-tab--active .mdc-tab__text-label {
    color: primary;
  }
  .mdc-tab-indicator .mdc-tab-indicator__content--underline {
    border-color: shapesDark;
    opacity: 1;
  }
  .mdc-tab-indicator--active .mdc-tab-indicator__content--underline {
    border-color: primary;
  }
  .mdc-tab__ripple.mdc-ripple-upgraded--background-focused::before {
    opacity: 0;
  }
`;

export const Content = styled.div`
  padding: 2rem 0;

  > * + * {
    margin-top: 1.4rem;
  }
`;
