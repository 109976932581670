import styled from '@xstyled/styled-components';

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  background-color: shapes;
`;

export const Container = styled.div`
  background-color: shapes;
  flex: 1;
  color: text;
  height: 100%;
`;

export const Content = styled.div`
  height: 100%;
`;
