import { all, takeLatest, call, put } from 'redux-saga/effects';

import api from '../../../services/api';
import history from '../../../services/history';

import { consumeGold, consumeGift } from '../user/actions';

function* createOrder({ payload }) {
  const { productId, type, price } = payload;

  try {
    yield call(api.post, `/orders/${productId}/${type}`);

    switch (type) {
      case 'gold':
        yield put(consumeGold(price));
        break;
      case 'gift':
        yield put(consumeGift());
        break;

      default:
        break;
    }

    history.push('/pedidos');
  } catch (err) {
    console.log(err);
  }
}

export default all([takeLatest('@shop/CREATE_ORDER_REQUEST', createOrder)]);
