import styled from '@xstyled/styled-components';

import { pulseEffect } from '../../styles/keyframes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;

  > * + * {
    margin-left: 0.8rem;
  }

  svg {
    border-radius: 50%;
    animation: ${({ color }) => color && pulseEffect(color)} 3s infinite;
  }
`;
