import styled, { css } from '@xstyled/styled-components';

import { Dialog } from '@rmwc/dialog';
import { transparentize } from 'polished';

import theme from '../../styles/theme';

export const MaterialDialog = styled(Dialog)`
  --mdc-theme-primary: ${theme.colors.primary};

  .mdc-dialog__surface {
    background-color: shapesDark !important;

    ${({ background }) =>
      background &&
      css`
        background: linear-gradient(
            to right,
            ${transparentize(0.1, theme.colors.shapesDark)} 20%,
            ${transparentize(0.7, theme.colors.shapesDark)}
          ),
          url(${({ background }) => background}) center;

        background-size: cover;
      `}
  }

  .mdc-dialog__title {
    margin: 1rem 0;
    font-size: 1.6rem;
  }

  .mdc-dialog__title,
  .mdc-dialog__content {
    color: text !important;
  }

  .mdc-dialog__content,
  .mdc-dialog__actions button {
    font-size: 1.4rem;
  }

  .mdc-dialog__content {
    h1,
    h2,
    h3 {
      margin-top: 2rem;
    }
    > * + * {
      margin-top: 2rem;
    }
    p {
      line-height: 2.8rem;
    }

    .center {
      text-align: center;
    }
  }
`;
