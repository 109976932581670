import styled from '@xstyled/styled-components';
import theme from '../../../styles/theme';

export const Container = styled.div`
  position: relative;
  max-width: 980px;
  margin: 0 auto;
`;

export const Background = styled.div`
  background: linear-gradient(to bottom, transparent, ${theme.colors.shapes}),
    url(${({ background }) => background});
  min-height: 50vh;
  width: 100%;
  position: absolute;
  z-index: 0;
`;

export const Content = styled.div`
  text-shadow: 0 1px 6px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 1;
  width: 100%;

  padding: page;

  font-size: 1.4rem;

  header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    grid-gap: 4rem;

    > div {
      display: flex;
      flex-direction: column;
      > * + * {
        margin-top: 1rem;
      }
    }

    h1 {
      text-transform: uppercase;
      font-size: 2.6rem;
    }

    .description {
      line-height: 3rem;

      h1,
      h2,
      h3 {
        margin: 1rem 0;
      }
    }
  }

  .status {
    .count {
      display: flex;
      justify-content: space-between;
    }
  }

  span.limit {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 4px;
    }
  }
`;

export const Details = styled.div`
  margin-top: 4rem;
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  grid-gap: 4rem;
  align-items: flex-start;

  .giveaway {
    display: flex;
    flex-direction: column;

    > .container + .container {
      margin-top: 6rem;
    }

    .container {
      background: linear-gradient(
        to bottom,
        ${theme.colors.translucentDark},
        ${theme.colors.shapesDark}
      );
      border-radius: default;
      padding: 2rem 2.2rem;
      margin-top: 3rem;

      > img {
        margin-top: -5rem;
        border-radius: default;
        width: 100%;
        box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.5);
      }

      h3 {
        font-size: 2rem;
        padding: 2rem 0;
      }

      p {
        line-height: 1.8em;
      }

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .info {
    background: linear-gradient(
      to bottom,
      ${theme.colors.translucentDark},
      ${theme.colors.shapesDark}
    );
    /* padding: default; */
    border-radius: default;
  }
`;
