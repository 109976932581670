import React from 'react';

import { FaTrophy } from 'react-icons/fa';

import { Container } from './styles';

import theme from '../../../../styles/theme';
import formatTreasureType from '../../../../utils/formatTreasureName';

const Treasure = ({ image, title, type, winner, formatted_ended_at }) => {
  const treasureType = formatTreasureType(type);

  return (
    <Container className="treasure" typeColor={treasureType.color}>
      <div className="left">
        <img src={image} />
        <div>
          <strong>{title}</strong>
          <span>{treasureType.text}</span>
        </div>
      </div>
      <div className="right">
        <div>
          <FaTrophy color={theme.colors.gold} size={12} />
          <strong>{winner.username}</strong>
        </div>
        <time>{formatted_ended_at}</time>
      </div>
    </Container>
  );
};

export default Treasure;
