import React from 'react';
import { Switch, Redirect, Route as BaseRoute } from 'react-router-dom';

import { spring, AnimatedSwitch } from 'react-router-transition';

import Route from './Route';

import Home from '../pages/Home';

import Tutorials from '../pages/Tutorials';
import NewsTutorial from '../pages/Tutorials/News';
import SubscribersTutorial from '../pages/Tutorials/Subscribers';
import GoldTutorial from '../pages/Tutorials/Gold';
import TicketsTutorial from '../pages/Tutorials/Tickets';
import ShopTutorial from '../pages/Tutorials/Shop';
import GiftsTutorial from '../pages/Tutorials/Gifts';
import GiveawaysTutorial from '../pages/Tutorials/Giveaways';
import DeliveryTutorial from '../pages/Tutorials/Delivery';
import AccountTutorial from '../pages/Tutorials/Account';
import TreasuresTutorial from '../pages/Tutorials/Treasures';

import Giveaways from '../pages/Giveaways';
import Giveaway from '../pages/Giveaways/Giveaway';
import Shop from '../pages/Shop';
import Orders from '../pages/account/Orders';
import Rewards from '../pages/account/Rewards';
import Logs from '../pages/account/Logs';
import Settings from '../pages/account/Settings';
import Points from '../pages/Points';
import Subtember from '../pages/Subtember';
import TreasureGroups from '../pages/TreasureGroups';
import Treasures from '../pages/TreasureGroups/Treasures';
import Contact from '../pages/Tutorials/Contact';

import Dashboard from '../pages/admin/Dashboard';
import AdminPendencies from '../pages/admin/Pendencies';
import AdminGiveaways from '../pages/admin/Giveaways';
import AdminGiveawaysCreate from '../pages/admin/Giveaways/New';
import AdminGiveaway from '../pages/admin/Giveaways/Giveaway';
import AdminGiveawayDraw from '../pages/admin/Giveaways/Draw';
import AdminShop from '../pages/admin/Shop';
import AdminShopCreate from '../pages/admin/Shop/New';
import AdminProduct from '../pages/admin/Shop/Product';
import AdminProductKeys from '../pages/admin/Shop/Keys';
import AdminCodes from '../pages/admin/Codes';
import AdminSubtember from '../pages/admin/Subtember';
import AdminTreasures from '../pages/admin/Treasures';

import AdminChatbot from '../pages/admin/Chatbot';
import AdminChatbotCounter from '../pages/admin/Chatbot/Counter';
import AdminChatbotGifter from '../pages/admin/Chatbot/Gifter';

import AdminUsers from '../pages/admin/Users';
import AdminUser from '../pages/admin/Users/User';
import AdminSettings from '../pages/admin/Settings';

import Post from '../pages/Post';

import AdminLayout from '../layouts/admin';
import AccountLayout from '../layouts/account';
import PostLayout from '../layouts/post';

function mapStyles(styles) {
  return {
    opacity: styles.opacity,
  };
}

function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 30,
  });
}

const transition = {
  atEnter: {
    opacity: 0,
  },
  atLeave: {
    opacity: bounce(0),
  },
  atActive: {
    opacity: bounce(1),
  },
};

export default function Routes() {
  return (
    <AnimatedSwitch
      atEnter={transition.atEnter}
      atLeave={transition.atLeave}
      atActive={transition.atActive}
      mapStyles={mapStyles}
      className="switch-wrapper"
      runOnMount
    >
      <Route exact path="/" component={Home} />
      <Route exact path="/sorteios" component={Giveaways} />
      <Route exact path="/sorteios/:giveaway_id" component={Giveaway} />
      <Route path="/loja" component={Shop} />

      <Route exact path="/como-funciona" component={Tutorials} />
      <Route
        path="/como-funciona/2021"
        component={NewsTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/subscribers"
        component={SubscribersTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/gold"
        component={GoldTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/tickets"
        component={TicketsTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/loja"
        component={ShopTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/gifts"
        component={GiftsTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/sorteios"
        component={GiveawaysTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/prazos"
        component={DeliveryTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/conta"
        component={AccountTutorial}
        layout={PostLayout}
      />
      <Route
        path="/como-funciona/tesouros"
        component={TreasuresTutorial}
        layout={PostLayout}
      />

      <Route path="/contato" component={Contact} layout={PostLayout} />

      <Route path="/pontos" component={Points} isPrivate />
      {/* <Route path="/sorteiodeaniversario" component={Subtember} /> */}
      <Route exact path="/tesouros" component={TreasureGroups} />
      <Route exact path="/cofres" component={TreasureGroups} />
      <Route path="/tesouros/:slug" component={Treasures} />
      <Route path="/cofres/:slug" component={Treasures} />

      <Route path="/premios" component={Rewards} layout={AccountLayout} />
      <Route path="/pedidos" component={Orders} layout={AccountLayout} />
      <Route path="/historico" component={Logs} layout={AccountLayout} />
      <Route path="/conta" component={Settings} layout={AccountLayout} />

      <Route exact path="/admin" component={Dashboard} layout={AdminLayout} />

      <Route
        exact
        path="/admin/pendencias"
        component={AdminPendencies}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/sorteios"
        component={AdminGiveaways}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/sorteios/new"
        component={AdminGiveawaysCreate}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/sorteios/:giveaway_id"
        component={AdminGiveaway}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/sorteios/:giveaway_id/sortear"
        component={AdminGiveawayDraw}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/loja"
        component={AdminShop}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/loja/new"
        component={AdminShopCreate}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/loja/produtos/:product_id"
        component={AdminProduct}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/loja/produtos/:product_id/keys"
        component={AdminProductKeys}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/codigos"
        component={AdminCodes}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/usuarios"
        component={AdminUsers}
        layout={AdminLayout}
      />
      <Route
        exact
        path="/admin/usuarios/:user_id"
        component={AdminUser}
        layout={AdminLayout}
      />

      <Route
        exact
        path="/admin/chatbot"
        component={AdminChatbot}
        layout={AdminLayout}
      />
      <Route
        path="/admin/chatbot/contador"
        component={AdminChatbotCounter}
        layout={AdminLayout}
      />
      <Route
        path="/admin/chatbot/presenteiro"
        component={AdminChatbotGifter}
        layout={AdminLayout}
      />
      {/* <Route
        path="/admin/subtember"
        component={AdminSubtember}
        layout={AdminLayout}
      /> */}

      <Route
        path="/admin/configuracoes"
        component={AdminSettings}
        layout={AdminLayout}
      />

      <Route
        path="/admin/treasures"
        component={AdminTreasures}
        layout={AdminLayout}
      />

      <Route path="/:slug" component={Post} layout={PostLayout} />

      {/* <BaseRoute render={() => <Redirect to="/" />} /> */}
    </AnimatedSwitch>
  );
}
