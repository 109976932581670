import { all, takeLatest, put, call } from 'redux-saga/effects';

import api from '../../../services/api';

import { getInfoSuccess } from './actions';

function* getInfo() {
  const response = yield call(api.get, '/info');

  yield put(getInfoSuccess(response.data));
}

export default all([takeLatest('persist/REHYDRATE', getInfo)]);
