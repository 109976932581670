import produce from 'immer';

const INITIAL_STATE = {
  verified: false,
  verifying: true,
};

export default function verification(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@verification/VERIFICATION_SUCCESS':
        draft.verified = true;
        draft.verifying = false;
        break;

      case '@verification/VERIFICATION_FAILURE':
        draft.verified = false;
        draft.verifying = false;
        break;
      default:
        break;
    }
  });
}
