import styled from '@xstyled/styled-components';

import { Link } from 'react-router-dom';

import theme from '../../../styles/theme';

export const Container = styled(Link)`
  background-color: shapesDark;
  padding: 2rem;
  border-radius: default;
  margin-left: 1.5rem;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    position: absolute;
    background-color: shapes;
    top: 1.5rem;
    left: -1.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 3px solid;
    border-color: shapesDark;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .log__date-relative {
    opacity: 0.75;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  h3 {
    margin-left: 1rem;
  }

  :hover {
    transform: translateX(2px);
    color: ${({ type }) => theme.colors[type]} !important;
  }

  + * {
    margin-top: 1rem;
  }
`;
