import styled, { css } from '@xstyled/styled-components';

import { List as MaterialList } from '@rmwc/list';

import { transparentize } from 'polished';
import theme from '../../../styles/theme';

export const Container = styled.div`
  border: 2px solid;
  border-color: shapesDark;
  border-radius: default;

  display: flex;
  flex-direction: column;

  background: linear-gradient(
      to bottom right,
      ${transparentize(0.1, theme.colors.shapesDark)} 20%,
      ${transparentize(0.4, theme.colors.shapes)}
    ),
    url(${({ background }) => background}) center;

  background-size: cover;

  font-size: 1.4rem;

  .details {
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > * + * {
        margin-top: 1rem;
      }
    }

    a,
    .date {
      text-transform: uppercase;
      font-size: 1.2rem;
      display: block;
      margin-top: 1rem;
    }

    .date {
      opacity: 0.6;
    }

    .step {
      cursor: pointer;
      font-size: 1.4rem;
      height: 2.4rem;
      width: 2.4rem;
      background-color: shapesDark;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

      &.accomplished {
        background-color: primary;
      }
    }

    .status {
      font-size: 1.8rem;
    }
  }

  .rewards {
    background-color: shapes;
    border-top: 2px solid;
    border-color: shapesDark;

    .reward {
      padding: 2rem;
      + * {
        border-top: 2px dotted;
        border-color: shapesDark;
      }
    }
  }
`;

export const Status = styled.strong`
  color: ${({ color }) => color};
  text-transform: uppercase;
`;

export const List = styled(MaterialList)`
  &.mdc-list {
    padding: 0;
  }

  .rmwc-collapsible-list {
    font-size: 1.4rem;
    font-weight: bold;
    color: text !important;

    .rmwc-icon {
      svg {
        fill: text;
      }
    }

    .mdc-list-item {
      font-size: 1.6rem;
    }
  }
`;

export const RewardInfo = styled.div`
  padding: 2rem;
  background: linear-gradient(
      to bottom right,
      ${transparentize(0.1, theme.colors.shapesDark)} 20%,
      ${transparentize(0.4, theme.colors.shapes)}
    ),
    url(${({ background }) => background}) center;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .actions {
    display: flex;
    > * + * {
      margin-left: 0.6rem;
    }
  }

  .key {
    font-family: 'Roboto Mono';
    filter: blur(2px);
    font-size: 1.6rem;
  }

  ${({ keyVisible }) =>
    keyVisible &&
    css`
      .key {
        filter: blur(0px);
      }
    `}

  h3 {
    line-height: 3rem;
  }
`;
