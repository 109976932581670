import styled, { css } from '@xstyled/styled-components';

import { loadingEffect } from '../../styles/keyframes';

export const Container = styled.div`
  padding: 1.2rem 0;
  border-radius: default;

  display: flex;

  font-size: 1.4rem;

  .actions {
    margin-left: 2rem;
    flex: 2;
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 1rem;
    }
  }
`;

export const Input = styled.div`
  padding: 0 1.4rem;
  border-radius: default;
  background-color: shapesDark;

  flex: 1;
  display: flex;
  align-items: stretch;

  svg {
    align-self: center;
    opacity: 0.4;

    ${({ loading }) =>
      loading &&
      css`
        animation: ${loadingEffect} 1s infinite linear;
      `}
  }

  input {
    flex: 1;
    margin-left: 0.6rem;
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 6px;
  }
`;
