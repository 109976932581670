import styled, { css } from '@xstyled/styled-components';

export const Container = styled.div`
  padding: page;
  max-width: 980px;
  margin: 0 auto;
  height: 100%;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4rem;

    strong {
      display: flex;
      align-items: center;
      text-transform: uppercase;

      > * + * {
        margin-left: 6px;
      }
    }

    .left {
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 2rem;
      }
    }
  }

  .empty {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const GiveawayList = styled.div`
  margin-top: 2rem;

  > * + * {
    margin-top: 2rem;
  }
`;

export const GiveawayGrid = styled.div`
  display: grid;
  justify-content: center;

  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(3, 310px); */
  /* grid-template-rows: 1fr;
  grid-template-columns: auto;
  grid-auto-flow: row; */
  grid-gap: 1.4rem;

  ${({ big }) =>
    big &&
    css`
      grid-template-columns: repeat(auto-fit, 460px);
      grid-gap: 2rem;
    `}
`;
