import React from 'react';

import { Link } from 'react-router-dom';

import { FaSkull, FaGifts } from 'react-icons/fa';

import { Container, Grid } from './styles';

export default function Chatbot() {
  return (
    <Container>
      <Grid>
        <div>
          <Link to="/admin/chatbot/contador">
            <div className="title">
              <h3>Contador</h3>
              <FaSkull className="color-primary" />
            </div>
            <p>
              Configurações do <strong>contador de mortes</strong>
            </p>
          </Link>
        </div>
        <div>
          <Link to="/admin/chatbot/presenteiro">
            <div className="title">
              <h3>Presenteiro</h3>
              <FaGifts className="color-gold" />
            </div>
            <p>
              Configurações do <strong>presenteiro</strong>
            </p>
          </Link>
        </div>
      </Grid>
    </Container>
  );
}
