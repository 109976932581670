import React from 'react';

import SkeletonTheme from '../../../../components/SkeletonTheme';
import Skeleton from 'react-loading-skeleton';

export default function Skeletons() {
  return (
    <SkeletonTheme>
      <Skeleton height={32} width={320} />
      <p>
        <Skeleton width={220} />
      </p>
    </SkeletonTheme>
  );
}
