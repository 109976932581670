import styled, { css } from '@xstyled/styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 2.6rem 0;

  ul {
    list-style: none;
    &.center {
      li {
        margin: 0;
        text-align: center;
      }
    }
  }

  p > img {
    margin-left: 1rem;
  }

  ${({ full }) =>
    full &&
    css`
      width: 100%;

      > div {
        width: 100%;
      }
    `}
`;
