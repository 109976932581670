import React, { useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import Treasure from '../../../components/Treasure';

import { Container, Counter } from './styles';

function TreasureGroup({ image, name, description, treasures, slug }) {
  const { pathname } = useLocation();

  const treasureCount = useMemo(() => {
    const types = {
      legendary: 0,
      epic: 0,
      rare: 0,
      common: 0,
    };

    treasures.map(treasure => types[treasure.type]++);

    return types;
  }, [treasures]);

  const TreasureCounter = useCallback(
    ({ type }) => {
      const count = treasureCount[type];
      if (!count) return null;
      return (
        <div className="treasure-container">
          <Treasure
            type={type}
            size={36}
            glowing={false}
            className="treasure"
          />
          <strong>x{count}</strong>
        </div>
      );
    },
    [treasureCount]
  );

  return (
    <Container to={`${pathname}/${slug}`}>
      <div className="content">
        <div className="top">
          <img src={image} alt={name} />
        </div>
        <div className="bottom">
          <div className="body">
            <h2>{name}</h2>
            <p>{description}</p>
          </div>
          <Counter>
            {Object.keys(treasureCount).map(type => (
              <TreasureCounter key={type} type={type} />
            ))}
          </Counter>
        </div>
      </div>
    </Container>
  );
}

export default TreasureGroup;
