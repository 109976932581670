import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import UserDetails from '../../../../components/account/UserDetails';
import UserTabs from '../User/UserTabs';

import api from '../../../../services/api';

import { Container } from './styles';

export default function User() {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  const { user_id } = useParams();

  useEffect(() => {
    async function getUser() {
      const response = await api.get(`/admin/users/${user_id}`);

      console.log(response.data);

      setProfile(response.data);
      setLoading(false);
    }

    getUser();
  }, []);

  return (
    !loading && (
      <Container>
        <UserDetails profile={profile.user} />
        <UserTabs
          rewards={profile.rewards}
          orders={profile.orders}
          logs={profile.logs}
        />
      </Container>
    )
  );
}
