export function signInSuccess(data) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { data },
  };
}

export function signOutRequest() {
  return {
    type: '@auth/SIGN_OUT_REQUEST',
  };
}
