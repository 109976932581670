import styled from '@xstyled/styled-components';

export const Container = styled.div`
  display: flex;
  input {
    flex: 1;
    background-color: shapesDark;
    font-size: 1.6rem;
    padding: 1rem;
    color: white;
    border-radius: default;

    ::placeholder {
      font-style: italic;
    }
  }

  .actions {
    display: flex;

    > * + * {
      margin-left: 1rem;
    }
  }

  > * + * {
    margin-left: 1rem;
  }
`;
