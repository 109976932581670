import produce from 'immer';

const INITIAL_STATE = {
  details: true,
};

export default function settings(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@settings/TOGGLE_DETAILS':
        draft.details = !state.details;
        break;

      default:
        break;
    }
  });
}
