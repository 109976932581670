import React from 'react';

import { Container } from './styles';

import { FaCircle } from 'react-icons/fa';

const LiveNow = ({ color }) => {
  return (
    <Container color={color}>
      <strong>AO VIVO</strong>
      <FaCircle color={color} size={10} />
    </Container>
  );
};

export default LiveNow;
