import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

import useDebounce from '../../../hooks/useDebounce';

function Search({ children, placeholder, onChange }) {
  const [value, setValue] = useState('');

  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    onChange(value);
  }, [debouncedValue]);

  return (
    <Container>
      <input
        type="text"
        placeholder={placeholder}
        onChange={e => setValue(e.target.value)}
      />
      {children}
    </Container>
  );
}

Search.propTypes = {
  children: PropTypes.element,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

Search.defaultProps = {
  children: null,
  onChange: () => {},
};

export default Search;
