import styled from '@xstyled/styled-components';

export const Container = styled.div`
  padding: page;
  max-width: 980px;
  margin: 0 auto;
  height: 100%;

  .learn-more {
    display: block;
    text-align: center;
    background-color: translucentDark;
    width: 100%;
    padding: 1.4rem 1rem;
    border-radius: default;
    font-size: 1.6rem;
  }

  .badges {
    margin: 0 auto;
    margin-top: 2rem;
    display: flex;
    max-width: 480px;
    justify-content: space-between;
  }

  .points {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.4rem;

    h2 {
      margin: 1rem 0;
    }

    .exchange {
      display: flex;
      flex-direction: column;
      align-items: center;
      .input {
        margin-top: 4rem;
        display: flex;
        align-items: center;
        width: 36rem;

        input {
          font-size: 1.6rem;
          width: 100%;
          height: 100%;
        }

        > * + * {
          margin-left: 1rem;
        }
      }

      .result {
        margin-top: 2rem;
        font-size: 1.4rem;
        transition: all 1s ease;

        &.error {
          color: error;
        }
      }

      > * + * {
        margin-top: 1.2rem;
      }
    }
  }
`;
