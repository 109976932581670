import styled, { css } from '@xstyled/styled-components';

import theme from '../../../styles/theme';

import { pulseEffect } from '../../../styles/keyframes';

import { transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  strong {
    margin-top: 1rem;
  }

  small {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      font-weight: bold;
      margin: 0 4px;
      svg {
        margin-right: 4px;
      }
    }
  }

  opacity: 0.65;
  transform: scale(0.8);

  transition: all 0.2s ease;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      transform: scale(1);
    `}
`;

export const LogoContainer = styled.div`
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: default;

  background: linear-gradient(${theme.colors.shapesDark}, transparent);
  background-size: cover;
  background-repeat: no-repeat;
  border-width: 3px;
  border-style: solid;
  border-color: shapesDark;

  animation: ${({ active }) => active && pulseEffect(theme.colors.twitch)} 5s
    infinite;

  img {
    width: 100%;
    padding: 8px;
    object-fit: contain;
  }

  transition: all 1s ease;

  ${({ active }) =>
    active &&
    css`
      background: linear-gradient(
        ${transparentize(0.8, theme.colors.twitch)},
        ${transparentize(0.9, theme.colors.twitch)}
      );
      border-color: twitch;
      border-width: 4px;
    `}
`;
