import React from 'react';

import ProductForm from '../ProductForm';

import api from '../../../../services/api';
import history from '../../../../services/history';

export default function Product() {
  async function handleSubmit(data) {
    await api.put(`/admin/products/${data.product_id}`, data);
    history.push('/admin/loja');
  }

  return <ProductForm className="product" onSubmit={handleSubmit} />;
}
