import React, { useEffect, useState, useRef } from 'react';

import { DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';

import Search from '../../../components/admin/Search';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Select from '../../../components/Select';

import DatePicker from 'react-datepicker';
import {
  FaCoins,
  FaGift,
  FaTicketAlt,
  FaEyeSlash,
  FaEye,
  FaTrophy,
  FaCalendar,
  FaCheck,
  FaTrash,
  FaCalendarCheck,
  FaCheckCircle,
} from 'react-icons/fa';

import { format, addMonths } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Container, Code } from './styles';

import theme from '../../../styles/theme';

import api from '../../../services/api';
import { queue } from '../../../services/snackbar';

const types = [
  { label: 'Gold', value: 'gold' },
  { label: 'Gift', value: 'gift' },
  { label: 'Ticket', value: 'ticket' },
  { label: 'Purple Ticket', value: 'unique_ticket' },
];

export default function Codes() {
  const [codes, setCodes] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [creatingEndsAt, setCreatingEndsAt] = useState(
    addMonths(new Date(), 1)
  );
  const [type, setType] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [removeCode, setRemoveCode] = useState(null);
  const [search, setSearch] = useState('');

  const quantityRef = useRef(null);
  const amountRef = useRef(null);

  function codeIcon(type) {
    const size = 12;

    switch (type) {
      case 'gold':
        return <FaCoins size={size} color={theme.colors[type]} />;
      case 'gift':
        return <FaGift size={size} color={theme.colors[type]} />;
      case 'ticket':
        return <FaTicketAlt size={size} color={theme.colors[type]} />;
      case 'unique_ticket':
        return <FaTicketAlt size={size} color={theme.colors['giveaway']} />;
      default:
        return null;
    }
  }

  async function handleSubmit() {
    try {
      const response = await api.post('/admin/codes', {
        codeAmount: quantityRef.current.value,
        type: type.value,
        amount: amountRef.current.value,
        expiresAt: creatingEndsAt,
      });

      setIsCreating(false);

      setCodes(prev => [...response.data, ...prev]);
    } catch (err) {}
  }

  function handleRemoveDialog(codeId) {
    setRemoveCode(codeId);
    setDialog(true);
  }

  async function handleRemove() {
    try {
      await api.delete(`/admin/codes/${removeCode}`);

      setCodes(prev => prev.filter(code => code.id !== removeCode));

      setDialog(false);
    } catch (err) {}
  }

  function handleCopy(key) {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_URL}/conta?codigo=${key}`
    );

    queue.notify({
      title: <b>Tudo certo!</b>,
      body: 'Código copiado!',
      icon: <FaCheckCircle size={18} color={theme.colors.success} />,
      actions: [],
    });
  }

  useEffect(() => {
    async function getCodes() {
      try {
        const response = await api.get('/admin/codes', {
          params: {
            search,
          },
        });

        const formattedCodes = response.data.map(code => ({
          ...code,
          formattedExpires: format(new Date(code.expires_at), 'dd/MM/yy', {
            locale: ptBR,
          }),
          formattedRedeemedAt: format(new Date(code.redeemed_at), 'dd/MM/yy', {
            locale: ptBR,
          }),
        }));

        setCodes(formattedCodes);
      } catch (err) {
        console.log(err.response);
      }
    }

    getCodes();
  }, [search]);

  function RenderCode({
    id,
    type,
    amount,
    redeemer,
    formattedExpires,
    formattedRedeemedAt,
  }) {
    const [isKeyVisible, setIsKeyVisible] = useState(false);

    const Icon = isKeyVisible ? FaEyeSlash : FaEye;

    const CalendarIcon = redeemer ? FaCalendarCheck : FaCalendar;

    return (
      <Code key={id} visible={isKeyVisible}>
        <div className="icon" onClick={() => handleCopy(id)}>
          {codeIcon(type)}
        </div>
        <h3>{isKeyVisible ? id : 'XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'}</h3>
        <div className="right">
          {formattedExpires && (
            <div className="expires">
              <CalendarIcon className={redeemer && 'color-success'} />
              <strong>
                {redeemer ? formattedRedeemedAt : formattedExpires}
              </strong>
            </div>
          )}
          <div className="amount">
            <FaTrophy />
            <strong>{amount}</strong>
          </div>
          {redeemer ? (
            <Button
              color={theme.colors.success}
              text={theme.colors.text}
              tip={
                <span>
                  Resgatado por <strong>{redeemer.username}</strong>
                </span>
              }
              to={`/admin/usuarios/${redeemer.id}`}
            >
              <FaCheck />
            </Button>
          ) : (
            <Button
              color={theme.colors.verified}
              text={theme.colors.text}
              onClick={() => setIsKeyVisible(prev => !prev)}
            >
              <Icon />
            </Button>
          )}
          {!redeemer && (
            <Button
              color={theme.colors.translucentDark}
              text={theme.colors.error}
              onClick={() => handleRemoveDialog(id)}
            >
              <FaTrash />
            </Button>
          )}
        </div>
      </Code>
    );
  }

  return (
    <Container>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogTitle>Remover código</DialogTitle>
        <DialogContent>
          <p>Tem certeza que deseja remover esse código?</p>
        </DialogContent>
        <DialogActions>
          <Button
            color={theme.colors.translucentDark}
            text={theme.colors.error}
            onClick={handleRemove}
          >
            Remover
          </Button>
        </DialogActions>
      </Dialog>
      <Search placeholder="Buscar código..." onChange={setSearch}>
        <div className="actions">
          {isCreating ? (
            <>
              <Button
                color={theme.colors.translucentDark}
                text={theme.colors.text}
                onClick={() => setIsCreating(false)}
              >
                Cancelar
              </Button>
              <Button
                color={theme.colors.verified}
                text={theme.colors.text}
                onClick={handleSubmit}
              >
                Gerar
              </Button>
            </>
          ) : (
            <Button
              color={theme.colors.code}
              text={theme.colors.text}
              onClick={() => setIsCreating(true)}
            >
              Novo código
            </Button>
          )}
        </div>
      </Search>
      {isCreating && (
        <div className="creating">
          <div className="col">
            <span>Códigos:</span>
            <input
              ref={quantityRef}
              type="number"
              className="sm"
              min="1"
              defaultValue="1"
            />
          </div>
          <div className="col select">
            <span>Tipo:</span>
            <Select
              onChange={value => setType(value)}
              placeholder="Escolha"
              options={types}
            />
          </div>
          <div className="col">
            <span>Premios:</span>
            <input
              ref={amountRef}
              type="number"
              className="sm"
              min="1"
              defaultValue="1"
            />
          </div>
          <div className="col">
            <span>Expira em:</span>
            <DatePicker
              className="md"
              selected={creatingEndsAt}
              onChange={date => setCreatingEndsAt(date)}
            />
          </div>
        </div>
      )}
      {codes.map(code => (
        <RenderCode key={code.id} {...code} />
      ))}
    </Container>
  );
}
