import React from 'react';

import { Link } from 'react-router-dom';

import { FaArrowRight, FaBacon } from 'react-icons/fa';

import Quote from '../components/Quote';
import Highlight from '../components/Highlight';

import Helmet from '../../../components/Helmet';

export default function Gold() {
  return (
    <>
      <Helmet
        title="Pontos e gold"
        description="Acesse para entender a diferença entre pontos e gold"
      />

      <div className="post">
        <h1>Pontos e gold</h1>

        <Quote>
          Assista a live, ganhe pontos, troque por{' '}
          <strong className="color-gold">gold</strong> e adquira produtos na{' '}
          <Link to="/loja">
            <strong>lojonia</strong>
          </Link>
        </Quote>

        <h3>Como eu consigo pontos na live?</h3>

        <p>
          <strong>Assistindo a live</strong>. Esta é a única maneira de
          conseguir farmar pontos da live. Com a live{' '}
          <strong className="color-success">online</strong> você recebe{' '}
          <strong>2 pontos</strong> a cada 10 minutos de live assistidos. Você
          encontra a quantidade de pontos no topo do site, ao lado do símbolo de{' '}
          <strong>
            bacon <FaBacon />
          </strong>
          .
        </p>

        <h3>Já tenho muitos pontos acumulados. O que posso fazer com eles?</h3>

        <ul>
          <li>
            <h4>
              Participar de sorteios abertos no chat e participar de jogos na
              live
            </h4>
            <p>
              Você pode utilizar seus pontos para entrar em sorteios abertos no
              chat, onde a maneira de entrar é gastando pontos, você também pode
              participar de jogos como o <strong>Show do Cofrão</strong>,{' '}
              <strong>PeronioTube</strong> e outros que irão utilizar seus
              pontos como moeda.
            </p>
          </li>
          <li>
            <h4>
              Trocar por <strong className="color-gold">gold</strong> e adquirir
              produtos na lojonia
            </h4>
            <p>
              Com seus pontos acumulados, basta entrar na lojonia e trocar
              pontos por GOLD. Todos podem trocar, mas{' '}
              <strong className="color-twitch">subscribers</strong> tem bônus!
              Segue abaixo a tabela de conversão atual:
              <Highlight>
                <ul className="center">
                  <li>
                    Um <strong>forte</strong> troca: 20 pontos{' '}
                    <FaArrowRight size={12} /> 1{' '}
                    <strong className="color-gold">gold</strong>
                  </li>
                  <li>
                    <strong className="color-twitch">Subs Tier 1</strong> (ou{' '}
                    <a
                      href="https://twitch.amazon.com/tp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong className="color-prime">Prime</strong>
                    </a>
                    ) trocam: 10 pontos <FaArrowRight size={12} /> 1{' '}
                    <strong className="color-gold">gold</strong>
                  </li>
                  <li>
                    <strong className="color-twitch">Subs Tier 2</strong>{' '}
                    trocam: 5 pontos <FaArrowRight size={12} /> 1{' '}
                    <strong className="color-gold">gold</strong>
                  </li>
                  <li>
                    <strong className="color-twitch">Subs Tier 3</strong>{' '}
                    trocam: 3 pontos <FaArrowRight size={12} /> 1{' '}
                    <strong className="color-gold">gold</strong>
                  </li>
                </ul>
              </Highlight>
            </p>
          </li>
        </ul>

        <h3>É preguiçoso com matemática? Eu te ajudo com um resumo :)</h3>

        <p>
          Cada usuário recebe <strong>2 pontos</strong> a cada 10 minutos de
          live assistidos. Partindo do princípio de ao menos 5 lives semanais
          com uma duração de 7 horas em média:
        </p>

        <Highlight>
          <ul className="center">
            <li>
              Média de <strong>84 pontos</strong> por live
            </li>
            <li>
              Média de <strong>420 pontos</strong> por semana
            </li>
            <li>
              Média de <strong>1680 pontos</strong> por mês
            </li>
          </ul>
        </Highlight>

        <Highlight>
          <ul className="center">
            <li>
              Um <strong>forte</strong> consegue, em média,{' '}
              <strong>
                84 <span className="color-gold">gold</span>
              </strong>{' '}
              por mês
            </li>
            <li>
              Um <strong className="color-twitch">Sub Tier 1</strong> (ou{' '}
              <strong className="color-prime">Prime</strong>) consegue, em
              média,{' '}
              <strong>
                168 <span className="color-gold">gold</span>
              </strong>{' '}
              por mês
            </li>
            <li>
              Um <strong className="color-twitch">Sub Tier 2</strong> consegue,
              em média,{' '}
              <strong>
                336 <span className="color-gold">gold</span>
              </strong>{' '}
              por mês
            </li>
            <li>
              Um <strong className="color-twitch">Sub Tier 3</strong> consegue,
              em média,{' '}
              <strong>
                560 <span className="color-gold">gold</span>
              </strong>{' '}
              por mês
            </li>
          </ul>
        </Highlight>

        <Quote color="error">
          Os pontos de todos os fortes serão <strong>resetados</strong> todo dia
          1º de todos os meses. Ou seja, utilizem seus pontos trocando por{' '}
          <strong className="color-gold">gold</strong> na lojonia até o último
          dia de cada mês.
        </Quote>
      </div>
    </>
  );
}
