import styled from '@xstyled/styled-components';
import { easeOutCirc } from 'eazy-ease';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 2rem;
  }
`;

export const Products = styled.div`
  .item {
    transition: all 0.1s ${easeOutCirc};

    :hover {
      transform: translateX(2px);
    }
    + * {
      margin-top: 1rem;
    }
  }
`;

export const Filters = styled.div`
  font-size: 1.2rem;

  display: flex;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }
`;
