import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import DefaultLayout from '../layouts/default';

import { store } from '../store';

function RouteWrapper({
  component: Component,
  isPrivate,
  location,
  layout,
  ...rest
}) {
  const { signed } = store.getState().auth;
  const { profile } = store.getState().user;

  if (location.pathname.startsWith('/admin')) {
    if (!signed || !profile.is_admin) {
      return (
        <DefaultLayout>
          <Redirect to="/" />
        </DefaultLayout>
      );
    }
  }

  if (isPrivate && !signed) {
    return <Redirect to="/" />;
  }

  const Layout = layout || DefaultLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export default RouteWrapper;
