import styled, { css, keyframes } from '@xstyled/styled-components';

const rotate = keyframes`

  from {
    transform: scale(1.75) rotate(0deg);
  }

  to {
    transform: scale(1.75) rotate(359deg);

  }

`;

export const Top = styled.img`
  position: absolute;
  width: 100%;
  left: 0;
`;

export const Bottom = styled.img``;

export const Chest = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;

  img {
    width: 100%;
    pointer-events: none;
    object-fit: contain;
  }
`;

export const Glow = styled.img`
  position: absolute;
  top: 0;
  z-index: -1;

  animation: ${rotate} 60s linear infinite;
`;

export const InnerGlow = styled.img`
  position: absolute;
  top: 0;
  z-index: 5;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: ${({ size }) => `${size}px`};

  ${({ open }) =>
    open &&
    css`
      ${Bottom} {
        position: relative;
        z-index: 1;
      }

      ${Top} {
        bottom: ${({ size }) => `${size / 1.8}px`};
        left: ${({ size }) => `${size / 8}px`};
      }
    `};

  ${({ onClick }) =>
    onClick &&
    css`
      ${Chest} {
        cursor: pointer;
      }
    `}
`;
