import React, { useState, useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { Container, KeyList, Key } from './styles';

import api from '../../../../services/api';
import theme from '../../../../styles/theme';

import Button from '../../../../components/Button';
import Dialog from '../../../../components/Dialog';

import { FaEye, FaEyeSlash, FaTrash } from 'react-icons/fa';
import { DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';

export default function Keys() {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [productKeys, setProductKeys] = useState([]);
  const [dialog, setDialog] = useState(false);

  const [removeKey, setRemoveKey] = useState('');

  const { product_id } = useParams();

  const newKeyRef = useRef();

  function toggleKeyVisibility(id) {
    setProductKeys(
      productKeys.map(key => ({
        ...key,
        visible: key.id === id ? !key.visible : key.visible,
      }))
    );
  }

  async function handleRemoveKey(id) {
    setRemoveKey(id);
    setDialog(true);
  }

  async function handleCreateKey(e) {
    e.preventDefault();
    try {
      const value =
        newKeyRef.current.value !== ''
          ? newKeyRef.current.value
          : format(new Date(), "dd/MM/yy', às' HH:mm", {
              locale: ptBR,
            });

      const response = await api.post(`/admin/shop/${product_id}/keys`, {
        value,
      });

      setProductKeys([{ ...response.data, visible: false }, ...productKeys]);

      newKeyRef.current.value = '';
    } catch (err) {}
  }

  async function handleRemove() {
    try {
      await api.delete(`/admin/keys/${removeKey}`);

      setProductKeys(prev => prev.filter(key => key.id !== removeKey));
      setDialog(false);
    } catch (err) {}
  }

  useEffect(() => {
    async function getProduct() {
      const response = await api.get(`/admin/shop/${product_id}`);

      setProduct(response.data.product);
      setProductKeys(
        response.data.product.keys.map(k => ({ ...k, visible: false }))
      );
      setLoading(false);
    }

    getProduct();
  }, []); // eslint-disable-line

  return (
    !loading && (
      <Container background={product_id && product.background}>
        <Dialog open={dialog} onClose={() => setDialog(false)}>
          <DialogTitle>Remover código</DialogTitle>
          <DialogContent>
            Tem certeza que deseja remover essa chave?
          </DialogContent>
          <DialogActions>
            <Button
              color={theme.colors.translucentDark}
              text={theme.colors.error}
              onClick={handleRemove}
            >
              Remover
            </Button>
          </DialogActions>
        </Dialog>

        <header>
          <img src={product.image} alt={product.title} />
          <div className="info">
            <h2>{product.title}</h2>
            <span>
              Cadastrado em <strong>{product.created_at}</strong>
            </span>
          </div>
        </header>
        <form className="keys__key-new" onSubmit={handleCreateKey}>
          <input
            type="text"
            placeholder="XXXX-XXXX-XXXX-XXXX"
            ref={newKeyRef}
          />
          <Button
            color={theme.colors.ticket}
            text={theme.colors.text}
            type="submit"
          >
            Cadastrar
          </Button>
        </form>
        {productKeys && (
          <KeyList>
            {productKeys.map(key => (
              <Key
                key={key.id}
                visible={key.visible}
                reserved={key.is_reserved}
                redeemed={key.redeemed_at}
              >
                <strong>
                  {key.visible ? key.value : 'XXXX-XXXX-XXXX-XXXX'}
                </strong>
                <div className="actions">
                  <Button
                    onClick={() => toggleKeyVisibility(key.id)}
                    visible={key.visible}
                    color={key.visible ? theme.colors.gift : theme.colors.steam}
                    text={theme.colors.text}
                  >
                    {key.visible ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                  <Button
                    onClick={() => handleRemoveKey(key.id)}
                    color={theme.colors.translucentDark}
                    text={theme.colors.error}
                  >
                    <FaTrash />
                  </Button>
                </div>
              </Key>
            ))}
          </KeyList>
        )}
      </Container>
    )
  );
}
