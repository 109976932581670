import React, { useMemo } from 'react';

import { Container, Chest, Top, Bottom, Glow, InnerGlow } from './styles';

import commonBottom from '../../assets/treasures/commonChest__bottom.png';
import commonTopClosed from '../../assets/treasures/commonChest__top--closed.png';
import commonTopOpen from '../../assets/treasures/commonChest__top--open.png';
import commonGlow from '../../assets/treasures/commonGlow.png';
import commonInnerGlow from '../../assets/treasures/commonInnerGlow.png';
import rareBottom from '../../assets/treasures/rareChest__bottom.png';
import rareTopClosed from '../../assets/treasures/rareChest__top--closed.png';
import rareTopOpen from '../../assets/treasures/rareChest__top--open.png';
import rareGlow from '../../assets/treasures/rareGlow.png';
import rareInnerGlow from '../../assets/treasures/rareInnerGlow.png';
import epicBottom from '../../assets/treasures/epicChest__bottom.png';
import epicTopClosed from '../../assets/treasures/epicChest__top--closed.png';
import epicTopOpen from '../../assets/treasures/epicChest__top--open.png';
import epicGlow from '../../assets/treasures/epicGlow.png';
import epicInnerGlow from '../../assets/treasures/epicInnerGlow.png';
import legendaryBottom from '../../assets/treasures/legendaryChest__bottom.png';
import legendaryTopClosed from '../../assets/treasures/legendaryChest__top--closed.png';
import legendaryTopOpen from '../../assets/treasures/legendaryChest__top--open.png';
import legendaryGlow from '../../assets/treasures/legendaryGlow.png';
import legendaryInnerGlow from '../../assets/treasures/legendaryInnerGlow.png';

function Treasure({
  type,
  open = false,
  glowing = true,
  innerGlow = false,
  size = 148,
  prize,
  onClick,
  className,
  canOpen,
}) {
  const ChestBottom = useMemo(() => {
    switch (type) {
      case 'common':
        return commonBottom;
      case 'rare':
        return rareBottom;
      case 'epic':
        return epicBottom;
      case 'legendary':
        return legendaryBottom;

      default:
        return null;
    }
  }, [type]);

  const ChestTop = useMemo(() => {
    switch (type) {
      case 'common':
        return open ? commonTopOpen : commonTopClosed;
      case 'rare':
        return open ? rareTopOpen : rareTopClosed;
      case 'epic':
        return open ? epicTopOpen : epicTopClosed;
      case 'legendary':
        return open ? legendaryTopOpen : legendaryTopClosed;

      default:
        return null;
    }
  }, [type, open]);

  const ChestGlow = useMemo(() => {
    switch (type) {
      case 'common':
        return commonGlow;
      case 'rare':
        return rareGlow;
      case 'epic':
        return epicGlow;
      case 'legendary':
        return legendaryGlow;

      default:
        return null;
    }
  }, [type]);

  const ChestInnerGlow = useMemo(() => {
    switch (type) {
      case 'common':
        return commonInnerGlow;
      case 'rare':
        return rareInnerGlow;
      case 'epic':
        return epicInnerGlow;
      case 'legendary':
        return legendaryInnerGlow;

      default:
        return null;
    }
  }, [type]);

  return (
    <Container
      className={className}
      type={type}
      open={open}
      size={size}
      onClick={canOpen && onClick}
    >
      <Chest>
        {glowing && <Glow src={ChestGlow} />}
        {innerGlow && <InnerGlow src={ChestInnerGlow} />}
        <Bottom src={ChestBottom} />
        <Top src={ChestTop} />
      </Chest>
    </Container>
  );
}

export default Treasure;
