import styled from '@xstyled/styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
  border-radius: default;
  border: 2px solid;
  border-color: shapesDark;

  height: 36rem;
  width: 28rem;

  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .top {
      flex: 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bottom {
      flex: 2;
      padding: default;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .body {
        flex: 1;
        height: 100%;
        padding-top: 1rem;

        p {
          margin-top: 1rem;
          line-height: 2rem;
        }
      }
    }
  }

  :hover {
    color: inherit;
    transform: scale(1.025);
    box-shadow: 0 4px 20px -2px rgba(0, 0, 0, 0.2);
  }
`;

export const Counter = styled.div`
  display: flex;
  > * + * {
    margin-left: 1rem;
  }

  .treasure-container {
    position: relative;

    .treasure {
      margin: 0;
      display: inline-block;
    }

    strong {
      z-index: 10;
      right: -10px;
      top: -6px;
      position: absolute;
      background-color: gift;
      padding: 2px 6px;
      border-radius: default;
      font-size: 1.2rem;
    }
  }
`;
