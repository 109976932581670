import styled from '@xstyled/styled-components';

export const Container = styled.input`
  background-color: shapesDark;
  border: 0;
  font-size: 1.4rem;
  color: text;
  padding: default;
  border-radius: default;
`;
