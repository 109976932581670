import { all, takeLatest, call, put } from 'redux-saga/effects';

import api from '../../../services/api';
import { verificationSuccess, verificationFailure } from './actions';
import { signOutRequest } from '../auth/actions';

function* verify({ payload }) {
  if (!payload || !payload.auth || !payload.auth.token) {
    return yield put(verificationFailure());
  }
  api.defaults.headers.Authorization = `Bearer ${payload.auth.token}`;

  try {
    const response = yield call(api.post, '/verify', {
      access_token: payload.user.twitch.access_token,
      refresh_token: payload.user.twitch.refresh_token,
    });

    yield put(verificationSuccess(response.data));
  } catch (err) {
    console.log(err.response);
    yield put(verificationFailure());
    yield put(signOutRequest());
  }
}

export default all([takeLatest('persist/REHYDRATE', verify)]);
