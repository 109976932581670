import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Container, Grid, StyledTip } from './styles';

import Helmet from '../../components/Helmet';
import Button from '../../components/Button';
import LiveNow from '../../components/LiveNow';

import theme from '../../styles/theme';

import {
  FaTwitch,
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaDiscord,
  FaEnvelope,
  FaUsers,
  FaGamepad,
} from 'react-icons/fa';

export default function Home() {
  const info = useSelector(state => state.info);

  const Tip = useCallback(() => {
    return (
      <StyledTip>
        <img
          src={info.stream.thumbnail_url
            .replace('{height}', 90)
            .replace('{width}', 160)}
        />
        <span>{info.stream.title}</span>
        <div className="row">
          <div className="info">
            <FaGamepad />
            <strong>{info.stream.game_name}</strong>
          </div>
          <span className="info">
            <FaUsers />
            <span>{info.stream.viewer_count}</span>
          </span>
        </div>
      </StyledTip>
    );
  }, [info.stream]);

  return (
    <Container>
      <Helmet title="Home" description="Página principal" />
      <Grid>
        <Link className="card big" to="/tesouros">
          <h1>Tesouros</h1>
          <p>Conheça o novo sistema de sorteios da live!</p>
        </Link>
        <Link className="card giveaways" to="/sorteios">
          <h2>Sorteios</h2>
        </Link>
        <Link className="card shop" to="/loja">
          <h2>Loja</h2>
        </Link>
        {/* <Link className="card treasures" to="/tesouros">
          <h2>Tesouros</h2>
        </Link> */}
        <div className="social">
          <div className="row">
            <Button
              url="https://twitch.tv/peroniogh"
              tip={info.stream && <Tip />}
              color={theme.colors.twitch}
              text={theme.colors.text}
              pulse={info.stream}
            >
              <FaTwitch />
              <span>Twitch</span>
              {info.stream && <LiveNow color={theme.colors.error} />}
            </Button>

            <Button
              url="https://www.youtube.com/peronio"
              color={theme.colors.error}
              text={theme.colors.text}
            >
              <FaYoutube />
              <span>YouTube</span>
            </Button>
          </div>
          <div className="row">
            <Button
              url="https://twitter.com/peroniogh"
              color={theme.colors.twitter}
              text={theme.colors.text}
            >
              <FaTwitter />
              <span>Twitter</span>
            </Button>
            <Button
              url="https://instagram.com/peroniogh"
              colors={[theme.colors.instagram, theme.colors.instagram2]}
              text={theme.colors.text}
            >
              <FaInstagram />
              <span>Instagram</span>
            </Button>
            <Button
              url="http://peronio.com.br/discord"
              color={theme.colors.discord}
              text={theme.colors.text}
            >
              <FaDiscord />
              <span>Discord</span>
            </Button>
          </div>
          <div className="row">
            <Button
              color={theme.colors.shapesDark}
              text={theme.colors.text}
              to="/contato"
            >
              <FaEnvelope />
              <span>Contato</span>
            </Button>
          </div>
        </div>
      </Grid>
    </Container>
  );
}
