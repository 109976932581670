import styled from '@xstyled/styled-components';
import { darken } from 'polished';
import theme from '../../../../styles/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  h3 {
    padding-bottom: 1.2rem;
    border-bottom: 1px solid;
    border-color: ${darken(0.02, theme.colors.shapes)};
  }

  section {
    > * {
      margin-top: 2rem;
    }
    > p {
      opacity: 0.6;
      font-size: 1.4rem;
      font-style: italic;
    }
  }
`;
