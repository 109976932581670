import styled from '@xstyled/styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    'stats1 stats2 stats3'
    'main main main'
    'main main main';

  grid-gap: 1rem;

  height: 100%;

  > * {
    padding: 1rem;
    border-radius: default;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    background-color: shapesDark;
  }

  .stats {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      margin-left: 2rem;
      .count {
        font-size: 3.2rem;
      }
      > * {
        display: block;

        + * {
          margin-top: 1rem;
        }
      }
    }
  }

  .stats1 {
    grid-area: stats1;
  }

  .stats2 {
    grid-area: stats2;
  }

  .stats3 {
    grid-area: stats3;
  }

  .main {
    padding: 2rem;
    grid-area: main;
    display: flex;
    align-items: flex-start;

    .chart {
      flex: 4;
      height: 100%;

      > div {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }

    .stats {
      margin-left: 2rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      > * + * {
        margin-top: 1rem;
      }
    }
  }
`;
