import styled, { css } from '@xstyled/styled-components';
import { easeOutCirc } from 'eazy-ease'
import { transparentize } from 'polished'

import theme from '../../../styles/theme'

export const Container = styled.main`
  .mdc-dialog__content {
    img {
      height: 180px;
      border-radius: default;
      object-fit: contain;
    }
  }
`;

export const PrizeGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);

`;

export const Prize = styled.div`
  height: 130px;
  border-radius: default;
  cursor: pointer;
  position: relative;
  background: rgba(0, 0, 0, .1);

  img {
    border-radius: default;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 1.3rem;
    font-weight: bold;
    letter-spacing: 1px;
    text-shadow: 0 0 2px rgba(0,0,0,.1);
    line-height: 2.4rem;
    background: linear-gradient(to right, ${transparentize(.1, theme.colors.shapesDark)} 65%, transparent);
    padding: .8rem;
    border-bottom-left-radius: 3px;
    overflow: hidden;
  }

  :hover {
    transform: scale(1.025);
    transition: transform ${easeOutCirc} .1s;
  }

  ${({ active }) => !active && css`
    opacity: .25;
    filter: grayscale(.75);
    cursor: not-allowed;
  `}
`;
