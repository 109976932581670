import styled, { css } from '@xstyled/styled-components';

import theme from '../../../../styles/theme';

export const Container = styled.div`
  font-size: 1.4rem;

  display: flex;
  flex-direction: column;

  height: 100%;

  ${({ background }) =>
    background &&
    css`
      padding: huge;
      background-image: linear-gradient(
          to top,
          ${theme.colors.shapes},
          transparent
        ),
        linear-gradient(to left, ${theme.colors.shapes}, transparent),
        url(${background});
      background-size: cover;
    `}
`;

export const Form = styled.div`
  .row {
    display: flex;
    align-items: center;

    .full {
      flex: 1;
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 1rem;
      }
    }

    .item {
      margin: 0;
    }

    > * + * {
      margin-left: 1rem !important;
    }
  }

  > div {
    display: flex;
    align-items: center;

    + div {
      margin-top: 1.2rem;
    }

    > * + * {
      margin-left: 1rem;
    }
  }

  textarea,
  input {
    margin-left: 1rem;
    flex: 1;
    background-color: shapesDark;
    font-size: 1.6rem;
    padding: 1rem;
    color: white;
    border-radius: default;
  }

  textarea {
    min-height: 9rem;
  }

  .selects {
    width: 100%;
    .select {
      display: flex;
      align-items: center;
      flex: 1;

      + .select {
        margin-left: 1rem;
      }

      .reselect {
        margin-left: 1rem;
        width: 100%;
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;
