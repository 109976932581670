import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import api from '../../../services/api';
import {
  exchangeSuccess,
  redeemGoldCodeSuccess,
  redeemTicketCodeSuccess,
  redeemGiftCodeSuccess,
} from './actions';

import alert from '../../../utils/alert';

function* exchange({ payload }) {
  const { amount } = payload;

  const { access_token, refresh_token } = yield select(
    state => state.user.twitch
  );

  try {
    const response = yield call(api.post, '/exchanges', {
      access_token,
      refresh_token,
      amount,
    });

    yield put(exchangeSuccess(amount, response.data.cost));

    alert({
      title: 'Tudo certo!',
      body: 'Pontos trocados com sucesso!',
      actions: [],
    });
  } catch (err) {
    console.log(err.response);
  }
}

function* redeemCode({ payload }) {
  try {
    const response = yield call(api.post, `/codes/${payload.code}`);

    const { type, amount, items } = response.data;

    switch (type) {
      case 'ticket':
        yield put(redeemTicketCodeSuccess(items, items[0].is_unique));
        break;
      case 'gift':
        yield put(redeemGiftCodeSuccess(items));
        break;
      case 'gold':
        yield put(redeemGoldCodeSuccess(amount));
        break;
    }

    alert({
      title: 'Yay!',
      body: response.data.message,
      actions: [],
    });
  } catch (err) {
    console.log(err);
    alert({
      type: 'error',
      title: 'Oops...',
      body: err.response.data,
      actions: [],
    });
  }
}

export default all([
  takeLatest('@user/EXCHANGE_REQUEST', exchange),
  takeLatest('@user/REDEEM_CODE_REQUEST', redeemCode),
]);
