import theme from '../styles/theme';

export default type => {
  switch (type) {
    case 'common':
      return {
        color: theme.colors.text,
        text: 'comum',
      };
    case 'rare':
      return {
        color: theme.colors.gold,
        text: 'raro',
      };
    case 'epic':
      return {
        color: theme.colors.twitch,
        text: 'épico',
      };
    case 'legendary':
      return {
        color: theme.colors.slabs,
        text: 'lendário',
      };
    default:
      return null;
  }
};
