import React from 'react';

import { Container } from './styles';

export default function Section({ title, children }) {
  return (
    <Container>
      <h3>{title}</h3>

      <section>{children || <p>Vazio.</p>}</section>
    </Container>
  );
}
