import React, { useEffect, useState } from 'react';

import api from '../../../services/api';

import { Container, Products, Filters } from './styles';

import ShopItem from './ShopItem';
import Button from '../../../components/Button';
import Switch from '../../../components/Switch';
import Search from '../../../components/admin/Search';
import Empty from '../../../components/Empty';

import theme from '../../../styles/theme';

export default function Shop() {
  const [loading, setLoading] = useState(true);
  const [productList, setProductList] = useState({
    count: 0,
    products: [],
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);

  const [enabled, setEnabled] = useState(true);

  function handleLoadMore() {
    setPage(prev => prev + 1);
  }

  async function getProducts() {
    setLoading(true);
    const response = await api.get('/admin/shop', {
      params: {
        enabled,
        page,
        search,
      },
    });

    setProductList(prev => ({
      count: response.data.count,
      products:
        page > 0
          ? [...prev.products, ...response.data.products]
          : response.data.products,
    }));

    setLoading(false);
  }

  useEffect(() => {
    setProductList(prev => ({ ...prev, products: [] }));
    if (!page) return;
    setPage(0);
    getProducts();
  }, [enabled]);

  useEffect(() => {
    getProducts();
  }, [page, enabled, search]);

  return (
    <Container>
      <Search placeholder="Buscar produto..." onChange={setSearch}>
        <div className="actions">
          <Button
            to="/admin/loja/new"
            color={theme.colors.ticket}
            text={theme.colors.text}
          >
            Novo produto
          </Button>
        </div>
      </Search>
      <Filters>
        <Switch checked={enabled} onChange={() => setEnabled(prev => !prev)}>
          Apenas ativos
        </Switch>
      </Filters>
      <Products>
        {!!productList.products.length ? (
          productList.products.map(product => (
            <ShopItem
              key={product.id}
              to={`/admin/loja/produtos/${product.id}`}
              {...product}
            />
          ))
        ) : (
          <Empty />
        )}
      </Products>
      {!!productList.products.length &&
        productList.products.length < productList.count && (
          <Button
            onClick={handleLoadMore}
            color={theme.colors.translucentDark}
            text={theme.colors.text}
          >
            Carregar mais
          </Button>
        )}
    </Container>
  );
}
