import styled from '@xstyled/styled-components';
import { darken } from 'polished';
import { easeOutCirc } from 'eazy-ease';

import theme from '../../styles/theme';

export const Container = styled.div`
  align-items: stretch;
  background-color: ${darken(0.02, theme.colors.shapes)};
  padding: 1.8rem 3rem;
  border-radius: default;

  font-weight: bold;

  user-select: none;

  a {
    font-size: 1.4rem;
    padding-bottom: calc(1.8rem - 3px);
    border-bottom: 3px solid transparent;

    transition: all 0.2s ${easeOutCirc};

    + a {
      margin-left: 2rem;
    }

    &.active {
      border-bottom: 3px solid;
      border-color: primary;
    }
  }
`;
