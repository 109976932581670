import styled from '@xstyled/styled-components';

import { Progress } from 'react-sweet-progress';

export const Container = styled(Progress).attrs(({ color, trailColor }) => ({
  theme: {
    success: {
      color,
      trailColor,
    },
    active: {
      color,
      trailColor,
    },
    default: {
      color,
      trailColor,
    },
  },
}))`
  flex: 1;
  .react-sweet-progress-symbol {
    display: none;
  }

  .react-sweet-progress-line {
    background-color: shapesDark !important;
    border-radius: 0;
    height: ${({ height }) => height}px;
    border-radius: 1px;
    .react-sweet-progress-line-inner.react-sweet-progress-line-inner-status-active,
    .react-sweet-progress-line-inner {
      border-radius: 0;
      min-height: 0;
      height: ${({ height }) => height}px;
      border-radius: 1px;

      :before {
        border-radius: 0;
        height: ${({ height }) => height}px;
        border-radius: 1px;
      }
    }
  }
`;
