import styled, { keyframes } from '@xstyled/styled-components';

const ghost = keyframes`
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-10px);
  }
`;

const shadow = keyframes`
  0% {
    filter: blur(10px);
  }
  50% {
    filter: blur(6px);
  }
  100% {
    filter: blur(10px);
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 96px;

    animation: ${ghost} 3s ease infinite;
  }

  .shadow {
    margin-top: 2rem;
    width: 90px;
    height: 24px;
    background-color: shapesDark;
    border-radius: 50%;
    animation: ${shadow} 3s ease infinite;
  }

  .text {
    margin-top: 2rem;
    font-size: 1.4rem;
    text-align: center;
    line-height: 2.8rem;
    h1 {
    }
  }
`;
